<script lang="ts" setup>
import SplitPanel from "@/components/Layout/SplitPanel.vue";
import { useScenariosStore } from "@/store/ScenariosStore";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const { isScenarioSelected } = storeToRefs(useScenariosStore());

const props = withDefaults(defineProps<{
  identifier?: string;
  gutter?(
    index: number,
    direction: "horizontal" | "vertical"
  ): HTMLElement;
  gutterSize?: number;
  direction?: "horizontal" | "vertical";
  sizes?: number[];
  minSize?: number | number[];
}>(), {
  direction: "horizontal",
  sizes: () => ([50, 50]),
  minSize: () => ([0, 0]),
});

const splitPanelRef = ref<typeof SplitPanel | null>(null);

function closeDetails() {
  splitPanelRef.value?.splitInstance?.collapse(1);
}

function showDetails() {
  const sizes = splitPanelRef.value?.splitInstance?.getSizes();

  /**
   * If the detail panel is closed, set the sizes to 50/50
   * We need to check if size is less than 1 because the split library
   * does not set the size to 0 when the panel is collapse(); It sets it to 0.5 ir sth similar.
   */
  if(sizes[1] < 1) {
    splitPanelRef.value?.splitInstance?.setSizes([50, 50]);
  }
}

defineExpose({ closeDetails, showDetails });

</script>

<template>
  <div class="split-layout">
    <div v-if="isScenarioSelected" class="fill-height">
      <split-panel 
        ref="splitPanelRef" 
        :identifier="identifier" 
        :direction="direction" class="split-layout-panel" 
        :sizes="sizes" :min-size="minSize" :gutter-size="gutterSize" :gutter="gutter">
        <template #panel-1>
          <slot name="master" />
        </template>
        <template #panel-2>          
          <slot name="detail" />
        </template>
      </split-panel>
    </div>
  </div>
</template>

<style lang="scss">
.split-layout {
  height: 100%;

  .scenario-selector {
    position: absolute;
    z-index: 1;
    top: 99px;
    right: 12px;
    min-width: 20vw;
    text-align: right;
  }
}
</style>
