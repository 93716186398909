<template>
  <split-layout identifier="personnel" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("personnel-view-personnel-title", { $: "Personnel" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <personnel-grid
            ref="grid"
            @manage-availability-rules="manageAvailabilityRules"
            @resource2resource="resource2resourceAction"
            @show-details="onShowDetails"
          />
        </v-card-text>
      </v-card>
      <v-col v-if="editAvailabilityRulesAssignmentDialog">
        <availability-rules-assignment-editor
          :resource="person"
          :opened="editAvailabilityRulesAssignmentDialog"
          @cancel="editAvailabilityRulesAssignmentDialog = false"
          @save="editAvailabilityRulesAssignmentDialog = false"
        />
      </v-col>
      <resource2-resource-dialog
        v-if="resourceType >= 0 && person"
        :opened="resource2resourceDialog"
        :assignment-type="resourceAssignmentType"
        :resource-type="resourceType"
        :resource="person"
        :reverse="isReverseResourceAssignment"
        @close="resource2resourceDialogClose"
      />
    </template>
    <template #detail>
      <personnel-details v-if="person" :person="person" :schema-tabs="schemaTabs" @updated="personUpdated" />
      <v-card v-if="!person" elevation="7" class="d-flex flex-column fill-height no-tasks-card">
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("personnel-view-noPersonnelSelected-title", { $: "No personnel selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<script lang="ts" setup>
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import { $t } from "@/i18n";

/**
 * Called to create gutter element for split panel.
 */
function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-personnel");
  return gutter;
}
</script>

<script lang="ts">
import PersonnelGrid from "@/components/Personnel/PersonnelGrid";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout";
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor";
import { mapActions, mapState } from "pinia";
import { usePersonnelStore } from "@/store/PersonnelStore";
import { ResourceAssignmentType, ResourceType } from "@masta/generated-model";
import Resource2ResourceDialog from "@/components/Resources/ResourceAssignment/Resource2ResourceDialog.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import ResourceModelInstanceAssignment from "@/components/ModelInstances/ModelInstanceAssignment/ResourceModelInstanceAssignment.vue";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useSystemStore } from "@/store/SystemStore";
import PersonnelDetails from "@/components/Personnel/PersonnelDetails.vue";

export default {
  name: "Personnel",
  components: {
    PersonnelDetails,
    Breadcrumbs,
    Resource2ResourceDialog,
    AvailabilityRulesAssignmentEditor,
    MasterDetailLayout,
    PersonnelGrid
  },
  title: "Personnel",
  data() {
    return {
      editAvailabilityRulesAssignmentDialog: false,
      showDetails: false,
      person: null,
      refreshGridAction: null,
      resource2resourceDialog: false,
      resourceType: null,
      resourceAssignmentType: ResourceAssignmentType.AgreementAssignment,
      isReverseResourceAssignment: true,
      schemaTabs: []
    };
  },
  computed: {
    ...mapState(useModelInstancesStore, ["schemas"]),
    ...mapState(useSystemStore, ["modelInstanceTabs"])
  },
  mounted() {
    this.loadTabSchemas();
  },
  methods: {
    ...mapActions(useModelInstancesStore, ["fetchSchemas"]),
    manageAvailabilityRules(person) {
      this.person = person;
      this.editAvailabilityRulesAssignmentDialog = true;
    },
    resource2resourceAction(resourceType, person) {
      this.resourceType = resourceType;
      switch (this.resourceType) {
        case ResourceType.Agreement:
          this.resourceAssignmentType = ResourceAssignmentType.AgreementAssignment;
          this.isReverseResourceAssignment = true;
          break;
        case ResourceType.Asset:
          this.resourceAssignmentType = ResourceAssignmentType.Identity;
          this.isReverseResourceAssignment = false;
          break;
      }
      this.person = person;
      this.resource2resourceDialog = true;
    },
    resource2resourceDialogClose() {
      this.resource2resourceDialog = false;
      this.resourceType = null;
      this.person = null;
    },
    onShowDetails(person) {
      this.person = person;
      this.showDetails = true;
    },
    async loadTabSchemas() {
      await this.fetchSchemas();
      for (const tab of this.modelInstanceTabs?.personnel ?? []) {
        const schema = this.schemas.find((x) => x.schemaName === tab.schemaName && x.schemaKey === tab.schemaKey && x.version === tab.schemaVersion);
        if (schema) {
          this.schemaTabs.push({ title: tab.title ?? "", schema, contextName: tab.contextName ?? "", resourceSubTypes: tab.resourceSubTypes });
        }
      }
    },
    async personUpdated(id) {
      this.$refs.grid.refreshSingle(id);
    }
  }
};
</script>

<style scoped></style>
