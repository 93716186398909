<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import OpenResourceGanttGridAction from "@/components/Gantt/OpenResourceGanttGridAction.vue";
import GenerateCalendarsResourceGridAction from "@/components/Resources/GenerateCalendarsResourceGridAction.vue";
import { reactive, Ref, ref, inject } from "vue";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { CalendarDto, ResourceType, TagDto } from "@masta/generated-model";
import { $t } from "@/i18n";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { joinArrayOfStrings } from "@/components/ValueCellEditor/CommonFormatters";
import { CalendarServerSideDataSource } from "@/components/Calendars/CalendarServerSideDataSource";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { generateRandomColorHex } from "@/composables/colorHelpers";
import { useTagsStore } from "@/store/TagsStore";
import { booleanTypeColumnFilterParams } from "@/components/Grid/Filters/BooleanTypeColumnFilters";
import { tagsTypeColumnFilterParams } from "@/components/Grid/Filters/TagsTypeColumnFilters";

const emit = defineEmits(["manageAvailabilityRules"]);

const serverSideDataSource = reactive(new CalendarServerSideDataSource("calendars"));
const DEFAULT_CREATE_VALUE = {
  color: () => generateRandomColorHex(),
  isBase: false,
  plannable: false,
};

const miStore = useModelInstancesStore();
const tagsStore = useTagsStore();
const ganttTypes = reactive([ResourceType.Calendar]);

const gridWrapperRef = ref<GridWrapperComponent>();
const defaultColumnDef = ref({
  filter: false,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

async function onFetchData() {
  await miStore.fetchSchemas();
}

async function onRefreshAction() {
  await onFetchData();
}

function onPrepareColumns(columnDefs: Ref<ColDef[]>) {
  columnDefs.value = [
    {
      field: "businessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerName: "Business ID",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("calendar-list-businessId-label", { $: "Business ID" }),
      cellEditorParams: {
        isEditEnabled: () => !gridWrapperRef.value?.isUpdating(),
        rules: [requiredRule],
        placeholder: $t("calendar-edit-businessId-label", { $: "Business ID" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("calendar-edit-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "name",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("calendar-list-name-label", { $: "Name" }),
      cellEditorParams: {
        placeholder: $t("calendar-edit-name-label", { $: "Name" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("calendar-edit-name-label", { $: "Name" })
      }
    },
    {
      field: "description",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("calendar-list-description-label", { $: "Description" }),
      editable: true,
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("calendar-edit-description-label", { $: "Description" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("calendar-edit-description-label", { $: "Description" })
      }
    },
    {
      field: "color",
      type: ["colorTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("calendar-list-color-label", { $: "Color" }),
      cellEditorParams: {
        placeholder: $t("calendar-list-color-label", { $: "Color" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("equipment-list-color-label", { $: "Color" })
      }
    },
    {
      field: "isBase",
      type: ["setFloatingFilterColumnType"],
      filter: "agSetColumnFilter",
      filterParams: booleanTypeColumnFilterParams,
      editable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("calendar-list-isBase-label", { $: "Base" }),
      cellDataType: "boolean",
      cellRendererParams: {
        disabled: () => !gridWrapperRef.value?.isEditing()
      },
    },
    {
      field: "tags",
      type: ["tagsPickerTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("calendar-list-tags-label", { $: "Tags" }),
      editable: true,
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: tagsTypeColumnFilterParams(tagsStore),
      valueFormatter: (params: ValueFormatterParams) => joinArrayOfStrings(params.data.tags),
      cellEditorParams: {
        placeholder: $t("calendar-edit-tags-label", { $: "Tags" })
      }
    },
    {
      field: "plannable",
      type: ["setFloatingFilterColumnType"],
      filter: "agSetColumnFilter",
      filterParams: booleanTypeColumnFilterParams,
      headerValueGetter: (_: any) => $t("calendar-list-plannable-label", { $: "Plannable" }),
      editable: true,
      resizable: true,
      sortable: true,
      cellDataType: "boolean",
      cellRendererParams: {
        disabled: () => !gridWrapperRef.value?.isEditing()
      },
    },
    {
      field: "createdBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("calendar-list-createdBy-label", { $: "Created By" }),
      floatingFilterComponentParams: {
        placeholder: $t("calendar-list-createdBy-label", { $: "Created By" })
      }
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("calendar-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("calendar-list-modifiedBy-label", { $: "Modified By" }),
      floatingFilterComponentParams: {
        placeholder: $t("equipment-list-modifiedBy-label", { $: "Modified By" })
      }
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("calendar-list-modifiedAt-label", { $: "Modified At" })
    }
  ];
}

function getContextMenuItems(param: any) {
  return [
    {
      name: $t("calendar-availabilityRules-manage-action", { $: "Manage Availability Rules Assignment" }),
      action: () => {
        emit("manageAvailabilityRules", param.node.data);
      }
    }
  ];
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    refresh-btn
    row-selection="multiple"
    :default-col-def="defaultColumnDef"
    :context-menu-items="getContextMenuItems"
    identifier="calendars"
    create-btn
    duplicate-btn
    edit-btn
    delete-btn
    server-side
    :server-side-datasource="serverSideDataSource"
    :create-default-value="DEFAULT_CREATE_VALUE"
    @fetch-data="onFetchData"
    @refresh-action="onRefreshAction"
    @prepare-columns="onPrepareColumns"
  >
    <template #custom-buttons>
      <open-resource-gantt-grid-action :types="ganttTypes" :grid-api="gridWrapperRef?.gridApi" />
      <generate-calendars-resource-grid-action :grid-api="gridWrapperRef?.gridApi" />
    </template>
  </grid-wrapper>
</template>
