<script lang="ts" setup>
import { AssetServerSideDataSource } from "@/components/Assets/AssetServerSideDataSource";
import { isDefined } from "@/components/Common/Types";
import OpenResourceGanttGridAction from "@/components/Gantt/OpenResourceGanttGridAction.vue";
import { assetGroupValueCellStyle } from "@/components/Grid/CellRenderers/GroupValueCellStyle";
import { enumToEditorEntries, enumToEditorEntriesOnlyIncluding, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { booleanTypeColumnFilterParams } from "@/components/Grid/Filters/BooleanTypeColumnFilters";
import FilterGridAction, { FilterGridActionItem } from "@/components/Grid/Filters/FilterGridAction.vue";
import { tagsTypeColumnFilterParams } from "@/components/Grid/Filters/TagsTypeColumnFilters";
import { useFilterGridAction } from "@/components/Grid/Filters/UseFilterGridAction";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import GenerateCalendarsResourceGridAction from "@/components/Resources/GenerateCalendarsResourceGridAction.vue";
import { joinArrayOfStrings, nameOrBusinessIdOrIdOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { generateRandomColorHex } from "@/composables/colorHelpers";
import { translateResourceSubType, translateResourceType } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useTagsStore } from "@/store/TagsStore";
import { AssetDto, AssetSubType, ResourceInfoDto, ResourceSubType, ResourceType } from "@masta/generated-model";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { GetContextMenuItemsParams, GridApi, GridReadyEvent, KeyCreatorParams, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { inject, reactive, ref } from "vue";
import ActionsButton from "@/components/Layout/ActionsButton.vue";

const emit = defineEmits(["manageAvailabilityRules", "showDetails", "resource2resource"]);
defineExpose({
  refreshSingle
});
const miStore = useModelInstancesStore();
const tagsStore = useTagsStore();

const serverSideDataSource = reactive(new AssetServerSideDataSource("assets"));
const DEFAULT_CREATE_VALUE = {
  type: ResourceType.Asset,
  tags: [],
  subTypes: [],
  color: () => generateRandomColorHex(),
  plannable: true
};
const ganttTypes = reactive([ResourceType.Asset]);
const gridWrapperRef = ref<GridWrapperComponent>();
const filterGridActionRef = ref<typeof FilterGridAction>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

async function loadModelInstances() {
  await miStore.fetchSchemas();
}

async function onReady(event: GridReadyEvent) {
  await loadModelInstances();
}

function getServerSideGroupKey(dataItem: AssetDto) {
  return dataItem?.id;
}

function getServerSideChildCount(dataItem: AssetDto) {
  return dataItem?.childCount;
}

function isServerSideGroup(dataItem: AssetDto) {
  return isDefined(dataItem?.id);
}

const autoGroupColumnDef = ref({
  field: "businessId",
  editable: true,
  sortable: true,
  minWidth: 330,
  filter: "agTextColumnFilter",
  headerValueGetter: (_: any) => $t("asset-list-businessId-label", { $: "Business ID" }),
  cellStyle: assetGroupValueCellStyle,
  type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
  cellEditorParams: {
    rules: [(v: any) => (v !== undefined && v !== null && v !== "") || "Required"],
    placeholder: $t("asset-list-businessId-label", { $: "Business ID" })
  },
  floatingFilterComponentParams: {
    placeholder: $t("asset-list-businessId-label", { $: "Business ID" })
  }
});

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "parentId",
      type: "resourcePickerTypeColumn",
      headerValueGetter: (_: any) => $t("asset-list-parent-label", { $: "Parent" }),
      editable: true,
      hide: true,
      cellEditorParams: {
        resourceTypes: [ResourceType.Asset],
        placeholder: $t("asset-list-parent-label", { $: "Parent" })
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return nameOrBusinessIdOrIdOrNull(params.value);
      },
      valueGetter: (params: ValueGetterParams<AssetDto>) => {
        const { data } = params;
        if (!data || !isDefined(data.parentId)) return null;

        const parentNode = params.api.getRowNode(data.parentId);

        return mapToResourceInfoDto(data.parentId, parentNode && parentNode.data ? parentNode.data.businessId : null, parentNode && parentNode.data ? parentNode.data.name : null);
      },
      valueSetter: (params: ValueSetterParams<AssetDto, AssetDto>) => {
        params.data.parentId = params.newValue?.id ?? null;
        return true;
      }
    },
    {
      field: "type",
      editable: false,
      sortable: false,
      filter: false,
      valueFormatter: (params: any) => translateResourceType(params.data.type),
      headerValueGetter: (_: any) => $t("asset-list-type-label", { $: "Type" })
    },
    {
      field: "name",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("asset-list-name-label", { $: "Name" }),
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("asset-list-name-label", { $: "Name" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("asset-list-name-label", { $: "Name" })
      }
    },
    {
      field: "description",
      headerValueGetter: (_: any) => $t("asset-list-description-label", { $: "Description" }),
      editable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("asset-edit-description-label", { $: "Description" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("asset-edit-description-label", { $: "Description" })
      }
    },
    {
      field: "businessId",
      headerName: "Business ID",
      editable: true,
      sortable: true,
      hide: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("asset-list-businessId-label", { $: "Business ID" }),
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [(v: any) => (v !== undefined && v !== null && v !== "") || "Required"],
        placeholder: $t("asset-list-businessId-label", { $: "Business ID" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("asset-list-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "token",
      headerValueGetter: (_: any) => $t("asset-list-token-label", { $: "Token" }),
      editable: true,
      sortable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("asset-list-token-label", { $: "Token" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("asset-list-token-label", { $: "Token" })
      }
    },
    {
      field: "color",
      type: ["colorTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("asset-list-color-label", { $: "Color" }),
      cellEditorParams: {
        placeholder: $t("asset-list-color-label", { $: "Color" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("asset-list-color-label", { $: "Color" })
      }
    },
    {
      field: "subTypes",
      editable: true,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(AssetSubType, [AssetSubType.Identity]), translateResourceSubType),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => (params.data.subTypes ? params.data.subTypes.map(translateResourceSubType).join(", ") : undefined),
      headerValueGetter: (_: any) => $t("asset-list-subTypes-label", { $: "Asset types" }),
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      cellEditorParams: {
        multiple: true,
        placeholder: $t("asset-list-subTypes-label", { $: "Asset types" }),
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(AssetSubType, [AssetSubType.Identity]), translateResourceSubType)
      },
      floatingFilterComponentParams: {
        placeholder: $t("asset-list-subTypes-label", { $: "Asset types" }),
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(AssetSubType, [AssetSubType.Identity]), translateResourceSubType)
      }
    },
    {
      field: "tags",
      type: ["tagsPickerTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("asset-list-tags-label", { $: "Tags" }),
      filter: "agSetColumnFilter",
      filterParams: tagsTypeColumnFilterParams(tagsStore),
      editable: true,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => joinArrayOfStrings(params.data.tags),
      cellEditorParams: {
        placeholder: $t("asset-edit-tags-label", { $: "Tags" })
      }
    },
    {
      field: "plannable",
      type: ["setFloatingFilterColumnType"],
      filter: "agSetColumnFilter",
      filterParams: booleanTypeColumnFilterParams,
      headerValueGetter: (_: any) => $t("asset-list-plannable-label", { $: "Plannable" }),
      editable: true,
      resizable: true,
      sortable: true,
      cellDataType: "boolean",
      cellRendererParams: {
        disabled: () => !gridWrapperRef.value?.isEditing()
      }
    },
    {
      field: "createdBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("asset-list-createdBy-label", { $: "Created By" }),
      floatingFilterComponentParams: {
        placeholder: $t("asset-list-createdBy-label", { $: "Created By" })
      }
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("asset-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("asset-list-modifiedBy-label", { $: "Modified By" }),
      floatingFilterComponentParams: {
        placeholder: $t("asset-list-modifiedBy-label", { $: "Modified By" })
      }
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("asset-list-modifiedAt-label", { $: "Modified At" })
    }
  ];
}

function getContextMenuItems(param: GetContextMenuItemsParams) {
  return [
    {
      name: $t("asset-resource2resource-personnel-action", { $: "Personnel assignment" }),
      action: () => {
        emit("resource2resource", ResourceType.Person, param?.node?.data);
      },
      disabled: !param?.node?.data || param.node.data.type !== ResourceType.Asset
    }
  ];
}

function showDetails(data: AssetDto) {
  emit("showDetails", data);
}

function refreshSingle(id: string) {
  serverSideDataSource.getSingle(id).then((data) => {
    if (data) {
      gridWrapperRef.value?.gridApi.forEachNode((node) => {
        if (node.data.id === id) {
          node.updateData(data);
          emit("showDetails", data);
        }
      });
    } else {
      console.warn(`Could not find row with id ${id} to refresh!`);
    }
  });
}

function mapToResourceInfoDto(id: string | null, businessId: string | null, name: string | null): ResourceInfoDto | null {
  if (id || businessId || name) {
    const resourceInfo: ResourceInfoDto = {
      id: id as string,
      scenarioId: "",
      name: name,
      businessId: businessId,
      type: ResourceType.PersonGroup,
      plannable: false
    };

    return resourceInfo;
  } else {
    return null;
  }
}

const filterGridAction = useFilterGridAction({ filterKey: "subTypes", gridWrapperRef: gridWrapperRef, filterGridActionRef: filterGridActionRef });

const filterGridActionItems: FilterGridActionItem[] = [{ value: AssetSubType.Identity, text: translateResourceSubType(ResourceSubType.AssetIdentity) }];
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    create-btn
    create-child-btn
    edit-btn
    delete-btn
    refresh-btn
    duplicate-btn
    details-btn
    row-selection="multiple"
    identifier="assets"
    :default-col-def="defaultColumnDef"
    :context-menu-items="getContextMenuItems"
    server-side
    :server-side-datasource="serverSideDataSource"
    :create-default-value="DEFAULT_CREATE_VALUE"
    :enable-group-edit="true"
    :auto-group-column-def="autoGroupColumnDef"
    :is-server-side-group="isServerSideGroup"
    :get-server-side-group-key="getServerSideGroupKey"
    :get-child-count="getServerSideChildCount"
    :tree-data="true"
    @fetch-data="onReady"
    @refresh-action="loadModelInstances"
    @prepare-columns="onPrepareColumns"
    @filter-changed="filterGridAction.onFilterGridModelChanged"
    @details="showDetails"
  >
    <template #custom-buttons>
      <actions-button :model-value="[]" :label="$t('actions-button-gantt-label', { $: 'Gantt' })">
        <template #prepend>
          <open-resource-gantt-grid-action :types="ganttTypes" :grid-api="gridWrapperRef?.gridApi" />
        </template>
      </actions-button>
    </template>
    <template #filter>
      <filter-grid-action ref="filterGridActionRef" :items="filterGridActionItems" @filter-changed="filterGridAction.onFilterGridActionChanged" />
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
