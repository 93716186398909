<template>
  <split-layout identifier="equipments" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("equipment-view-equipment-title", { $: "Equipment" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <equipment-grid ref="grid" @manage-availability-rules="manageAvailabilityRules" @show-details="onShowDetails" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-col v-if="editAvailabilityRulesAssignmentDialog">
        <availability-rules-assignment-editor
          :resource="equipment"
          :opened="editAvailabilityRulesAssignmentDialog"
          @cancel="editAvailabilityRulesAssignmentDialog = false"
          @save="editAvailabilityRulesAssignmentDialog = false"
        />
      </v-col>
    </template>
    <!--    <template #detail-title>-->
    <!--      <span class="text-h6">{{ equipment?.name || equipment?.businessId }}</span>-->
    <!--    </template>-->
    <template #detail>
      <equipment-details v-if="equipment" :equipment="equipment" :schema-tabs="schemaTabs" @updated="equipmentUpdated" />
      <v-card v-if="!equipment" elevation="7" class="d-flex flex-column fill-height no-tasks-card">
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("equipment-view-noEquipmentSelected-title", { $: "No equipment selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<script lang="ts" setup>
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import { $t } from "@/i18n";

/**
 * Called to create gutter element for split panel.
 */
function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-equipments");
  return gutter;
}
</script>

<script lang="ts">
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import EquipmentGrid from "@/components/Equipment/EquipmentGrid";
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor";
import { useEquipmentsStore } from "@/store/EquipmentsStore";
import { mapActions, mapState } from "pinia";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import EquipmentDetails from "@/components/Equipment/EquipmentDetails.vue";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useSystemStore } from "@/store/SystemStore";

export default {
  name: "Equipment",
  components: {
    EquipmentDetails,
    Breadcrumbs,
    AvailabilityRulesAssignmentEditor,
    EquipmentGrid,
    MasterDetailLayout
  },
  title: "Equipment",
  data() {
    return {
      editAvailabilityRulesAssignmentDialog: false,
      showDetails: false,
      equipment: null,
      refreshGridAction: null,
      schemaTabs: []
    };
  },
  computed: {
    ...mapState(useModelInstancesStore, ["schemas"]),
    ...mapState(useSystemStore, ["modelInstanceTabs"])
  },
  watch: {
    selectedScenario() {
      this.fetch();
    }
  },
  mounted() {
    this.loadTabSchemas();
  },
  methods: {
    ...mapActions(useEquipmentsStore, ["fetch", "updateModelInstanceAssignment"]),
    ...mapActions(useModelInstancesStore, ["fetchSchemas"]),
    onScenarioSelectionChanged() {
      this.fetch();
    },
    manageAvailabilityRules(equipment) {
      this.equipment = equipment;
      this.editAvailabilityRulesAssignmentDialog = true;
    },
    onShowDetails(equipment) {
      this.equipment = equipment;
      this.showDetails = true;
    },
    async loadTabSchemas() {
      await this.fetchSchemas();
      for (const tab of this.modelInstanceTabs?.equipments ?? []) {
        const schema = this.schemas.find((x) => x.schemaName === tab.schemaName && x.schemaKey === tab.schemaKey && x.version === tab.schemaVersion);
        if (schema) {
          this.schemaTabs.push({ title: tab.title ?? "", schema, contextName: tab.contextName ?? "", resourceSubTypes: tab.resourceSubTypes });
        }
      }
    },
    async equipmentUpdated(id) {
      this.$refs.grid.refreshSingle(id);
    }
  }
};
</script>

<style scoped></style>
