<script lang="ts" setup>
import { getSchemaId, useModelInstancesStore } from "@/store/ModelInstancesStore";
import { storeToRefs } from "pinia";
import { computed, onBeforeMount, ref, watch } from "vue";
import { useRouteQuery } from "@vueuse/router";
import { ModelSchemaDto } from "@masta/generated-model";
import { useRouter } from "vue-router";
import { $t } from "@/i18n";

const searchText = defineModel<string | null | undefined>({ required: true });

const emits = defineEmits(["close"]);

const store = useModelInstancesStore();

const { schemas } = storeToRefs(store);

const filteredSchemas = computed(() => {
  if (searchText.value === null || searchText.value === undefined || searchText.value === "") {
    return [];
  }
  return schemas.value.filter((schema) => {
    return schema.schemaName.toLowerCase().includes(searchText.value?.toLowerCase() ?? "");
  });
});

const schemaId = useRouteQuery<string | null>("schemaId", null);

const selectedSchema = ref<ModelSchemaDto | null>(null);

onBeforeMount(async () => {
  await store.fetchSchemas();
  watch(schemaId, (id: string | null) => {
    if (id) {
      selectedSchema.value = schemas.value.find((schema) => getSchemaId(schema) === id) ?? null;
    } else {
      selectedSchema.value = null;
    }
  }, { immediate: true });
});

const router = useRouter();

function onSchemaClick(schema: ModelSchemaDto) {
  router.push({ name: "ModelRepository", query: { schemaId: getSchemaId(schema) } });
  emits("close");
}
</script>

<template>
  <v-list class="list">
    <v-fade-transition group>
      <v-list-item
        v-for="schema in filteredSchemas"
        :key="getSchemaId(schema)"
        :active="!!selectedSchema && (getSchemaId(selectedSchema) === getSchemaId(schema))"
        :title="schema.schemaName"
        :subtitle="schema.schemaName"
        :base-color="!!selectedSchema && (getSchemaId(selectedSchema) === getSchemaId(schema)) ? 'primary': undefined"
        cols="12"
        class="rounded"
        density="compact"
        data-cy="schema"
        @click="onSchemaClick(schema)"
      />
      <v-list-item
        v-if="filteredSchemas.length === 0"
        key="empty"
        :title="$t('topSearch-modelRepositorySearchRequired-caption', { $: 'Please type something to search models...' })"
        cols="12"
        class="rounded text-center"
        density="compact"
      />
    </v-fade-transition>
  </v-list>
</template>

<style scoped lang="scss">
.list {
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 0;
}
</style>
