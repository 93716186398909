<template>
  <split-layout ref="splitLayoutRef" identifier="organizations" scenario-selector :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="d-flex flex-column fill-height bg-material-design-grey-lighten-5">
        <v-card-title>
          <breadcrumbs>{{ $t("organization-view-organizations-title", { $: "Organizations" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <organization-grid ref="organizationGridRef" @row-selected="onOrganizationSelected" @delete-action="onOrganizationDeleted" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template #detail>
      <v-card v-if="selectedOrganization" elevation="7" class="d-flex flex-column fill-height contacts-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-title class="pt-2">
          <breadcrumbs>
            {{ nameOrBusinessIdOrIdOrNull(selectedOrganization) }} {{ $t("organization-view-Contacts-title", { $: "Contacts" }) }}
          </breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12">
              <contact-grid ref="contactGridRef" :organization="selectedOrganization" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="!selectedOrganization" elevation="7" class="d-flex flex-column fill-height no-contacts-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("organization-view-noOrganizationSelected-label", { $: "No organization selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<script lang="ts" setup>
import ContactGrid from "@/components/Contacts/ContactGrid.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import OrganizationGrid from "@/components/Organizations/OrganizationGrid.vue";
import { nameOrBusinessIdOrIdOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import { OrganizationDto } from "@masta/generated-model";
import { isDefined } from "@vueuse/core";
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import ApiService from "@/services/api";
import { useScenariosStore } from "@/store/ScenariosStore";
import { storeToRefs } from "pinia";
import CloseCardButton from "@/components/CloseCardButton.vue";

const router = useRouter();
const route = useRoute();
const scenariosStore = useScenariosStore();
const { selectedScenario } = storeToRefs(scenariosStore);

const organizationGridRef = ref<typeof OrganizationGrid | null>(null);
const contactGridRef = ref<typeof ContactGrid | null>(null);

const selectedOrganization = ref<OrganizationDto | null | undefined>(null);

const splitLayoutRef = ref<typeof SplitLayout | null>(null);

watch(
  () => route.query.id,
  async (organizationId, _) => {
    if (isDefined(organizationId)) {
      if (isDefined(selectedOrganization.value) && selectedOrganization.value?.id === organizationId) {
        return;
      } else {
        const organization = await fetchOrganization(organizationId as string);
        selectedOrganization.value = organization;
        showDetails();
      }
    } else {
      selectedOrganization.value = null;
    }
  }, { immediate: true });

async function onOrganizationSelected(orgnization: OrganizationDto) {
  if (isDefined(orgnization)) {
    selectedOrganization.value = orgnization;
    showDetails();
    await router.push({ query: { id: orgnization.id } });
  }
}

async function onOrganizationDeleted() {
  selectedOrganization.value = null;
  await router.push({ query: {} });
}

async function fetchOrganization(organizationId: string): Promise<OrganizationDto | null | undefined> {
  try {
    if (!selectedScenario.value) {
      return null;
    }

    const reponse = await ApiService.organizations.getSingle(organizationId, selectedScenario.value.id);
    return reponse.data;
  } catch (error) {
    console.error("Fetch organization error", error);
  }

  return null;
}

function showDetails() {
  splitLayoutRef.value?.showDetails();
}

function closeDetails() {
  splitLayoutRef.value?.closeDetails();
}

/**
 * Called to create gutter element for split panel.
 */
function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-organizations");
  return gutter;
}
</script>

<style lang="scss">
.contacts-card {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}

.no-contacts-card {
  border: 2px solid rgb(var(--v-theme-primary), 0.0);
  border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}

.gutter-organizations {
  cursor: col-resize;
}
</style>
