<script lang="ts" setup>
import { isDefined } from "@/components/Common/Types";
import { useContextMaterialGanttGridAction } from "@/components/Gantt/ContextMaterialGantt/UseContextMaterialGanttGridAction";
import OpenResourceGanttGridAction from "@/components/Gantt/OpenResourceGanttGridAction.vue";
import { refreshCheckboxCellEditor } from "@/components/Grid/CellEditors/CheckboxCellEditorHelper";
import { IEnumValueSelectCellEditorParams } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";
import { groupValueCellStyle } from "@/components/Grid/CellRenderers/GroupValueCellStyle";
import { enumToEditorEntries, enumToEditorEntriesOnlyIncluding, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { booleanTypeColumnFilterParams } from "@/components/Grid/Filters/BooleanTypeColumnFilters";
import FilterGridAction, { FilterGridActionItem } from "@/components/Grid/Filters/FilterGridAction.vue";
import { intervalTypeColumnFilter, intervalTypeColumnFilterParams } from "@/components/Grid/Filters/IntervalTypeColumnFilters";
import { tagsTypeColumnFilterParams } from "@/components/Grid/Filters/TagsTypeColumnFilters";
import { useFilterGridAction } from "@/components/Grid/Filters/UseFilterGridAction";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { MaterialServerSideDataSource } from "@/components/Material/MaterialServerSideDataSource";
import { joinArrayOfStrings, nameOrBusinessIdOrIdOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import { requiredNotEmptyArrayRule, requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { generateRandomColorHex } from "@/composables/colorHelpers";
import {
  translateMaterialOrigin,
  translateMeasurementUnit,
  translateResourcePurpose,
  translateResourceSubType,
  translateResourceType,
  translateTrackingType
} from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useTagsStore } from "@/store/TagsStore";
import {
  MaterialDto,
  MaterialOrigin,
  MaterialSubType,
  MeasurementUnit,
  ResourceInfoDto,
  ResourcePurpose,
  ResourceSubType,
  ResourceTrackingType,
  ResourceType
} from "@masta/generated-model";
import { $dateTimeFormatterSymbol, asIsoFormattedString, asJiraFormattedString, DateFormatter } from "@masta/shared";
import {
  CellClassParams,
  GridReadyEvent,
  ICellEditorParams,
  KeyCreatorParams,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams
} from "ag-grid-community";
import { inject, reactive, ref } from "vue";
import { groupTypeCellStyle } from "@/components/Grid/CellRenderers/GroupTypeCellStyle";
import ActionsButton from "@/components/Layout/ActionsButton.vue";

const emit = defineEmits(["manageAvailabilityRules", "selectionChanged", "resource2resource", "resource2resourceReverse", "showDetails"]);
defineExpose({
  refreshSingle
});

const miStore = useModelInstancesStore();
const tagsStore = useTagsStore();

const openContextMaterialGanttGridAction = useContextMaterialGanttGridAction();

const DEFAULT_CREATE_VALUE = {
  type: ResourceType.MaterialGroup,
  trackingType: ResourceTrackingType.None,
  tags: [],
  color: () => generateRandomColorHex(),
  infiniteCapacity: true,
  plannable: false
};
const serverSideDataSource = reactive(new MaterialServerSideDataSource("materials"));

const ganttTypes = reactive([ResourceType.Material]);
const gridWrapperRef = ref<GridWrapperComponent>();
const filterGridActionRef = ref<typeof FilterGridAction>();

const isEditingMaterialType = ref<boolean>(true);
const selectedMaterialId = ref<string | null>(null);

const defaultColumnDef = ref({
  filter: false,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});
const autoGroupColumnDef = ref({
  field: "businessId",
  editable: true,
  sortable: true,
  minWidth: 330,
  filter: "agTextColumnFilter",
  headerValueGetter: (_: any) => $t("material-list-businessId-label", { $: "Business ID" }),
  type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
  cellEditorParams: {
    rules: [(v: any) => (v !== undefined && v !== null && v !== "") || "Required"],
    placeholder: $t("material-list-businessId-label", { $: "Business ID" }),
    isEditEnabled: () => true //!gridWrapperRef.value?.isUpdating()
  },
  cellStyle: (params: CellClassParams) => groupValueCellStyle(params, isServerSideGroup),
  floatingFilterComponentParams: {
    placeholder: $t("material-list-businessId-label", { $: "Business ID" })
  }
});

function isServerSideGroup(dataItem: MaterialDto) {
  // new row in editing mode is not a group (otherwise the ag grid might tries to load data for it and :boom:)
  if (dataItem?.id == null) return false;

  return dataItem.type === ResourceType.MaterialGroup;
}

function getServerSideGroupKey(dataItem: MaterialDto) {
  return dataItem?.id;
}

function getServerSideChildCount(dataItem: MaterialDto) {
  return dataItem?.childCount;
}

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

async function loadModelInstances() {
  await miStore.fetchSchemas();
}

async function onReady(event: GridReadyEvent) {
  await loadModelInstances();
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "parentId",
      type: "resourcePickerTypeColumn",
      headerValueGetter: (_: any) => $t("material-list-parent-label", { $: "Parent" }),
      editable: true,
      hide: true,
      cellEditorParams: {
        resourceTypes: [ResourceType.MaterialGroup],
        placeholder: $t("material-list-parent-label", { $: "Parent" })
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return nameOrBusinessIdOrIdOrNull(params.value);
      },
      valueGetter: (params: ValueGetterParams<MaterialDto>) => {
        const { data } = params;
        if (!data || !isDefined(data.parentId)) return null;

        const parentNode = params.api.getRowNode(data.parentId);

        return mapToResourceInfoDto(data.parentId, parentNode && parentNode.data ? parentNode.data.businessId : null, parentNode && parentNode.data ? parentNode.data.name : null);
      },
      valueSetter: (params: ValueSetterParams<MaterialDto, MaterialDto>) => {
        params.data.parentId = params.newValue?.id ?? null;
        return true;
      }
    },
    {
      field: "name",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("material-list-name-label", { $: "Name" }),
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("material-list-name-label", { $: "Name" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("material-list-name-label", { $: "Name" })
      }
    },
    {
      field: "type",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("material-list-type-label", { $: "Type" }),
      cellStyle: (params: CellClassParams) => groupTypeCellStyle(params, isServerSideGroup),
      editable: true,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(ResourceType, [ResourceType.Material, ResourceType.MaterialGroup]), translateResourceType),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => translateResourceType(params.data.type),
      cellEditorParams: {
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(ResourceType, [ResourceType.Material, ResourceType.MaterialGroup]), translateResourceType),
        rules: [requiredRule],
        placeholder: $t("material-list-type-label", { $: "Type" }),
        onValueChange: onTypeChange,
        isEditEnabled: () => !gridWrapperRef.value?.isUpdating(),
        clearOnEditDisabled: false
      } as unknown as IEnumValueSelectCellEditorParams,
      floatingFilterComponentParams: {
        placeholder: $t("material-list-type-label", { $: "Type" }),
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(ResourceType, [ResourceType.Material, ResourceType.MaterialGroup]), translateResourceType)
      }
    },
    {
      field: "businessId",
      editable: true,
      sortable: true,
      hide: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("material-list-businessId-label", { $: "Business ID" }),
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [(v: any) => (v !== undefined && v !== null && v !== "") || "Required"],
        placeholder: $t("material-list-businessId-label", { $: "Business ID" }),
        isEditEnabled: () => true //!gridWrapperRef.value?.isUpdating()
      },
      floatingFilterComponentParams: {
        placeholder: $t("material-list-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "description",
      headerValueGetter: (_: any) => $t("material-list-description-label", { $: "Description" }),
      editable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("material-edit-description-label", { $: "Description" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("material-list-description-label", { $: "Description" })
      }
    },
    {
      field: "color",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("material-list-color-label", { $: "Color" }),
      type: ["colorTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("material-list-color-label", { $: "Color" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("material-list-color-label", { $: "Color" })
      }
    },
    {
      field: "subTypes",
      editable: true,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MaterialSubType), translateResourceSubType),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => (params.data.subTypes ? translateResourceSubType(params.data.subTypes) : undefined),
      headerValueGetter: (_: any) => $t("material-list-subTypes-label", { $: "Material types" }),
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [requiredRule],
        isEditEnabled: () => isEditingMaterialType,
        multiple: false,
        placeholder: $t("material-list-subTypes-label", { $: "Material types" }),
        values: translateEditorEntries(enumToEditorEntries(MaterialSubType), translateResourceSubType)
      },
      floatingFilterComponentParams: {
        placeholder: $t("equipment-edit-type-label", { $: "Type" }),
        values: translateEditorEntries(enumToEditorEntries(MaterialSubType), translateResourceSubType)
      }
    },
    {
      field: "trackingType",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("material-list-trackingType-label", { $: "Tracking Type" }),
      valueFormatter: (params: ValueFormatterParams) => translateTrackingType(params.data.trackingType),
      editable: true,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(ResourceTrackingType), translateTrackingType),
        comparator: enumValueEntryWithLocaleComparator
      },
      cellEditorParams: {
        isEditEnabled: () => isEditingMaterialType,
        placeholder: $t("productTemplate-edit-trackingType-label", { $: "Tracking Type" }),
        values: translateEditorEntries(enumToEditorEntries(ResourceTrackingType), translateTrackingType)
      } as unknown as IEnumValueSelectCellEditorParams,
      floatingFilterComponentParams: {
        placeholder: $t("productTemplate-edit-trackingType-label", { $: "Tracking Type" }),
        values: translateEditorEntries(enumToEditorEntries(ResourceTrackingType), translateTrackingType)
      }
    },
    {
      field: "measurementUnit",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("material-list-measurementUnit-label", { $: "Measurement Unit" }),
      editable: true,
      sortable: true,
      valueFormatter: (params: any) => translateMeasurementUnit(params.data.measurementUnit),
      cellEditorParams: {
        isEditEnabled: () => !gridWrapperRef.value?.isUpdating() && isEditingMaterialType,
        rules: [requiredRule],
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        placeholder: $t("material-edit-measurementUnit-label", { $: "Measurement Unit" })
      },
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        comparator: enumValueEntryWithLocaleComparator
      },
      floatingFilterComponentParams: {
        placeholder: $t("material-edit-measurementUnit-label", { $: "Measurement Unit" }),
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit)
      }
    },
    {
      field: "orderableUnit",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("material-list-orderableUnit-label", { $: "Orderable Unit" }),
      editable: true,
      sortable: true,
      valueFormatter: (params: any) => translateMeasurementUnit(params.data.orderableUnit),
      cellEditorParams: {
        isEditEnabled: () => isEditingMaterialType,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        placeholder: $t("material-edit-orderableUnit-label", { $: "Orderable Unit" })
      },
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        comparator: enumValueEntryWithLocaleComparator
      },
      floatingFilterComponentParams: {
        placeholder: $t("material-list-orderableUnit-label", { $: "Orderable Unit" }),
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit)
      }
    },
    {
      field: "purchaseConversionRatio",
      type: ["numberInputTypeColumn", "numberFloatingFilterColumnType"],
      filter: "agNumberColumnFilter",
      headerValueGetter: (_: any) => $t("material-list-purchaseConversionRatio-label", { $: "Purchase Conversion Ratio" }),
      editable: true,
      sortable: true,
      cellEditorParams: {
        isEditEnabled: () => isEditingMaterialType
      }
    },
    {
      field: "indivisibleQuantity",
      type: ["numberInputTypeColumn", "numberFloatingFilterColumnType"],
      filter: "agNumberColumnFilter",
      headerValueGetter: (_: any) => $t("material-list-indivisibleQuantity-label", { $: "Indivisible Quantity" }),
      editable: true,
      sortable: true,
      cellEditorParams: {
        isEditEnabled: () => isEditingMaterialType
      }
    },
    {
      field: "infiniteCapacity",
      type: ["setFloatingFilterColumnType"],
      filter: "agSetColumnFilter",
      filterParams: booleanTypeColumnFilterParams,
      editable: true,
      sortable: true,
      cellDataType: "boolean",
      cellRendererParams: {
        disabled: () => !gridWrapperRef.value?.isEditing() && !isEditingMaterialType.value
      },
      headerValueGetter: (_: any) => $t("material-list-infiniteCapacity-label", { $: "Infinite Capacity" })
    },
    {
      field: "origin",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("material-list-origin-label", { $: "Origin" }),
      valueFormatter: (params: ValueFormatterParams) => translateMaterialOrigin(params.data.origin),
      editable: true,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MaterialOrigin), translateMaterialOrigin),
        comparator: enumValueEntryWithLocaleComparator
      },
      cellEditorParams: {
        placeholder: $t("material-edit-origin-label", { $: "Origin" }),
        isEditEnabled: () => isEditingMaterialType,
        values: translateEditorEntries(enumToEditorEntries(MaterialOrigin), translateMaterialOrigin)
      } as unknown as IEnumValueSelectCellEditorParams,
      floatingFilterComponentParams: {
        placeholder: $t("material-edit-origin-label", { $: "Origin" }),
        values: translateEditorEntries(enumToEditorEntries(MaterialOrigin), translateMaterialOrigin)
      }
    },
    {
      field: "purposes",
      editable: true,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(ResourcePurpose), translateResourcePurpose),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => (params.data.purposes ? params.data.purposes.map(translateResourcePurpose).join(", ") : undefined),
      headerValueGetter: (_: any) => $t("material-list-purposes-label", { $: "Purposes" }),
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [
          requiredNotEmptyArrayRule,
          (v: any) =>
            (Array.isArray(v) && !(v.includes(ResourcePurpose.Production) && v.includes(ResourcePurpose.Purchase))) ||
            $t("material-list-purposes-validation-productionPurchasePurpose-tooltip", { $: "Material cannot have both production and purchase purposes" })
        ],
        isEditEnabled: () => isEditingMaterialType,
        multiple: true,
        placeholder: $t("material-list-purposes-label", { $: "Purposes" }),
        values: translateEditorEntries(enumToEditorEntries(ResourcePurpose), translateResourcePurpose)
      },
      floatingFilterComponentParams: {
        placeholder: $t("material-list-purposes-label", { $: "Purposes" }),
        values: translateEditorEntries(enumToEditorEntries(ResourcePurpose), translateResourcePurpose)
      }
    },
    {
      field: "infiniteCapacityOffset",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("material-list-infiniteCapacityOffset-label", { $: "Infinite Capacity Offset" }),
      filter: intervalTypeColumnFilter,
      filterParams: intervalTypeColumnFilterParams($t("material-filter-infiniteCapacityOffset-label", { $: "format: '?d ?h ?m ?s'" })),
      editable: true,
      sortable: true,
      valueParser: (params: any) => asIsoFormattedString(params.newValue),
      valueFormatter: (params: any) => asJiraFormattedString(params.data.infiniteCapacityOffset),
      cellEditorParams: {
        valueInitializer: (value: any) => asJiraFormattedString(value),
        isEditEnabled: () => isEditingMaterialType,
        placeholder: $t("material-edit-infiniteCapacityOffset-label", { $: "format: '?d ?h ?m ?s'" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("material-filter-infiniteCapacityOffset-label", { $: "format: '?d ?h ?m ?s'" })
      }
    },
    {
      field: "tags",
      headerValueGetter: (_: any) => $t("material-list-tags-label", { $: "Tags" }),
      type: ["tagsPickerTypeColumn", "setFloatingFilterColumnType"],
      editable: true,
      resizable: true,
      filter: "agSetColumnFilter",
      filterParams: tagsTypeColumnFilterParams(tagsStore),
      valueFormatter: (params: ValueFormatterParams) => joinArrayOfStrings(params.data.tags),
      cellEditorParams: {
        placeholder: $t("material-edit-tags-label", { $: "Tags" })
      }
    },
    {
      field: "plannable",
      type: ["setFloatingFilterColumnType"],
      filter: "agSetColumnFilter",
      filterParams: booleanTypeColumnFilterParams,
      headerValueGetter: (_: any) => $t("material-list-plannable-label", { $: "Plannable" }),
      editable: true,
      resizable: true,
      sortable: true,
      cellDataType: "boolean",
      cellRendererParams: {
        disabled: () => !gridWrapperRef.value?.isEditing()
      }
    },
    {
      field: "createdBy",
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("material-list-createdBy-label", { $: "Created By" }),
      floatingFilterComponentParams: {
        placeholder: $t("material-list-createdBy-label", { $: "Created By" })
      }
    },
    {
      field: "createdAt",
      editable: false,
      resizable: true,
      type: ["dateTimeTypeColumn"],
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("material-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("material-list-modifiedBy-label", { $: "Modified By" }),
      floatingFilterComponentParams: {
        placeholder: $t("material-list-modifiedBy-label", { $: "Modified By" })
      }
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("material-list-modifiedAt-label", { $: "Modified At" })
    }
  ];
}

function getContextMenuItems(param: any) {
  return [
    {
      name: $t("material-resource2resource-scrap-action", { $: "Scrap Assignment" }),
      action: () => {
        emit("resource2resource", ResourceType.Material, param.node.data);
      },
      disabled: !param.node || (isDefined(param.node?.data?.subTypes) && param.node?.data.subTypes.some((x: MaterialSubType) => [MaterialSubType.Scrap].includes(x)))
    },
    {
      name: $t("material-resource2resource-material-action", { $: "Material Assignment" }),
      action: () => {
        emit("resource2resourceReverse", ResourceType.Material, param.node.data);
      },
      disabled: !param.node
    },
    {
      name: $t("material-gantt-contextMaterialGantt-action", { $: "Open Context Gantt For Material" }),
      action: () => {
        if (param.node.data.id) {
          openContextMaterialGanttGridAction(param.node.data.id);
        }
      },
      disabled: !param.node || !param.node?.data?.id
    }
  ];
}

function onTypeChange(newValue: any, oldValue: any, params?: ICellEditorParams) {
  isEditingMaterialType.value = newValue === ResourceType.Material;

  if (params) {
    const isEditMode = !!params.data.id;

    if (!isEditMode) {
      const isPlannable = newValue === ResourceType.Material;
      refreshCheckboxCellEditor({ value: isPlannable, api: params.api, node: params.node, column: "plannable" });
    }
  }
}

function showDetails(data: MaterialDto) {
  emit("showDetails", data);
}

function onSelectionChanged(event: SelectionChangedEvent) {
  const { api } = event;
  const selectedRows = getSelectedRows(api);
  if (selectedRows.length > 0) {
    selectedMaterialId.value = selectedRows[0].id;
  } else {
    selectedMaterialId.value = null;
  }

  emit("selectionChanged", event);
}

function refreshSingle(id: string) {
  console.error("refreshSingle", id);
  serverSideDataSource.getSingle(id).then((data) => {
    if (data) {
      gridWrapperRef.value?.gridApi.forEachNode((node) => {
        if (node.data.id === id) {
          node.updateData(data);
          emit("showDetails", data);
        }
      });
    } else {
      console.warn(`Could not find row with id ${id} to refresh!`);
    }
  });
}

function mapToResourceInfoDto(id: string | null, businessId: string | null, name: string | null): ResourceInfoDto | null {
  if (id || businessId || name) {
    const resourceInfo: ResourceInfoDto = {
      id: id as string,
      scenarioId: "",
      name: name,
      businessId: businessId,
      type: ResourceType.PersonGroup,
      plannable: false
    };

    return resourceInfo;
  } else {
    return null;
  }
}

const filterGridAction = useFilterGridAction({ filterKey: "subTypes", gridWrapperRef: gridWrapperRef, filterGridActionRef: filterGridActionRef });

const filterGridActionItems: FilterGridActionItem[] = [
  { value: MaterialSubType.RawOrSubcomponent, text: translateResourceSubType(ResourceSubType.MaterialRawOrSubcomponent) },
  { value: MaterialSubType.SemiFinishedOrFinalProduct, text: translateResourceSubType(ResourceSubType.MaterialSemiFinishedOrFinalProduct) }
];

const ganttDetailActions = ref([
  {
    title: $t("material-list-contextGanttForMaterial-action", { $: "Selected Resource in directive resource context" }),
    tooltip: $t("material-list-contextGanttForMaterial-action", { $: "Selected Resource in directive resource context" }),
    action: () => openContextMaterialGanttGridAction(selectedMaterialId.value!),
    icon: "mdi-chart-gantt",
    disabled: () => selectedMaterialId.value === null
  }
]);
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    create-btn
    create-child-btn
    duplicate-btn
    edit-btn
    delete-btn
    refresh-btn
    details-btn
    row-selection="multiple"
    identifier="materials"
    :default-col-def="defaultColumnDef"
    :auto-group-column-def="autoGroupColumnDef"
    :context-menu-items="getContextMenuItems"
    server-side
    :is-server-side-group="isServerSideGroup"
    :get-server-side-group-key="getServerSideGroupKey"
    :get-child-count="getServerSideChildCount"
    :tree-data="true"
    :enable-group-edit="true"
    :server-side-datasource="serverSideDataSource"
    :create-default-value="DEFAULT_CREATE_VALUE"
    @refresh-action="loadModelInstances"
    @prepare-columns="onPrepareColumns"
    @filter-changed="filterGridAction.onFilterGridModelChanged"
    @ready="onReady"
    @selection-changed="onSelectionChanged"
    @details="showDetails"
  >
    <template #custom-buttons>
      <actions-button :model-value="ganttDetailActions" :label="$t('actions-button-gantt-label', { $: 'Gantt' })">
        <template #prepend>
          <open-resource-gantt-grid-action :types="ganttTypes" :grid-api="gridWrapperRef?.gridApi" />
        </template>
      </actions-button>
    </template>
    <template #filter>
      <filter-grid-action ref="filterGridActionRef" :items="filterGridActionItems" @filter-changed="filterGridAction.onFilterGridActionChanged" />
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
