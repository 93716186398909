import { ApiClient } from "@/services/api/common.api";
import {
  CreateOrUpdateInboundDeliveryCommand,
  CreateOrUpdateInboundDeliveryLineCommand,
  GetInboundDeliveriesPaginatedQuery,
  GetInboundDeliveryLinesPaginatedQuery,
  IAgGridPaginationResponse,
  InboundDeliveryDto,
  InboundDeliveryLineDto,
  RegisterReleaseInboundDeliveriesToProductionCommand,
  RegisterReleaseInboundDeliveryLinesToProductionCommand,
  RegisterUnreleaseInboundDeliveryCommand
} from "@masta/generated-model";

export default {
  async getAll(scenarioId?: string) {
    return ApiClient.get<IAgGridPaginationResponse<InboundDeliveryDto>>(`/inboundDeliveries?scenarioId=${scenarioId}`);
  },
  async getPaginated(request: GetInboundDeliveriesPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<InboundDeliveryDto>>(`/inboundDeliveries`, request);
  },
  async getSingle(id: string, scenarioId: string) {
    return ApiClient.get<InboundDeliveryDto>(`/inboundDeliveries/${id}?scenarioId=${scenarioId}`);
  },
  async createOrUpdate(request: CreateOrUpdateInboundDeliveryCommand) {
    return ApiClient.put<string>(`/inboundDeliveries`, request);
  },
  async remove(id: string, scenarioId?: string) {
    return ApiClient.delete(`/inboundDeliveries/${id}?scenarioId=${scenarioId}`);
  },
  async releaseToProduction(request: RegisterReleaseInboundDeliveriesToProductionCommand) {
    return ApiClient.post("/inboundDeliveries/releaseToProduction", request);
  },
  async getInboundDeliveryLinesPaginated(request: GetInboundDeliveryLinesPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<InboundDeliveryLineDto>>(`/inboundDeliveries/${request.orderId}/lines`, request);
  },
  async getInboundDeliveryLine(orderId: string, orderLineId: string, scenarioId: string) {
    return ApiClient.get(`/inboundDeliveries/${orderId}/lines/${orderLineId}?scenarioId=${scenarioId}`);
  },
  async releaseInboundDeliveryLinesToProduction(request: RegisterReleaseInboundDeliveryLinesToProductionCommand) {
    return ApiClient.post(`/inboundDeliveries/${request.orderId}/releaseToProduction`, request);
  },
  async unrelease(request: RegisterUnreleaseInboundDeliveryCommand) {
    return ApiClient.post(`/inboundDeliveries/${request.orderId}/unrelease`, request);
  },
  // async confirm(orderId: string) {
  //   return ApiClient.post(`/inboundDeliveries/${orderId}/confirm`);
  // },
  // async markAsQuotationSent(orderId: string) {
  //   return ApiClient.post(`/inboundDeliveries/${orderId}/markAsQuotationSent`);
  // },
  async createOrUpdateInboundDeliveryLine(request: CreateOrUpdateInboundDeliveryLineCommand) {
    return ApiClient.put<string>(`/inboundDeliveries/${request.orderId}/lines`, request);
  },
  async removeInboundDeliveryLine(orderId: string, id: string, scenarioId: string) {
    return ApiClient.delete(`/inboundDeliveries/${orderId}/lines/${id}?scenarioId=${scenarioId}`);
  }
  // async correctInboundDeliveryLine(request: RegisterCorrectInboundDeliveryLineCommand) {
  //   return ApiClient.post(`/inboundDeliveries/${request.orderId}/lines/${request.id}/correct`, request);
  // },
  // async updateModelInstanceAssignment(request: UpdateInboundDeliveryModelInstanceAssignmentCommand) {
  //   return ApiClient.post(`/inboundDeliveries/updateModelAssignment`, request);
};
