<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import ApiService from "@/services/api";
import { AttachmentSourceType } from "@masta/generated-model";
import VuePdfEmbed from "vue-pdf-embed";
import { $t } from "@/i18n";
import Online3dViewer from "@/views/ProductOverview/Online3dViewer.vue";

const props = defineProps<{
  documentId: string;
  revisionNumber: number;
  fileName: string;
  attachmentSourceType?: AttachmentSourceType;
  attachmentContentStorageConfigurationId?: string;
}>();

const contentType = ref<string | null>(null);
const url = ref<string | null>(null);
const fileFunc = ref<(() => Promise<File>) | null>(null);
const loadContent = async () => {
  try {
    const { data } = await ApiService.documents.getDocumentContentStream({
      documentId: props.documentId,
      revisionNumber: props.revisionNumber,
      attachmentSourceType: props.attachmentSourceType,
      attachmentContentStorageConfigurationId: props.attachmentContentStorageConfigurationId
    });
    contentType.value = data.type;
    if (contentType.value === "text/plain") {
      url.value = await data.text();
    } else {
      url.value = window.URL.createObjectURL(data);
    }
    if (contentType.value === "application/octet-stream" && props.fileName?.toLowerCase().endsWith("stp")) {
      const blob = new Blob([data], { type: "application/octet-stream" });
      fileFunc.value = () => Promise.resolve(new File([blob], props.fileName));
    }
  } catch (e) {
    console.error(e);
  }
};

onMounted(loadContent);

watch(() => props.documentId, loadContent);

async function onShowContent() {
  try {
    const a = document.createElement("a");
    a.href = url.value!;
    a.download = props.fileName;
    a.target = "_blank";
    a.click();
  } catch (e) {
    console.error(e);
  }
}
</script>

<template>
  <v-row class="mb-4">
    <v-col cols="6">
      <slot />
    </v-col>
    <v-col cols="6">
      <v-btn prepend-icon="mdi-download" color="indigo-lighten-5" block @click="onShowContent">
        {{ $t("documentAttachmentDefinition-content-action", { $: "Download content" }) }}
      </v-btn>
    </v-col>
  </v-row>
  <v-img v-if="contentType?.startsWith('image/')" :src="url" height="240" />
  <video v-else-if="contentType?.startsWith('video/')" controls height="340">
    <source :src="url" :type="contentType" />
  </video>
  <v-textarea v-else-if="contentType === 'text/plain'" v-model="url" readonly :rules="[() => true]"></v-textarea>
  <vue-pdf-embed v-else-if="contentType === 'application/pdf'" :source="url" />
  <span v-else-if="contentType === 'application/octet-stream' && fileName?.toLowerCase().endsWith('stp') && fileFunc !== null" :source="url">
    <Online3dViewer :file="fileFunc" />
  </span>
</template>

<style lang="scss" scoped></style>
