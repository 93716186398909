import { ApiClient } from "@/services/api/common.api";
import {
  AssignModelInstanceToCostCatalogueItemCommand,
  CostCatalogueItemDto,
  CreateOrUpdateCostCatalogueItemCommand,
  CreateOrUpdateCostCatalogueItemResponse,
  DeassignModelInstanceFromCostCatalogueItemCommand,
  GenerateCostCatalogueCommand,
  GetCostCatalogueItemsPaginatedQuery,
  IAgGridPaginationResponse
} from "@masta/generated-model";

export default {
  async getPaginated(request: GetCostCatalogueItemsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<CostCatalogueItemDto>>(`/costCatalogue`, request);
  },
  async getSingle(id: string, scenarioId: string) {
    return ApiClient.get<CostCatalogueItemDto>(`/costCatalogue/${id}?scenarioId=${scenarioId}`);
  },
  async getCostCatalogue({ scenarioId }: { scenarioId: string }) {
    return ApiClient.get<any>(`/costCatalogue?scenarioId=${scenarioId}`);
  },
  async create(request: Partial<CreateOrUpdateCostCatalogueItemCommand>) {
    return ApiClient.put<CreateOrUpdateCostCatalogueItemResponse>(`/costCatalogue`, request);
  },
  async update(request: Partial<CreateOrUpdateCostCatalogueItemCommand>) {
    return ApiClient.put<CreateOrUpdateCostCatalogueItemResponse>(`/costCatalogue/${request.id}`, request);
  },
  async remove(id: string, scenarioId: string) {
    return ApiClient.delete(`/costCatalogue/${id}?scenarioId=${scenarioId}`);
  },
  async generate(request: Partial<GenerateCostCatalogueCommand>) {
    return ApiClient.post(`/costCatalogue/generate`, request);
  },
  async assignModelInstance(request: AssignModelInstanceToCostCatalogueItemCommand) {
    return ApiClient.post(`/costCatalogue/assignModelInstance`, request);
  },
  async deassignModelInstance(request: DeassignModelInstanceFromCostCatalogueItemCommand) {
    return ApiClient.post(`/costCatalogue/deassignModelInstance`, request);
  }
};
