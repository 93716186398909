<script lang="ts" setup>
import { groupTypeCellStyle } from "@/components/Grid/CellRenderers/GroupTypeCellStyle";
import { enumToEditorEntries, enumToEditorEntriesOnlyIncluding, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { booleanTypeColumnFilterParams } from "@/components/Grid/Filters/BooleanTypeColumnFilters";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import ResourcesPickerDialog from "@/components/Resources/ResourcesPickerDialog.vue";
import {
  translateMeasurementUnit,
  translateResourceType
} from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useMaterialsStore } from "@/store/MaterialsStore";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { MeasurementUnit, ModelInstanceAssignmentsDto, ResourceInfoDto, ResourceType } from "@masta/generated-model";
import { CellClassParams, KeyCreatorParams, SelectionChangedEvent, ValueFormatterParams } from "ag-grid-community";
import { ref, watch } from "vue";

const modelInstanceId = defineModel<string>({
  required: true
});
const assignments = ref<ModelInstanceAssignmentsDto | null>(null);
const modelInstanceStore = useModelInstancesStore();
const materialsStore = useMaterialsStore();
const snackbarsStore = useSnackbarsStore();
const selectedRow = ref<ResourceInfoDto | null>(null);

const gridWrapperRef = ref<GridWrapperComponent>();

const modelInstanceResourcAssignDialog = ref(false);

const selectedResourcesToAssign = ref<ResourceInfoDto[]>();

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

watch(modelInstanceId, async (id) => {
  if (id) {
    await fetchModelInstanceAssignments();
  }
}, { immediate: true });

watch(selectedResourcesToAssign, async (resources) => {
  if (resources) {
    for (const resource of resources) {
      await materialsStore.assignModelInstance({
        modelInstanceId: modelInstanceId.value,
        resourceId: resource.id,
        scenarioId: resource.scenarioId
      } as any);
      snackbarsStore.createSnackbar({
        message: $t("modelInstanceAssignments-snackbar-resource-assigned", { $: "Resource {resource} assigned", resource: resource.businessId }),
        closeable: true,
        timeout: 2500
      });
    }
    await fetchModelInstanceAssignments();
  }
});

async function fetchModelInstanceAssignments() {
  selectedRow.value = null;
  assignments.value = await modelInstanceStore.fetchModelInstanceAssignments(modelInstanceId.value);
}

function assignResource() {
  modelInstanceResourcAssignDialog.value = true;
}

async function unassignResource() {
  if (gridWrapperRef.value == null) return;

  const selectedRows = gridWrapperRef.value.gridApi.getSelectedRows();
  if (selectedRows.length === 0) return;

  for (const row of selectedRows) {
    const selectedResource = row as ResourceInfoDto;

    await materialsStore.deassignModelInstance({
      modelInstanceId: modelInstanceId.value,
      resourceId: selectedResource.id,
      scenarioId: selectedResource.scenarioId
    } as any);

    snackbarsStore.createSnackbar({
      message: "<span class='mdi mdi-save'></span>" + $t("modelInstanceAssignments-snackbar-resource-deassigned", {
        $: "Resource {resource} deassigned", resource:
        selectedResource.businessId
      }),
      closeable: true,
      timeout: 2500
    });
  }

  await fetchModelInstanceAssignments();
}

function onSelectionChanged(e: SelectionChangedEvent) {
  if (e.source === "rowDataChanged") return;
  const selectedRows = e.api.getSelectedRows();
  selectedRow.value = selectedRows.length > 0 ? selectedRows[0] : null;
}

function isResourceGroup(resource: ResourceInfoDto) {
  return resource.type === ResourceType.MaterialGroup
    || resource.type === ResourceType.EquipmentGroup
    || resource.type === ResourceType.PersonGroup
    || resource.type === ResourceType.AgreementGroup;
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "name",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("modelInstanceResources-list-name-label", { $: "Name" }),
      type: ["textFloatingFilterColumnType"],
      floatingFilterComponentParams: {
        placeholder: $t("modelInstanceResources-list-name-label", { $: "Name" })
      }
    },
    {
      field: "businessId",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("modelInstanceResources-list-businessId-label", { $: "Business ID" }),
      type: ["textFloatingFilterColumnType"],
      floatingFilterComponentParams: {
        placeholder: $t("modelInstanceResources-list-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "type",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("modelInstanceResources-list-type-label", { $: "Type" }),
      cellStyle: (params: CellClassParams) => groupTypeCellStyle(params, isResourceGroup),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(ResourceType, [ResourceType.Material, ResourceType.MaterialGroup]), translateResourceType),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => translateResourceType(params.data.type),
      floatingFilterComponentParams: {
        placeholder: $t("modelInstanceResources-list-type-label", { $: "Type" }),
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(ResourceType, [ResourceType.Material, ResourceType.MaterialGroup]), translateResourceType)
      }
    },
    {
      field: "plannable",
      type: ["setFloatingFilterColumnType"],
      filter: "agSetColumnFilter",
      filterParams: booleanTypeColumnFilterParams,
      headerValueGetter: (_: any) => $t("modelInstanceResources-list-plannable-label", { $: "Plannable" }),
      editable: false,
      resizable: true,
      sortable: true,
      cellDataType: "boolean"
    },
    {
      field: "measurementUnit",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("modelInstanceResources-list-measurementUnit-label", { $: "Measurement Unit" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => translateMeasurementUnit(params.data.measurementUnit),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        comparator: enumValueEntryWithLocaleComparator
      },
      floatingFilterComponentParams: {
        placeholder: $t("modelInstanceResources-list-measurementUnit-label", { $: "Measurement Unit" }),
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit)
      }
    },
    {
      field: "orderableUnit",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("modelInstanceResources-list-orderableUnit-label", { $: "Orderable Unit" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => translateMeasurementUnit(params.data.orderableUnit),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        comparator: enumValueEntryWithLocaleComparator
      },
      floatingFilterComponentParams: {
        placeholder: $t("modelInstanceResources-list-orderableUnit-label", { $: "Orderable Unit" }),
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit)
      }
    }
  ];
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    :default-col-def="defaultColumnDef"
    row-selection="multiple"
    identifier="modelInstanceResources"
    hide-custom-actions-separator
    :grid-data="assignments?.resources"
    @selection-changed="onSelectionChanged"
    @prepare-columns="onPrepareColumns">
    <template #custom-buttons>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" @click="assignResource">
              <v-icon icon="mdi-playlist-plus" class="pr-4"></v-icon>
              {{ $t("modelInstanceResources-list-assign-action", { $: "Assign" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstanceResources-list-assign-tooltip", { $: "Assign" }) }}</span>
      </v-tooltip>

      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn
size="small" variant="text" density="compact" v-bind="props" :disabled="!selectedRow"
                   @click="unassignResource">
              <v-icon icon="mdi-playlist-minus" class="pr-4"></v-icon>
              {{ $t("modelInstanceResources-list-unassign-action", { $: "Unassign" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstanceResources-list-unassign-tooltip", { $: "Unassign" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>

  <resources-picker-dialog
    v-model:selected-resources="selectedResourcesToAssign"
    v-model:dialog="modelInstanceResourcAssignDialog"
    :preselected-resource-ids="assignments?.resources.map((resourceInfo) => (resourceInfo.id))">
  </resources-picker-dialog>
</template>

<style scoped lang="scss">
</style>
