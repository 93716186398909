import {
  CreateProductTemplateDraftDto,
  CreateStepDraftDto,
  CreateStepDraftResourceSpecDto,
  ProductTemplateDraftDto,
  StepDraftDto,
  StepDraftResourceSpecDto,
  ResourceInfoDto
} from "@masta/generated-model";
import { asServerSideDurationFormattedString } from "@masta/shared";

export function mapProductTemplateDrafts(dtos: ProductTemplateDraftDto[] | null): CreateProductTemplateDraftDto[] {
  return dtos?.map(mapProductTemplateDraft) ?? [];
}

function mapProductTemplateDraft(dto: ProductTemplateDraftDto): CreateProductTemplateDraftDto {
  return {
    name: dto.name,
    wbs: dto.wbs,
    businessId: dto.businessId,
    tags: dto.tags,
    resourceId: mapResource(dto.resource),
    resourceBusinessId: null,
    childProductTemplates: mapProductTemplateDrafts(dto.childProductTemplates),
    steps: mapSteps(dto.steps),
    useExistingTemplate: dto.useExistingTemplate
  };
}

function mapSteps(dtos: StepDraftDto[] | null): CreateStepDraftDto[] {
  return dtos?.map(mapStep) ?? [];
}

function mapStep(dto: StepDraftDto): CreateStepDraftDto {
  return {
    name: dto.name,
    position: dto.position,
    type: dto.type,
    processingTime: asServerSideDurationFormattedString(dto.processingTime) ?? "",
    quantity: dto.quantity,
    specs: mapSpecs(dto.specs)
  };
}

function mapSpecs(dtos: StepDraftResourceSpecDto[] | null): CreateStepDraftResourceSpecDto[] {
  return dtos?.map(mapSpec) ?? [];
}

function mapSpec(dto: StepDraftResourceSpecDto): CreateStepDraftResourceSpecDto {
  return {
    resourceType: dto.resourceType,
    resources: mapResources(dto.resources),
    externalResources: null,
    quantity: dto.quantity,
    assignmentType: dto.assignmentType,
    usageType: dto.usageType,
    inventoryChangeThresholdQuantity: dto.inventoryChangeThresholdQuantity,
    inventoryChangeType: dto.inventoryChangeType,
    isBase: dto.isBase
  };
}

function mapResources(dtos: ResourceInfoDto[] | null): string[] {
  return dtos?.map(mapResource).filter((x): x is string => x !== null) ?? [];
}

function mapResource(dto: ResourceInfoDto | null) {
  return dto?.id ?? null;
}
