import { ApiClient } from "@/services/api/common.api";
import { DocumentDto, GetQuotationDocumentPaginatedQuery, IAgGridPaginationResponse } from "@masta/generated-model";

export default {
  async getByOrderId(request: GetQuotationDocumentPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<DocumentDto>>(`/quotationDocuments`, request); //BS: TODO need to extract only quotation documents
  },
  async getSingle(documentId: string, revisionNumber: number, scenarioId: string) {
    return ApiClient.get<DocumentDto>(`/quotationDocuments/${documentId}/${revisionNumber}?scenarioId=${scenarioId}`);
  }
};
