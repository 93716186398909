<script lang="ts" setup>
import CloseCardButton from "@/components/CloseCardButton.vue";
import { translateOrderType } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { OrderDto, OrderType } from "@masta/generated-model";
import { ref } from "vue";
import { VBtn } from "vuetify/lib/components/index.mjs";

import CustomerOrdersGrid from "@/components/CustomerOrders/CustomerOrdersGrid.vue";
import InboundDeliveriesGrid from "@/components/InboundDeliveries/InboundDeliveriesGrid.vue";
import PurchaseOrdersGrid from "@/components/PurchaseOrders/PurchaseOrdersGrid.vue";

import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { IRowNode, SelectionChangedEvent } from "ag-grid-community";


const modelValue = defineModel<OrderDto[] | null | undefined>("selectedOrders", { required: true, default: []});
const dialog = defineModel<boolean>("dialog", { required: true, default: false });
const props = defineProps<{
    /**
     * The order IDs that should be preselected in the picker.
     */
    preselectedOrderIds?: string[];
}>();

const selectedOrderType = ref<OrderType>(OrderType.Customer);

const selectedOrders = ref<OrderDto[] | null | undefined>([]);

const orderTypeChipItems = ref<{ value: OrderType, text: string }[]>(
  [
    { value: OrderType.Customer, text: translateOrderType(OrderType.Customer) },
    { value: OrderType.InboundDelivery, text: translateOrderType(OrderType.InboundDelivery) },
    { value: OrderType.Purchase, text: translateOrderType(OrderType.Purchase) },
  ]
);

function onBtnAdd() {
  if (selectedOrders.value) {
    modelValue.value = selectedOrders.value;
  }

  dialog.value = false;
}

function onBtnCancel() {
  dialog.value = false;
}

function onSelectionChanged({ api }: SelectionChangedEvent<OrderDto>) {
  const selectedOrderRows = getSelectedRows(api);
  selectedOrders.value = selectedOrderRows;
}

function isRowSelectable(row: IRowNode) {
  return !!row.data && !props.preselectedOrderIds?.includes(row.data.id);
}
</script>

<template>
  <v-dialog v-model="dialog" width="100%" height="90vh" @click:outside="onBtnCancel">
    <v-card min-width="300px" height="100%" class="reference-picker-card">
      <close-card-button @click="onBtnCancel"></close-card-button>
      <v-card-title class="pa-0">
        <div class="text-h4 pb-5">{{ $t("ordersPicker-list-addOrders-label", { $: "Add orders" }) }}</div>
      </v-card-title>
      <v-card-subtitle>
        <v-chip-group v-model="selectedOrderType" class="d-flex justify-start">
            <v-chip
                v-for="item in orderTypeChipItems"
                :key="item.value"
                variant="elevated"
                elevation="0"
                size="large"
                slim
                density="compact"
                class="order-type-chip"
                :color="item.value === selectedOrderType ? 'primary' : ''"
                :value="item.value">
                {{ item.text }}
            </v-chip>
        </v-chip-group>        
      </v-card-subtitle>
      <v-card-text class="px-0">
        <customer-orders-grid 
          v-if="selectedOrderType === OrderType.Customer"
          :is-row-selectable="isRowSelectable"
          @selection-changed="onSelectionChanged" />

        <inbound-deliveries-grid
          v-if="selectedOrderType === OrderType.InboundDelivery"
          :is-row-selectable="isRowSelectable"
          @selection-changed="onSelectionChanged" />
        
        <purchase-orders-grid
          v-if="selectedOrderType === OrderType.Purchase"
          :is-row-selectable="isRowSelectable"
          @selection-changed="onSelectionChanged" />
      </v-card-text>
      <v-card-actions class="pa-0">
        <div class="left-col pl-0">
          <v-btn variant="elevated" color="secondary" class="w-100" @click="onBtnCancel">
            {{ $t("ordersPicker-list-cancel-label", { $: "Cancel" }) }}
          </v-btn>
        </div>
        
        <div class="middle-col"></div>
        <div class="right-col pr-0">
          <v-btn variant="elevated" color="primary" class="w-100" @click="onBtnAdd">
            {{ $t("ordersPicker-list-add-label", { $: "Add" }) }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.order-type-chip {
    background-color: rgb(var(--v-theme-primary), 0.3);
    color: rgb(var(--v-theme-primary));
    min-height: 26px;
}
</style>