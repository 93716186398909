import { KpiResultDto } from "@masta/generated-model";
import { CellClassParams } from "ag-grid-community";

/**
 * This function is used to set the background color of the cell based on the value of the cell.
 * - If the value is negative, the background color will be red with an opacity based on the value.
 * - If the value is positive, the background color will be green with an opacity based on the value.
 * - If the value is 0, the background color will be the default color of grid cell.
 *
 * Opacity is calculated as the value divided by the min or max value of the range of values.
 *
 * @param params
 * @param minValue - min value of the range of values
 * @param maxValue - max value of the range of values
 * @returns
 */
export function normalizedPercentValueCellStyle(params: CellClassParams<KpiResultDto, number>) {
  const { data, node, value } = params;

  if (value === null || value === undefined || value === 0) return;

  if (value <= 0)
    return {
      "background-color": "rgb(255, 255, 255)"
    };

  if (value < 40)
    return {
      "background-color": "rgb(232, 245, 233)"
    };

  if (value < 80)
    return {
      "background-color": "rgb(165, 214, 167)"
    };
  if (value <= 100)
    return {
      "background-color": "rgb(76, 175, 80)"
    };
  if (value > 100)
    return {
      "background-color": "rgb(244, 67, 54)"
    };

  return null;
}
