<template>
  <master-detail-layout scenario-selector :detail="locationDetails !== null" detail-dialog-width="100%" @scenario-selection-changed="onScenarioSelectionChanged">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("location-view-locations-title", { $: "Locations" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <location-grid @manage-availability-rules="manageAvailabilityRules" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-col v-if="editAvailabilityRulesAssignmentDialog">
        <availability-rules-assignment-editor
          :resource="locationResource"
          :opened="editAvailabilityRulesAssignmentDialog"
          @cancel="editAvailabilityRulesAssignmentDialog = false"
          @save="editAvailabilityRulesAssignmentDialog = false"
        />
      </v-col>
    </template>
    <template #detail>
      <v-row>
        <v-col v-if="locationDetails" cols="12">
          <v-row>
            <v-col cols="12">
              <v-card class="mt-2">
                <v-card-title>
                  <v-icon>mdi-semantic-web</v-icon>
                  {{ $t("location-view-storedResources-label", { locationName: locationDetails.name, $: "{locationName} stored resources" }) }}
                </v-card-title>
                <v-card-text class="fill-height">
                  <location-resource-capacities-grid :capacities="locationDetails.capacities" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else>
          <v-card>
            <v-card-title>{{ $t("location-view-selectLocation-label", { $: "Select location" }) }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </master-detail-layout>
</template>

<script>
import LocationGrid from "@/components/Location/LocationGrid.vue";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import LocationResourceCapacitiesGrid from "@/components/Location/LocationResourceCapacitiesGrid.vue";
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor";
import { mapActions, mapState } from "pinia";
import { useLocationsStore } from "@/store/LocationsStore";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";

export default {
  name: "Location",
  components: {
    Breadcrumbs,
    AvailabilityRulesAssignmentEditor,
    LocationGrid,
    MasterDetailLayout,
    LocationResourceCapacitiesGrid
  },
  title: "Location",
  data() {
    return {
      editAvailabilityRulesAssignmentDialog: false,
      locationResource: null,
      refreshGridAction: null
    };
  },
  computed: {
    ...mapState(useLocationsStore, ["locations", "locationDetails"])
  },
  beforeUnmount() {
    this.resetDetails();
  },
  methods: {
    ...mapActions(useLocationsStore, ["fetch", "resetDetails"]),
    onScenarioSelectionChanged() {
      this.fetch();
    },
    manageAvailabilityRules(locationResource) {
      this.locationResource = locationResource;
      this.editAvailabilityRulesAssignmentDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
