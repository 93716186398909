<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { ref } from "vue";
import { $t } from "@/i18n";
import { CellClassParams, ValueFormatterParams } from "ag-grid-community";
import { translateMaterialDemandQuantityType, translateMeasurementUnit } from "@/composables/translateEnum";
import ApiService from "@/services/api";
import { useErrorsStore } from "@/store/ErrorsStore";
import { GetMaterialsDemandQuery, MaterialDemandItemDto } from "@masta/generated-model";
import MaterialsDemandCalculateDialog from "@/components/MaterialsDemand/MaterialsDemandCalculateDialog.vue";

const gridWrapperRef = ref<GridWrapperComponent>();
const errorsStore = useErrorsStore();
const data = ref<MaterialDemandItemDto[]>([]);
const calculateDialog = ref(false);
const query = ref<GetMaterialsDemandQuery>();

async function onRefreshAction() {
  await onFetchData();
}

async function onFetchData() {
  await fetchData();
}

async function fetchData() {
  if (!query.value) {
    return;
  }
  try {
    const response = await ApiService.planning.getMaterialsDemand(query.value);
    data.value = response.data?.items ?? [];
  } catch (e) {
    errorsStore.handleError(e);
    throw e;
  }
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "resourceId",
      headerName: "Resource Id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("materialsDemand-list-resourceId-label", { $: "ResourceId" })
    },
    {
      field: "resourceBusinessId",
      headerName: "Resource Business Id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("materialsDemand-list-resourceBusinessId-label", { $: "Resource Business Id" })
    },
    {
      field: "resourceColor",
      headerName: "Resource Color",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["colorTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("materialsDemand-list-resourceColor-label", { $: "Resource Color" }),
      floatingFilterComponentParams: {
        placeholder: $t("materialsDemand-list-color-label", { $: "Color" })
      }
    },
    {
      field: "periodName",
      headerName: "PeriodName",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("materialsDemand-list-period-label", { $: "Period" })
    },
    {
      field: "quantity",
      headerName: "Quantity",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? params.value.toFixed(1) : null;
      },
      headerValueGetter: (_: any) => $t("materialsDemand-list-quantity-label", { $: "Quantity" })
    },
    {
      field: "quantityType",
      type: "enumTypeColumn",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("materialsDemand-list-quantityType-label", { $: "Quantity Type" }),
      valueFormatter: (params: ValueFormatterParams) => translateMaterialDemandQuantityType(params.value)
    },
    {
      field: "unit",
      type: "enumTypeColumn",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("materialsDemand-list-unit-label", { $: "Unit" }),
      valueFormatter: (params: ValueFormatterParams) => translateMeasurementUnit(params.value)
    }
  ];
}

const defaultColDef = ref({
  // allow every column to be aggregated
  enableValue: true,
  // allow every column to be grouped
  enableRowGroup: true,
  // allow every column to be pivoted
  enablePivot: true,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

function triggerCalculateAction() {
  calculateDialog.value = true;
}

async function calculationParamsSet(queryFromDialog: GetMaterialsDemandQuery) {
  calculateDialog.value = false;
  query.value = queryFromDialog;
  await fetchData();
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="materials-demand"
    :enable-charts="true"
    refresh-btn
    enable-range-selection
    enable-columns-tool-panel
    enable-filters-tool-panel
    row-selection="multiple"
    :grid-data="data"
    group-display-type="multipleColumns"
    :pivot-mode="false"
    :default-col-def="defaultColDef"
    :hide-custom-actions-separator="true"
    :checkbox-selection="false"
    :enable-actions-column="false"
    :pivot-default-expanded="-1"
    :group-default-expanded="-1"
    @fetch-data="onFetchData"
    @refresh-action="onRefreshAction"
    @prepare-columns="onPrepareColumns"
  >
    <template #custom-buttons>
      <v-tooltip location="bottom" open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" @click="triggerCalculateAction">
              <v-icon icon="mdi-calculator-variant-outline" class="pr-4" />
              {{ "Calculate" }}
            </v-btn>
            <materials-demand-calculate-dialog v-model="calculateDialog" @calculation-params-set="calculationParamsSet" />
          </div>
        </template>
        <span>{{ "Calculate" }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
</template>
