import { ApiClient } from "@/services/api/common.api";
import { GetShiftInstancePersonnelResult, TerminalTaskDetailsDto } from "@masta/generated-model";

export default {
  async getShiftInstancePersonnel(availabilityRuleId: string, date: string, scenarioId: string) {
    return ApiClient.get<GetShiftInstancePersonnelResult>(
      `/dispatch/${availabilityRuleId}/${date}/personnel?showSatisfaction=false&showConstraints=false&scenarioId=${scenarioId}`
    );
  },
  async startAutoDispatch(availabilityRuleId: string, startDate: string, scenarioId: string) {
    return ApiClient.post(`/dispatch/auto`, { availabilityRuleId, start: startDate, scenarioId });
  },
  async performManualDispatch(availabilityRuleId: string, date: string, personIds: string[], taskIds: string[], specIds: string[], scenarioId: string) {
    return ApiClient.post(`/dispatch/manual`, { availabilityRuleId, date, personIds, taskIds, specIds, scenarioId });
  },
  async removeAllDispatch(availabilityRuleId: string, date: string, scenarioId: string) {
    return ApiClient.post(`/dispatch/remove`, { availabilityRuleId, date, removeAll: true, scenarioId });
  },
  async removeDispatch(availabilityRuleId: string, date: string, taskIds: string[], scenarioId: string) {
    return ApiClient.post(`/dispatch/remove`, { availabilityRuleId, date, taskIds, scenarioId });
  },
  async getResourceProductionTasks(resourceId: string, scenarioId: string) {
    return ApiClient.get<{ tasks: TerminalTaskDetailsDto[] }>(`/TerminalTasks/details?relatedResourceId=${resourceId}&scenarioId=${scenarioId}`);
  }
};
