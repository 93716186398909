<script lang="ts" setup>
import { inject, ref, watch } from "vue";
import { useKeyPerformanceIndicatorsStore } from "@/store/KeyPerformanceIndicatorsStore";
import { CalculateKpiValuesCommand } from "@masta/generated-model";
import { useScenariosStore } from "@/store/ScenariosStore";
import DatepickerField from "@/components/Datepicker/DatepickerField.vue";
import { useI18n } from "vue-i18n";
import { $dateFormatterSymbol, DateFormatter } from "@masta/shared";
import { FACTORY_TIMEZONE, useSettingsStore } from "@/store/SettingsStore";

const $dateFormatter = inject<DateFormatter>($dateFormatterSymbol)!;

const { locale } = useI18n();

const props = defineProps<{
  modelValue: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "calculationParamsSet", command: CalculateKpiValuesCommand): void;
}>();

const profiles = ref<string[]>([]);
const selectedProfile = ref<string | null>(null);
const from = ref(new Date());
const to = ref(new Date());

watch(
  () => props.modelValue,
  (newVal) => {
    if (!newVal) {
      resetDialog();
    } else {
      fetchProfiles();
    }
  }
);

async function fetchProfiles() {
  const keyPerformanceIndicatorsStore = useKeyPerformanceIndicatorsStore();
  const profileNames = await keyPerformanceIndicatorsStore.fetchProfiles();
  profiles.value = profileNames;
}

function resetDialog() {
  selectedProfile.value = null;
  from.value = new Date();
  to.value = new Date();
}

function cancel() {
  emit("update:modelValue", false);
}

//cut timezone
const formatOutputDate = (date) => date.toISOString().slice(0, -1);

async function calculate() {
  if (selectedProfile.value) {
    const scenariosStore = useScenariosStore();
    const settingsStore = useSettingsStore();
    const factoryTimezone = (await settingsStore.getSettingByKey(FACTORY_TIMEZONE))?.value || "UTC";
    const command: CalculateKpiValuesCommand = {
      kpiProfileName: selectedProfile.value,
      timeZone: factoryTimezone,
      from: formatOutputDate(from.value),
      to: formatOutputDate(to.value),
      scenarioId: scenariosStore.selectedScenario?.id || ""
    };
    emit("calculationParamsSet", command);
  }
  emit("update:modelValue", false);
}

function formatDate(date: Date) {
  return $dateFormatter(date);
}
</script>

<template>
  <v-dialog :model-value="modelValue" width="auto" @update:model-value="(value) => emit('update:modelValue', value)">
    <v-card class="pa-8 calculate-card">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h4">{{ "Calculate KPI" }}</div>
        <v-icon @click="cancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <div class="text-subtitle-1">{{ "Profile:" }}</div>
        <v-chip-group v-model="selectedProfile" mandatory class="d-flex justify-center">
          <v-chip v-for="profile in profiles" :key="profile" :value="profile" :class="profile === selectedProfile ? 'bg-primary' : 'bg-indigo-lighten-5 text-primary'" size="large">
            {{ profile }}
          </v-chip>
        </v-chip-group>
        <v-container>
          <v-row>
            <v-col cols="6">
              <datepicker-field v-model="from" :clearable="false" :format="formatDate" :locale="locale" :enable-time-picker="false" :label="'KPI from'" :placeholder="'KPI from'" />
            </v-col>
            <v-col cols="6">
              <datepicker-field v-model="to" :clearable="false" :format="formatDate" :locale="locale" :enable-time-picker="false" :label="'KPI to'" :placeholder="'KPI to'" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="secondary" block @click="cancel">
              {{ "Cancel" }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" :color="selectedProfile ? 'primary' : 'grey'" block :disabled="!selectedProfile" @click="calculate">
              {{ "Calculate" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.calculate-card {
  overflow: initial !important;
  z-index: initial !important;
}
</style>
