<script lang="ts" setup>
import CloseCardButton from "@/components/CloseCardButton.vue";
import CostCatalogueGrid from "@/components/CostCatalogue/CostCatalogueGrid.vue";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { $t } from "@/i18n";
import { CostCatalogueItemDto } from "@masta/generated-model";
import { IRowNode, SelectionChangedEvent } from "ag-grid-community";
import { ref } from "vue";
import { VBtn } from "vuetify/lib/components/index.mjs";

const modelValue = defineModel<CostCatalogueItemDto[] | null | undefined>("selectedCostCatalogues", { required: true, default: [] });
const dialog = defineModel<boolean>("dialog", { required: true, default: false });
const props = defineProps<{

    /**
     * The order IDs that should be preselected in the picker.
     */
    preselectedCostCatalogueIds?: string[];
}>();

const selectedCostCatalogues = ref<CostCatalogueItemDto[] | null | undefined>([]);

function onBtnAdd() {
    if (selectedCostCatalogues.value) {
        modelValue.value = selectedCostCatalogues.value;
    }

    dialog.value = false;
}

function onBtnCancel() {
    dialog.value = false;
}

function onSelectionChanged({ api }: SelectionChangedEvent<CostCatalogueItemDto>) {
    const selectedRows = getSelectedRows(api);
    selectedCostCatalogues.value = selectedRows;
}

function isRowSelectable(row: IRowNode) {
    return !!row.data && !props.preselectedCostCatalogueIds?.includes(row.data.id);
}
</script>

<template>
    <v-dialog v-model="dialog" width="100%" height="90vh" @click:outside="onBtnCancel">
        <v-card min-width="300px" height="100%" class="reference-picker-card">
            <close-card-button @click="onBtnCancel"></close-card-button>
            <v-card-title class="pa-0">
                <div class="text-h4 pb-5">{{ $t("costCataloguesPicker-list-addCostCatalogues-label", { $: "Add cost catalogues" }) }}</div>
            </v-card-title>
            <v-card-text class="px-0">
                <cost-catalogue-grid 
                    :is-row-selectable="isRowSelectable"
                    @selection-changed="onSelectionChanged"
                />
            </v-card-text>
            <v-card-actions class="pa-0">
                <div class="left-col pl-0">
                    <v-btn variant="elevated" color="secondary" class="w-100" @click="onBtnCancel">
                        {{ $t("costCataloguesPicker-list-cancel-label", { $: "Cancel" }) }}
                    </v-btn>
                </div>
                <div class="middle-col"></div>
                <div class="right-col pr-0">
                    <v-btn variant="elevated" color="primary" class="w-100" @click="onBtnAdd">
                        {{ $t("costCataloguesPicker-list-add-label", { $: "Add" }) }}
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped></style>