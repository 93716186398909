import { ApiClient } from "@/services/api/common.api";
import {
  AssignModelInstanceToTaskCommand,
  DeassignModelInstanceFromTaskCommand,
  GenerateAndCreateSerialNumbersForTopLevelTaskCommand,
  UpdateTaskModelInstanceAssignmentCommand
} from "@masta/generated-model";

export default {
  async updateTaskModelInstanceAssignment(request: UpdateTaskModelInstanceAssignmentCommand) {
    return ApiClient.post(`/tasks/updateModelAssignment`, request);
  },
  async assignModelInstance(request: AssignModelInstanceToTaskCommand) {
    return ApiClient.post(`/tasks/assignModelInstance`, request);
  },
  async deassignModelInstance(request: DeassignModelInstanceFromTaskCommand) {
    return ApiClient.post(`/tasks/deassignModelInstance`, request);
  },
  async generateSerialNumbers(request: GenerateAndCreateSerialNumbersForTopLevelTaskCommand) {
    return ApiClient.post(`/tasks/generateSerialNumbers`, request);
  }
};

