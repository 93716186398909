<template>
  <master-detail-layout scenario-selector>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("services-view-services-title", { $: "Services" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <services-grid @manage-model-instances="manageModelInstances" @manage-availability-rules="manageAvailabilityRules" />
        </v-card-text>
      </v-card>
      <v-col v-if="editAvailabilityRulesAssignmentDialog">
        <availability-rules-assignment-editor
          :resource="service"
          :opened="editAvailabilityRulesAssignmentDialog"
          @cancel="editAvailabilityRulesAssignmentDialog = false"
          @save="editAvailabilityRulesAssignmentDialog = false"
        />
      </v-col>
    </template>
  </master-detail-layout>
</template>

<script>
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout";
import ServicesGrid from "@/components/Services/ServicesGrid";

export default {
  name: "Services",
  components: {
    Breadcrumbs,
    AvailabilityRulesAssignmentEditor,
    MasterDetailLayout,
    ServicesGrid,
  },
  title: "Services",
  data() {
    return {
      editModelInstanceAssignmentDialog: false,
      editAvailabilityRulesAssignmentDialog: false,
      service: null,
      refreshGridAction: null
    };
  },
  methods: {
    manageAvailabilityRules(service) {
      this.service = service;
      this.editAvailabilityRulesAssignmentDialog = true;
    }
  }
};
</script>

<style scoped></style>
