<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { inject, ref } from "vue";
import { $t } from "@/i18n";
import { CellClassParams, ValueFormatterParams } from "ag-grid-community";
import { translateMeasurementUnit } from "@/composables/translateEnum";
import ApiService from "@/services/api";
import { useErrorsStore } from "@/store/ErrorsStore";
import { GetPlannedCapacityUtilizationQuery, ResourceUtilizationPeriodItemDto } from "@masta/generated-model";
import PlannedCapacityUtilizationCalculateDialog from "@/components/PlannedCapacityUtilization/PlannedCapacityUtilizationCalculateDialog.vue";
import { normalizedPercentValueCellStyle } from "@/components/PlannedCapacityUtilization/PercentResultGridCellStyle";
import { $durationFormatterSymbol, DateFormatter } from "@masta/shared";

const gridWrapperRef = ref<GridWrapperComponent>();
const errorsStore = useErrorsStore();
const data = ref<ResourceUtilizationPeriodItemDto[]>([]);
const calculateDialog = ref(false);
const query = ref<GetPlannedCapacityUtilizationQuery>();
const $durationFormatter = inject<DateFormatter>($durationFormatterSymbol)!;

async function onRefreshAction() {
  await onFetchData();
}

async function onFetchData() {
  await fetchData();
}

async function fetchData() {
  if (!query.value) {
    return;
  }
  try {
    const response = await ApiService.planning.getPlannedCapacityUtilization(query.value);
    data.value = response.data?.items ?? [];
  } catch (e) {
    errorsStore.handleError(e);
    throw e;
  }
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "resourceId",
      headerName: "Resource Id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("plannedCapacityUtilization-list-resourceId-label", { $: "ResourceId" })
    },
    {
      field: "resourceBusinessId",
      headerName: "Resource Business Id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("plannedCapacityUtilization-list-resourceBusinessId-label", { $: "Resource Business Id" })
    },
    {
      field: "resourceColor",
      headerName: "Resource Color",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["colorTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("plannedCapacityUtilization-list-resourceColor-label", { $: "Resource Color" }),
      floatingFilterComponentParams: {
        placeholder: $t("plannedCapacityUtilization-list-color-label", { $: "Color" })
      }
    },
    {
      field: "periodName",
      headerName: "PeriodName",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("plannedCapacityUtilization-list-period-label", { $: "Period" })
    },
    {
      field: "supply",
      headerName: "Supply",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? params.value.toFixed(1) : null;
      },
      headerValueGetter: (_: any) => $t("plannedCapacityUtilization-list-supply-label", { $: "Supply" })
    },
    {
      field: "demand",
      headerName: "Demand",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? params.value.toFixed(1) : null;
      },
      headerValueGetter: (_: any) => $t("plannedCapacityUtilization-list-demand-label", { $: "Demand" })
    },
    {
      field: "utilization",
      headerName: "Utilization",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellStyle: (params: CellClassParams) => normalizedPercentValueCellStyle(params),
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? params.value.toFixed(1) : null;
      },
      headerValueGetter: (_: any) => $t("plannedCapacityUtilization-list-utilization-label", { $: "Utilization" })
    },
    {
      field: "unit",
      type: "enumTypeColumn",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("plannedCapacityUtilization-list-unit-label", { $: "Unit" }),
      valueFormatter: (params: ValueFormatterParams) => translateMeasurementUnit(params.value)
    }
  ];
}

const defaultColDef = ref({
  // allow every column to be aggregated
  enableValue: true,
  // allow every column to be grouped
  enableRowGroup: true,
  // allow every column to be pivoted
  enablePivot: true,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

function triggerCalculateAction() {
  calculateDialog.value = true;
}

async function calculationParamsSet(queryFromDialog: GetPlannedCapacityUtilizationQuery) {
  calculateDialog.value = false;
  query.value = queryFromDialog;
  await fetchData();
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="planned-capacity-utilization"
    :enable-charts="true"
    refresh-btn
    enable-range-selection
    enable-columns-tool-panel
    enable-filters-tool-panel
    row-selection="multiple"
    :grid-data="data"
    group-display-type="multipleColumns"
    :pivot-mode="false"
    :default-col-def="defaultColDef"
    :hide-custom-actions-separator="true"
    :checkbox-selection="false"
    :enable-actions-column="false"
    :pivot-default-expanded="-1"
    :group-default-expanded="-1"
    @fetch-data="onFetchData"
    @refresh-action="onRefreshAction"
    @prepare-columns="onPrepareColumns"
  >
    <template #custom-buttons>
      <v-tooltip location="bottom" open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" @click="triggerCalculateAction">
              <v-icon icon="mdi-calculator-variant-outline" class="pr-4" />
              {{ "Calculate" }}
            </v-btn>
            <planned-capacity-utilization-calculate-dialog v-model="calculateDialog" @calculation-params-set="calculationParamsSet" />
          </div>
        </template>
        <span>{{ "Calculate" }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
</template>
