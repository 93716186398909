import ApiService from "@/services/api";
import { useErrorsStore } from "@/store/ErrorsStore";
import { AssignModelInstanceToOrderCommand, DeassignModelInstanceFromOrderCommand } from "@masta/generated-model";
import { defineStore } from "pinia";

interface State {}

export const useOrdersStore = defineStore("orders", {
  state: (): State => ({}),
  actions: {
    async assignModelInstance(request: AssignModelInstanceToOrderCommand) {
      try {
        await ApiService.orders.assignModelInstance(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async deassignModelInstance(request: DeassignModelInstanceFromOrderCommand) {
      try {
        await ApiService.orders.deassignModelInstance(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});

