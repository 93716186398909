<script lang="ts" setup>
import { taskGroupValueCellStyle } from "@/components/Grid/CellRenderers/GroupValueCellStyle";
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { groupEnumValueRowKeyCreator } from "@/components/Grid/Filters/ColumnFilters";
import { dateColumnFilterParams } from "@/components/Grid/Filters/DateTypeColumnFilters";
import { tagsTypeColumnFilterParams } from "@/components/Grid/Filters/TagsTypeColumnFilters";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import TasksPickerDialog from "@/components/Tasks/TasksPickerDialog.vue";
import { getStatusBasedCellStyle } from "@/components/Tasks/TaskUtils";
import { translatePlanningStatus, translateTaskSubType, translateTotalStatus } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { $appContextSymbol } from "@/plugins/app.plugin";
import { composeTreeHierarchy } from "@/store/common";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useTagsStore } from "@/store/TagsStore";
import { ModelInstanceAssignmentsDto, PlanningStatus, TaskDto, TaskSubType, TotalStatus } from "@masta/generated-model";
import { $dateSymbol, $dateTimeFormatterSymbol, $durationFormatterSymbol, DateFormatter } from "@masta/shared";
import { SelectionChangedEvent, ValueFormatterParams } from "ag-grid-community";
import dayjs from "dayjs";
import { inject, ref, watch } from "vue";

const modelInstanceId = defineModel<string>({
  required: true
});
const assignments = ref<ModelInstanceAssignmentsDto | null>(null);
const modelInstanceStore = useModelInstancesStore();
const snackbarsStore = useSnackbarsStore();
const tasksStore = useProductionTasksStore();
const tagsStore = useTagsStore();
const selectedRow = ref<TaskDto | null>(null);

const gridWrapperRef = ref<GridWrapperComponent>();

const modelInstanceTasksAssignDialog = ref(false);

const selectedTasksToAssign = ref<TaskDto[]>();

const $date = inject<typeof dayjs>($dateSymbol)!;
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const $durationFormatter = inject<DateFormatter>($durationFormatterSymbol)!;
const $appContext = inject<any>($appContextSymbol)!;

watch(modelInstanceId, async (id) => {
  if (id) {
    await fetchModelInstanceAssignments();
  }
}, { immediate: true });

watch(selectedTasksToAssign, async (tasks) => {
  if (tasks) {
    for (const task of tasks) {
      await tasksStore.assignModelInstance({
        taskId: task.id,
        modelInstanceId: modelInstanceId.value,
        scenarioId: task.scenarioId
      } as any);

      snackbarsStore.createSnackbar({
        message: $t("modelInstanceAssignments-snackbar-task-assigned", { $: "Task {task} assigned", task: task.businessId }),
        closeable: true,
        timeout: 2500
      });
    }
    await fetchModelInstanceAssignments();
  }
});

async function fetchModelInstanceAssignments() {
  selectedRow.value = null;
  assignments.value = await modelInstanceStore.fetchModelInstanceAssignments(modelInstanceId.value);
  if(assignments.value) {
    assignments.value.tasks = composeTreeHierarchy(assignments.value.tasks, "parentTaskId");
  }
}

function assignTasks() {
  modelInstanceTasksAssignDialog.value = true;
}

async function unassignTasks() {
  try {
    if (gridWrapperRef.value == null) return;

    const selectedRows = gridWrapperRef.value.gridApi.getSelectedRows();
    if (selectedRows.length === 0) return;

    for (const row of selectedRows) {
      const selectedTask = row as TaskDto;

      await tasksStore.deassignModelInstance({
        taskId: row.id,
        modelInstanceId: modelInstanceId.value,
        scenarioId: selectedTask.scenarioId
      } as any);

      snackbarsStore.createSnackbar({
        message: $t("modelInstanceAssignments-snackbar-task-unassigned", { $: "Task {task} unassigned", task: selectedTask.businessId }),
        closeable: true,
        timeout: 2500
      });
    }

    await fetchModelInstanceAssignments();
  } catch (error) {
    const errorsStore = useErrorsStore();
    errorsStore.handleError(error);
  }
}

function onSelectionChanged(e: SelectionChangedEvent) {
  if (e.source === "rowDataChanged") return;
  const selectedRows = e.api.getSelectedRows();
  selectedRow.value = selectedRows.length > 0 ? selectedRows[0] : null;
}

const getDataPath = ref((data: any) => {
  return data.hierarchy;
});

const defaultColumnDef = ref({
  sortable: true,
  resizable: true,
  floatingFilter: true,
  type: ["textFloatingFilterColumnType"],
  filter: "agTextColumnFilter",
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

const autoGroupColumnDef = ref({
  field: "businessId",
  cellStyle: taskGroupValueCellStyle,
  headerValueGetter: (_: any) => $t("task-list-businessId-label", { $: "Business ID" }),
  minWidth: 330,
  floatingFilterComponentParams: {
    placeholder: $t("task-list-businessId-label", { $: "Business ID" })
  }
});

/**
 * 
 * @param columnDefs copied from TasksGrid.vue. Maybe it would be good to refactor this into a shared function.
 */
function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "name",
      headerValueGetter: (_: any) => $t("task-list-name-label", { $: "Name" }),
      cellStyle: taskGroupValueCellStyle,
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      floatingFilterComponentParams: {
        placeholder: $t("task-list-name-label", { $: "Name" })
      }
    },
    {
      field: "resourceBusinessId",
      headerValueGetter: (_: any) => $t("task-list-resourceBusinessId-label", { $: "Resource Business ID" }),
      cellStyle: taskGroupValueCellStyle,
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      floatingFilterComponentParams: {
        placeholder: $t("task-list-resourceBusinessId-label", { $: "Resource Business ID" })
      }
    },
    {
      field: "wbs",
      headerValueGetter: (_: any) => $t("task-list-workBreakdownStructure-label", { $: "Work Breakdown Structure" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      floatingFilterComponentParams: {
        placeholder: $t("task-list-workBreakdownStructure-label", { $: "Work Breakdown Structure" })
      }
    },
    {
      field: "taskSubType",
      headerValueGetter: (_: any) => $t("task-list-taskSubType-label", { $: "Task Sub-Type" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      filterParams: {
        keyCreator: groupEnumValueRowKeyCreator,
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        values: translateEditorEntries(enumToEditorEntries(TaskSubType), translateTaskSubType),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => translateTaskSubType(params.data?.taskSubType),
      floatingFilterComponentParams: {
        placeholder: $t("task-list-taskSubType-label", { $: "Task Sub-Type" }),
        values: translateEditorEntries(enumToEditorEntries(TaskSubType), translateTaskSubType)
      }
    },
    {
      field: "priority",
      type: ["numberInputTypeColumn", "numberFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-priority-label", { $: "Priority" }),
      editable: false,
      sortable: true,
      filter: "agNumberColumnFilter"
    },
    {
      field: "dateTimeFrom",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-from-label", { $: "From" }),
      editable: false,
      sortable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data?.dateTimeFrom);
      }
    },
    {
      field: "dateTimeTo",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-to-label", { $: "To" }),
      editable: false,
      sortable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data?.dateTimeTo);
      }
    },
    {
      field: "statistics.totalStatus",
      headerValueGetter: (_: any) => $t("task-list-totalStatus-label", { $: "Total Status" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      filterParams: {
        keyCreator: groupEnumValueRowKeyCreator,
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        values: translateEditorEntries(enumToEditorEntries(TotalStatus), translateTotalStatus),
        comparator: enumValueEntryWithLocaleComparator
      },
      cellStyle: (params: any) => getStatusBasedCellStyle($appContext, params.value),
      valueFormatter: (params: any) => {
        return translateTotalStatus(params.data?.statistics?.totalStatus);
      },
      floatingFilterComponentParams: {
        placeholder: $t("task-list-totalStatus-label", { $: "Total Status" }),
        values: translateEditorEntries(enumToEditorEntries(TotalStatus), translateTotalStatus)
      }
    },
    {
      field: "statistics.schedulingStatus",
      headerValueGetter: (_: any) => $t("task-list-schedulingStatus-label", { $: "Scheduling Status" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      filterParams: {
        keyCreator: groupEnumValueRowKeyCreator,
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        values: translateEditorEntries(enumToEditorEntries(TotalStatus), translateTotalStatus),
        comparator: enumValueEntryWithLocaleComparator
      },
      cellStyle: (params: any) => getStatusBasedCellStyle($appContext, params.value),
      valueFormatter: (params: any) => {
        return translateTotalStatus(params.data?.statistics?.schedulingStatus);
      },
      floatingFilterComponentParams: {
        placeholder: $t("task-list-schedulingStatus-label", { $: "Scheduling Status" }),
        values: translateEditorEntries(enumToEditorEntries(TotalStatus), translateTotalStatus)
      }
    },
    {
      field: "statistics.executionStatus",
      headerValueGetter: (_: any) => $t("task-list-executionStatus-label", { $: "Execution Status" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        keyCreator: groupEnumValueRowKeyCreator,
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        values: translateEditorEntries(enumToEditorEntries(TotalStatus), translateTotalStatus),
        comparator: enumValueEntryWithLocaleComparator
      },
      cellStyle: (params: any) => getStatusBasedCellStyle($appContext, params.value),
      valueFormatter: (params: any) => {
        return translateTotalStatus(params.data?.statistics?.executionStatus);
      }
    },
    {
      field: "planningStatus",
      headerValueGetter: (_: any) => $t("task-list-planningStatus-label", { $: "Planning Status" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        keyCreator: groupEnumValueRowKeyCreator,
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        values: translateEditorEntries(enumToEditorEntries(PlanningStatus), translatePlanningStatus),
        comparator: enumValueEntryWithLocaleComparator
      },
      cellStyle: (params: any) => getStatusBasedCellStyle($appContext, params.value),
      valueFormatter: (params: any) => {
        return translatePlanningStatus(params.data?.planningStatus);
      }
    },
    {
      field: "statistics.schedulingStart",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-schedulingStart-label", { $: "Scheduling Start" }),
      editable: false,
      sortable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data?.statistics?.schedulingStart);
      }
    },
    {
      field: "statistics.schedulingEnd",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-schedulingEnd-label", { $: "Scheduling End" }),
      editable: false,
      sortable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data?.statistics?.schedulingEnd);
      }
    },
    {
      field: "statistics.schedulingDuration",
      headerValueGetter: (_: any) => $t("task-list-schedulingDuration-label", { $: "Scheduling Duration" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.schedulingDuration);
      }
    },
    {
      field: "statistics.schedulingLeadTime",
      headerValueGetter: (_: any) => $t("task-list-schedulingLeadTime-label", { $: "Scheduling Lead Time" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.schedulingLeadTime);
      }
    },
    {
      field: "statistics.schedulingDelay",
      headerValueGetter: (_: any) => $t("task-list-schedulingDelay-label", { $: "Scheduling Delay" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.schedulingDelay);
      }
    },
    {
      field: "statistics.schedulingWaitingTime",
      headerValueGetter: (_: any) => $t("task-list-schedulingWaitingTime-label", { $: "Scheduling Waiting Time" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.schedulingWaitingTime);
      }
    },
    {
      field: "statistics.executionStart",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-executionStart-label", { $: "Execution Start" }),
      editable: false,
      sortable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data?.statistics?.executionStart);
      }
    },
    {
      field: "statistics.executionEnd",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-executionEnd-label", { $: "Execution End" }),
      editable: false,
      sortable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data?.statistics?.executionEnd);
      }
    },
    {
      field: "statistics.executionDuration",
      headerValueGetter: (_: any) => $t("task-list-executionDuration-label", { $: "Execution Duration" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.executionDuration);
      }
    },
    {
      field: "statistics.executionLeadTime",
      headerValueGetter: (_: any) => $t("task-list-executionLeadTime-label", { $: "Execution Lead Time" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.executionLeadTime);
      }
    },
    {
      field: "statistics.executionWaitingTime",
      headerValueGetter: (_: any) => $t("task-list-executionWaitingTime-label", { $: "Execution Waiting Time" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.executionWaitingTime);
      }
    },
    {
      field: "tags",
      type: ["tagsPickerTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-tags-label", { $: "Tags" }),
      editable: false,
      resizable: true,
      filter: "agSetColumnFilter",
      filterParams: tagsTypeColumnFilterParams(tagsStore),
      valueFormatter: (params: ValueFormatterParams) => (params.data?.tags ?? []).join(", ")
    },
    {
      field: "createdBy",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("task-list-createdBy-label", { $: "Created By" })
    },
    {
      field: "createdAt",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType", "dateTimeTypeColumn"],
      editable: false,
      resizable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("task-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("task-list-modifiedBy-label", { $: "Modified By" })
    },
    {
      field: "modifiedAt",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType", "dateTimeTypeColumn"],
      editable: false,
      resizable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("task-list-modifiedAt-label", { $: "Modified At" })
    }
  ];
}
</script>

<template>
  <grid-wrapper 
    ref="gridWrapperRef" 
    identifier="modelInstanceTasks"
    row-selection="multiple"
    hide-custom-actions-separator
    :grid-data="assignments?.tasks"
    :default-col-def="defaultColumnDef"
    :auto-group-column-def="autoGroupColumnDef"
    :tree-data="true"
    :get-data-path="getDataPath"
    @selection-changed="onSelectionChanged" 
    @prepare-columns="onPrepareColumns">
    <template #custom-buttons>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" @click="assignTasks">
              <v-icon icon="mdi-playlist-plus" class="pr-4"></v-icon>
              {{ $t("modelInstanceTasks-list-assign-action", { $: "Assign" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstanceTasks-list-assign-tooltip", { $: "Assign" }) }}</span>
      </v-tooltip>

      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" :disabled="!selectedRow" @click="unassignTasks">
              <v-icon icon="mdi-playlist-minus" class="pr-4"></v-icon>
              {{ $t("modelInstanceTasks-list-unassign-action", { $: "Unassign" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstanceTasks-list-unassign-tooltip", { $: "Unassign" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>

  <tasks-picker-dialog
    v-model:selected-tasks="selectedTasksToAssign"
    v-model:dialog="modelInstanceTasksAssignDialog"
    :preselected-task-ids="assignments?.tasks.map((task) => (task.id))">
  </tasks-picker-dialog>
</template>

<style scoped lang="scss">
</style>
