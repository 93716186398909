<script lang="ts" setup>
import { getOrderReleaseStatusCellStyle } from "@/components/CustomerOrders/CustomerOrderUtils";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import OrdersPickerDialog from "@/components/Orders/OrdersPickerDialog.vue";
import { translateOrderReleaseStatus } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useOrdersStore } from "@/store/OrdersStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { ModelInstanceAssignmentsDto, OrderDto, OrganizationDto } from "@masta/generated-model";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { SelectionChangedEvent, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { inject, ref, watch } from "vue";

const modelInstanceId = defineModel<string>({
  required: true
});
const assignments = ref<ModelInstanceAssignmentsDto | null>(null);
const modelInstanceStore = useModelInstancesStore();
const snackbarsStore = useSnackbarsStore();
const ordersStore = useOrdersStore();

const selectedRow = ref<OrderDto | null>(null);

const gridWrapperRef = ref<GridWrapperComponent>();

const modelInstanceOrdersAssignDialog = ref(false);

const selectedOrdersToAssign = ref<OrderDto[]>();

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

watch(modelInstanceId, async (id) => {
  if (id) {
    await fetchModelInstanceAssignments();
  }
}, { immediate: true });

watch(selectedOrdersToAssign, async (orders) => {
  if (orders) {
    for (const order of orders) {
      await ordersStore.assignModelInstance({
        orderId: order.id,
        modelInstanceId: modelInstanceId.value,
        scenarioId: order.scenarioId
      } as any);

      snackbarsStore.createSnackbar({
        message: $t("modelInstanceAssignments-snackbar-order-assigned", { $: "Order {order} assigned", order: order.businessId }),
        closeable: true,
        timeout: 2500
      });
    }

    await fetchModelInstanceAssignments();
  }
});

async function fetchModelInstanceAssignments() {
  selectedRow.value = null;
  assignments.value = await modelInstanceStore.fetchModelInstanceAssignments(modelInstanceId.value);
}

function assignOrders() {
  modelInstanceOrdersAssignDialog.value = true;
}

async function unassignOrders() {
  if (gridWrapperRef.value == null) return;

  const selectedRows = gridWrapperRef.value.gridApi.getSelectedRows();
  if (selectedRows.length === 0) return;

  for (const row of selectedRows) {
    const selectedOrder = row as OrderDto;

    await ordersStore.deassignModelInstance({
      orderId: row.id,
      modelInstanceId: modelInstanceId.value,
      scenarioId: selectedOrder.scenarioId
    } as any);

    snackbarsStore.createSnackbar({
      message: $t("modelInstanceAssignments-snackbar-order-unassigned", { $: "Order {order} unassigned", order: row.businessId }),
      closeable: true,
      timeout: 2500
    });
  }

  await fetchModelInstanceAssignments();
}

function onSelectionChanged(e: SelectionChangedEvent) {
  if (e.source === "rowDataChanged") return;
  const selectedRows = e.api.getSelectedRows();
  selectedRow.value = selectedRows.length > 0 ? selectedRows[0] : null;
}

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});


function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "description",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("customerOrder-list-description-label", { $: "Description" }),
      floatingFilterComponentParams: {
        laceholder: $t("customerOrder-list-description-label", { $: "Description" })
      }
    },
    {
      field: "businessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("customerOrder-list-businessId-label", { $: "Business ID" }),
      floatingFilterComponentParams: {
        placeholder: $t("customerOrder-list-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "issueDate",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agDateColumnFilter",
      type: "datepickerTypeColumn",
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.issueDate);
      },
      headerValueGetter: (_: any) => $t("customerOrder-list-issueDate-label", { $: "Issue Date" })
    },
    {
      field: "customerName",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      type: ["organizationPickerTypeColumn", "textFloatingFilterColumnType"],
      valueGetter: (params: ValueGetterParams<OrderDto, OrganizationDto>) => {
        const { data } = params;
        if (!data || !data.customerName) return null;

        return {
          businessId: data.customerName,
          name: data.customerName
        } as OrganizationDto;
      },
      valueSetter: (params: ValueSetterParams<OrderDto, OrganizationDto>) => {
        params.data.customerName = params.newValue?.businessId ?? "";
        return true;
      },
      valueFormatter: (params: ValueFormatterParams) => params.data?.customerName,
      headerValueGetter: (_: any) => $t("customerOrder-list-customerName-label", { $: "Customer" })
    },
    {
      field: "releaseStatus",
      resizable: true,
      cellStyle: (params: any) => getOrderReleaseStatusCellStyle(params.data.releaseStatus),
      valueGetter: (params: any) => translateOrderReleaseStatus(params.data.releaseStatus),
      headerValueGetter: (_: any) => $t("customerOrder-list-releaseStatus-label", { $: "Release Status" })
    },
    {
      field: "organizationId",
      hide: true,
      filter: "agTextColumnFilter",
      suppressColumnsToolPanel: true,
      headerValueGetter: (_: any) => $t("customerOrder-list-organizationId-label", { $: "Organization ID" })
    }
  ];
}
</script>

<template>
  <grid-wrapper 
    ref="gridWrapperRef" 
    identifier="modelInstanceOrders"
    row-selection="multiple"
    hide-custom-actions-separator
    :grid-data="assignments?.orders"
    :default-col-def="defaultColumnDef"
    @selection-changed="onSelectionChanged" 
    @prepare-columns="onPrepareColumns">
    <template #custom-buttons>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" @click="assignOrders">
              <v-icon icon="mdi-playlist-plus" class="pr-4"></v-icon>
              {{ $t("modelInstanceOrders-list-assign-action", { $: "Assign" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstanceOrders-list-assign-tooltip", { $: "Assign" }) }}</span>
      </v-tooltip>

      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" :disabled="!selectedRow" @click="unassignOrders">
              <v-icon icon="mdi-playlist-minus" class="pr-4"></v-icon>
              {{ $t("modelInstanceOrders-list-unassign-action", { $: "Unassign" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstanceOrders-list-unassign-tooltip", { $: "Unassign" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>

  <orders-picker-dialog
    v-model:selected-orders="selectedOrdersToAssign"
    v-model:dialog="modelInstanceOrdersAssignDialog"
    :preselected-order-ids="assignments?.orders.map((order) => (order.id))">
  </orders-picker-dialog>
</template>

<style scoped lang="scss">
</style>
