<script lang="ts" setup>
import OrdersGrid from "@/components/CustomerOrders/CustomerOrdersGrid.vue";
import { ref, watch } from "vue";
import OrderLinesGrid from "@/components/CustomerOrders/CustomerOrderLinesGrid.vue";
import { DocumentContextNameConsts, OrderDto, SettingDto } from "@masta/generated-model";
import { $t } from "@/i18n";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import QuotationDocumentsGrid from "@/components/QuotationDocuments/QuotationDocumentsGrid.vue";
import QuotationLinesGrid from "@/components/QuotationLines/QuotationLinesGrid.vue";
import OrderMediaGallery from "@/components/Media/OrderMediaGallery.vue";
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import { isDefined } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router";
import { nameOrBusinessIdOrIdOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import ApiService from "@/services/api";
import { useScenariosStore } from "@/store/ScenariosStore";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/store/SettingsStore";
import CostControllingDocumentsGrid from "@/components/CostControllingDocuments/CostControllingDocumentsGrid.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";

const APP_CLIENT_SETTINGS_SHOW_DETAILED_ORDER_COSTS_GRID = "app.client.settings.showDetailedOrderCostsGrid";
const settingsStore = useSettingsStore();
const showDetailedCostsGrid = ref<boolean>(false);

async function initializeShowDetailedCostsGrid(): Promise<boolean> {
  await settingsStore.fetchIfEmpty();
  const settingEntry = settingsStore.settings?.find((entry: SettingDto) => entry.key === APP_CLIENT_SETTINGS_SHOW_DETAILED_ORDER_COSTS_GRID);
  const value = settingEntry?.value as boolean;
  return value ?? false;
}

initializeShowDetailedCostsGrid().then((showStuff) => {
  showDetailedCostsGrid.value = showStuff;
});

const ordersGridRef = ref<typeof OrdersGrid | null>(null);
const orderLinesGridRef = ref<typeof OrderLinesGrid | null>(null);

interface OrdersViewProps {
  embedded?: boolean;
  readonly?: boolean;
  filterByProductId?: string | null | undefined;
}

const router = useRouter();
const route = useRoute();
const scenariosStore = useScenariosStore();
const { selectedScenario } = storeToRefs(scenariosStore);
const selectedOrderId = ref<string>("");
defineProps<OrdersViewProps>();

const selectedOrder = ref<OrderDto | null | undefined>(null);

watch(
  () => route.query.orderId,
  async (orderId, _) => {
    if (isDefined(orderId)) {
      if (isDefined(selectedOrder.value) && selectedOrder.value?.id === orderId) {
        return;
      } else {
        const order = await fetchOrder(orderId as string);
        selectedOrder.value = order;

        showDetails();
      }
    } else {
      selectedOrder.value = null;
    }
  },
  { immediate: true }
);

async function fetchOrder(orderId: string): Promise<OrderDto | null | undefined> {
  try {
    if (!selectedScenario.value) {
      return null;
    }

    const reponse = await ApiService.customerOrders.getSingle(orderId, selectedScenario.value.id);
    return reponse.data;
  } catch (error) {
    console.error("Fetch order error", error);
  }

  return null;
}

async function onOrderSelected(order: OrderDto) {
  if (isDefined(order)) {
    selectedOrder.value = order;

    showDetails();
  
    const currentQuery = router.currentRoute.value.query;
    const newQuery = Object.assign({}, currentQuery, { orderId: order.id });
    await router.push({ query: newQuery });
  }
}

const selectedTab = ref("orderLines");

const quotationDocumentsGridRef = ref();
const costControllingDocumentsGridRef = ref();

const splitLayoutRef = ref<typeof SplitLayout | null>(null);

function showDetails() {
  splitLayoutRef.value?.showDetails();
}

function closeDetails() {
  splitLayoutRef.value?.closeDetails();
}

function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-orders");
  return gutter;
}
</script>

<template>
  <split-layout ref="splitLayoutRef" identifier="orders" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="d-flex flex-column fill-height bg-material-design-grey-lighten-5">
        <v-card-title v-if="!embedded">
          <breadcrumbs>{{ $t("customerOrder-view-order-title", { $: "Orders" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height" :class="embedded ? 'pa-0' : ''">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <orders-grid
                ref="ordersGridRef"
                :filter-by-product-id="filterByProductId"
                :readonly="readonly"
                :no-actions-row="embedded && readonly"
                @row-selected="onOrderSelected"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template #detail>
      <v-card v-if="selectedOrder" elevation="7" class="d-flex flex-column fill-height orderLines-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-title class="pt-2 pb-0 tabs">
          <breadcrumbs> {{ nameOrBusinessIdOrIdOrNull(selectedOrder) }} {{ $t("customerOrder-view-lines-title", { $: "Order lines" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-subtitle>
          <v-tabs v-model="selectedTab" class="text-disabled" selected-class="v-slide-group-item--active text-primary bg-white" density="compact">
            <v-tab class="mr-1" value="orderLines">
              <v-icon class="pr-2">mdi-sitemap</v-icon>
              {{ $t("customerOrder-view-orderLines-label", { $: "Order lines" }) }}
            </v-tab>
            <v-tab class="mr-1" value="quotationDocuments">
              <v-icon class="pr-2">mdi-file-excel-outline</v-icon>
              {{ $t("customerOrder-view-quotationDocuments-label", { $: "Quotation/Offer Spreadsheets" }) }}
            </v-tab>
            <v-tab class="mr-1" value="costControllingDocuments">
              <v-icon class="pr-2">mdi-file-excel-outline</v-icon>
              {{ $t("customerOrder-view-costControllingDocuments-label", { $: "Cost Controlling Spreadsheets" }) }}
            </v-tab>
            <v-tab class="mr-1" value="attachments">
              <v-icon class="pr-2">mdi-paperclip</v-icon>
              {{ $t("customerOrder-view-attachments-label", { $: "Attachments" }) }}
            </v-tab>
            <v-tab v-if="showDetailedCostsGrid" class="mr-1" value="costs">
              <v-icon class="pr-2">mdi-currency-usd</v-icon>
              {{ $t("customerOrder-view-costs-label", { $: "Detailed Costs Calculations" }) }}
            </v-tab>
          </v-tabs>
        </v-card-subtitle>
        <v-card-text class="pt-2">
          <v-window v-model="selectedTab" class="fill-height">
            <v-window-item value="orderLines" class="fill-height">
              <order-lines-grid ref="orderLinesGridRef" :readonly="readonly" :selected-order="selectedOrder" />
            </v-window-item>
            <v-window-item value="quotationDocuments" class="fill-height">
              <quotation-documents-grid ref="quotationDocumentsGridRef" :readonly="readonly" :selected-order="selectedOrder" />
            </v-window-item>
            <v-window-item value="costControllingDocuments" class="fill-height pa-5">
              <cost-controlling-documents-grid ref="costControllingDocumentsGridRef" :readonly="readonly" :selected-order="selectedOrder" />
            </v-window-item>
            <v-window-item v-if="showDetailedCostsGrid" value="costs" class="fill-height pa-5">
              <quotation-lines-grid :readonly="readonly" :selected-order="selectedOrder" />
            </v-window-item>
            <v-window-item value="attachments" class="fill-height">
              <v-card class="overview-container flexcard" elevation="0">
                <v-card-text class="d-flex flex-column pa-0">
                  <div class="flex-1-1 d-flex overflow-hidden">
                    <order-media-gallery :context-name="DocumentContextNameConsts.orderDocument" :order-id="selectedOrder.id" :scenario-id="selectedOrder.scenarioId" />
                  </div>
                </v-card-text>
              </v-card>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
      <v-card v-else elevation="7" class="d-flex flex-column fill-height no-orderLines-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("customerOrder-view-noOrderSelected-label", { $: "No order selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<style lang="scss">
.orderLines-card {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 0;

  .tabs {
    .v-tab:not(.v-tab--selected) {
      border-bottom: 1px solid #e0e0e0;
    }
  }

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}

.no-orderLines-card {
  border: 2px solid rgb(var(--v-theme-primary), 0.0);
  border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}

.gutter-orders {
  cursor: col-resize;
}
</style>
