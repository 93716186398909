import { $t } from "@/i18n";
import { MenuItem } from "@/components/MegaMenu/MegaMenuModel";

// $t("menu-megaMenu-administration-label", {$: "Administration"})
// $t("menu-megaMenu-generalData-label", { $: "General Data" })
// $t("menu-megaMenu-masterData#generalData#aas#models-label", { $: "AAS Models" })
// $t("menu-megaMenu-masterData#generalData#model#repository-label", { $: "Model Repository" })
// $t("menu-megaMenu-masterData#generalData#availability#rules-label", { $: "Availability Rules" })
// $t("menu-megaMenu-masterData#generalData#fte#calendar-label", { $: "FTE Calendar" })
// $t("menu-megaMenu-masterData#generalData#tags-label", { $: "Tags" })
// $t("menu-megaMenu-masterData#resourcesManagement#agreements-label", { $: "Agreements" })
// $t("menu-megaMenu-masterData#resourcesManagement#assets-label", { $: "Assets" })
// $t("menu-megaMenu-masterData#resourcesManagement#equipment-label", { $: "Equipment" })
// $t("menu-megaMenu-masterData#resourcesManagement#locations-label", { $: "Locations" })
// $t("menu-megaMenu-masterData#resourcesManagement#materials-label", { $: "Materials" })
// $t("menu-megaMenu-masterData#resourcesManagement#personnel-label", { $: "Personnel" })
// $t("menu-megaMenu-masterData#resourcesManagement#services-label", { $: "Services" })
// $t("menu-megaMenu-masterData#sales#purchase#contacts-label", { $: "Contacts" })
// $t("menu-megaMenu-masterData#sales#purchase#cost#catalogue-label", {$: "Costs Catalogue"})
// $t("menu-megaMenu-masterData#sales#purchase#organizations-label", { $: "Organizations" })
// $t("menu-megaMenu-masterData#technicalData#product#card-label", { $: "Product Card" })
// $t("menu-megaMenu-masterData#technicalData#product#technologies-label", {$: "Product Technologies"})
// $t("menu-megaMenu-masterData#technicalData#technology#builder-label", {$: "Technology Builder"})
// $t("menu-megaMenu-masterData-label", { $: "Master Data" })
// $t("menu-megaMenu-operations#planning#scheduling#gantt#resources-label", { $: "Gantt - Resources" })
// $t("menu-megaMenu-operations#planning#scheduling#gantt#tasks-label", { $: "Gantt - Tasks" })
// $t("menu-megaMenu-operations#planning#scheduling#Scenario#KPI-label", {$: "Scenario KPI's"})
// $t("menu-megaMenu-operations#planning#scheduling#production#tasks-label", { $: "Production Tasks" })
// $t("menu-megaMenu-operations#planning#scheduling#rough#cut#capacity#planning-label", {$: "Rough Cut Capacity Planning"})
// $t("menu-megaMenu-operations#planning#scheduling#planned#capacity#utilization-label", {$: "Planned Capacity Utilization"})
// $t("menu-megaMenu-operations#planning#scheduling#materials#demand-label", {$: "Materials Demand"})
// $t("menu-megaMenu-operations#planning#scheduling#scenario#management-label", { $: "Scenario Management" })
// $t("menu-megaMenu-operations#productionExecution#inconsistencydetection-label", {$: "Inconsistency Detection"})
// $t("menu-megaMenu-operations#productionExecution#personnel#dispatch-label", { $: "Personnel Dispatch" })
// $t("menu-megaMenu-operations#productionExecution#workjournalrecords-label", {$: "Work Journal Records"})
// $t("menu-megaMenu-operations#supplyChain#communications-label", {$: "Communication Management"})
// $t("menu-megaMenu-operations#supplyChain#material#movement-label", {$: "Material Movements"})
// $t("menu-megaMenu-operations#supplyChain#orders-label", {$: "Quotations & Orders"})
// $t("menu-megaMenu-operations#supplyChain#purchase#orders-label", {$: "Purchase Orders"})
// $t("menu-megaMenu-operations#supplyChain#inbound#deliveries-label", {$: "Inbound Deliveries"})
// $t("menu-megaMenu-performance#dashboards#product#passport-label", { $: "Product Passport" })
// $t("menu-megaMenu-performance#dashboards#technological#card-label", { $: "Technological Card" })
// $t("menu-megaMenu-performance#dashboards#resource#tracker-label", { $: "Resource usage tracker" })
// $t("menu-megaMenu-performance#reports#electricity#consumption#per#equipment-label", { $: "Electr. Consumption" })
// $t("menu-megaMenu-performance#reports#oee#report-label", { $: "OEE Report" })
// $t("menu-megaMenu-performance#reports#ole#report-label", { $: "OLE Report" })
// $t("menu-megaMenu-performance#reports#reports#list-label", { $: "Reports List" })
// $t("menu-megaMenu-performance-label", {$: "Performance"})
// $t("menu-megaMenu-planning#scheduling-label", { $: "Planning & Scheduling" })
// $t("menu-megaMenu-productionExecution-label", {$: "Production Execution"})
// $t("menu-megaMenu-resourcesManagement-label", {$: "Resources Management"})
// $t("menu-megaMenu-sales#purchase-label", {$: "Sales & Purchasing"})
// $t("menu-megaMenu-supplyChain-label", {$: "Supply Chain"})
// $t("menu-megaMenu-system#administration#admin#panel-label", {$: "Admin Panel"})
// $t("menu-megaMenu-system#administration#business#actions-label", {$: "Business Actions"})
// $t("menu-megaMenu-system#administration#settings-label", {$: "Settings"})
// $t("menu-megaMenu-system#administration#tenants-label", {$: "Tenants"})
// $t("menu-megaMenu-system#userManagement#users-label", {$: "Users"})
// $t("menu-megaMenu-system-label", {$: "System"})
// $t("menu-megaMenu-technicalData-label", {$: "Technical Data"})
// $t("menu-megaMenu-userManagement-label", {$: "User Management"})

const convertIdToTranslationKey = (id: string) => id.replace(/ - /g, "#").replace(/ /g, "#").replace(/-/g, "#");

export function translateMegaMenuItem(item: MenuItem) {
  const translationId = convertIdToTranslationKey(item.id);
  // dynamic keys are ignored by source processing script (i18n-t8l.py), but can be listed in the browser: 'i18nT8l.createMissingKeysJsonTemplate()
  item.name = $t(`menu-megaMenu-${translationId}-label`, { $: item.name });
}

export function translateMegaMenuItemRecursively(item: MenuItem): void {
  translateMegaMenuItem(item);
  item.items.forEach(translateMegaMenuItemRecursively);
}
