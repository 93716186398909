<script setup lang="ts">
import { $t } from "@/i18n";
import { AssignModelInstanceToCostCatalogueItemCommand, DocumentDto } from "@masta/generated-model";
import { ref } from "vue";
import { useDocumentsStore } from "@/store/DocumentsStore";
import ApiService from "@/services/api";
import MediaGallery, { MediaGalleryComponent } from "@/components/Media/MediaGallery.vue";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";

const $props = defineProps<{
  costCatalogueItemId: string;
  contextName: string;
}>();

const mediaGalleryRef = ref<MediaGalleryComponent | null>(null);

const snackbarStore = useSnackbarsStore();
const documentsStore = useDocumentsStore();

async function documentsProvider(contextName: string): Promise<DocumentDto[]> {
  if (!$props.costCatalogueItemId) {
    throw new Error("Cost Catalogue Item id is required");
  }
  const { data } = await ApiService.documents.getDocumentsAssignedToCostCatalogueItemForContext(contextName, $props.costCatalogueItemId);
  return data.documentDtos;
}

async function onDelete(dto: DocumentDto) {
  if (!$props.costCatalogueItemId) {
    throw new Error("Cost Catalogue Item id is required");
  }
  try {
    await documentsStore.deassignCostCatalogueItemDocument(dto.id, $props.costCatalogueItemId);
    await mediaGalleryRef.value?.refreshItems();
  } catch (e: any) {
    snackbarStore.createSnackbar({
      message: e && e.detail ? e.detail : $t("resourceMediaGallery-deleteError-message", { $: "Could not delete" }),
      type: "error",
      closeable: true
    });
  }
}

async function onSelected(items: DocumentDto[]) {
  if (items.length > 0 && $props.costCatalogueItemId) {
    for (const item of items) {
      await ApiService.costCatalogue.assignModelInstance({
        costCatalogueItemId: $props.costCatalogueItemId,
        modelInstanceId: item.id,
        modelInstanceBusinessId: item.businessId,
        scenarioId: useScenariosStore().selectedScenario?.id
      } as AssignModelInstanceToCostCatalogueItemCommand);
    }
  }
  await mediaGalleryRef.value?.refreshItems();
}
</script>

<template>
  <media-gallery
    ref="mediaGalleryRef"
    multiple
    :context-name="contextName"
    :documents-provider="documentsProvider"
    :on-delete="onDelete"
    :qr-code-props="{ costCatalogueItemId }"
    @selected="onSelected"
  ></media-gallery>
</template>

<style scoped lang="scss"></style>
