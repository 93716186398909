<script lang="ts" setup>
import CloseCardButton from "@/components/CloseCardButton.vue";
import { ModelSchemaDto, ResourceInfoDto } from "@masta/generated-model";
import { $t } from "@/i18n";
import SchemasGrid from "@/components/ModelInstances/SchemasGrid.vue";
import { VBtn } from "vuetify/components";
import { getSchemaId } from "@/store/ModelInstancesStore";
import { useRouter } from "vue-router";
import { ref } from "vue";

const dialog = defineModel<boolean>("dialog", { required: true, default: false });
const router = useRouter();

const selectedSchema = ref<ModelSchemaDto | null>(null);

function resetSelectedSchema() {
  selectedSchema.value = null;
}

function onBtnCancel() {
  dialog.value = false;
  resetSelectedSchema();
}

function onDialogModelValueChanged(v: any) {
  if (!v) {
    resetSelectedSchema();
  }
}

function onBtnAdd() {
  if (selectedSchema.value) {
    dialog.value = false;
    openDialogForSchema(selectedSchema.value);
  }
}

function onSchemaSelected(schema: ModelSchemaDto) {
  selectedSchema.value = schema;
}

function openDialogForSchema(schema: ModelSchemaDto) {
  router.push({ name: "ModelRepository", query: { schemaId: getSchemaId(schema) } });
  resetSelectedSchema();
}
</script>

<template>
  <v-dialog v-model="dialog" width="100%" height="90vh" @click:outside="onBtnCancel" @update:model-value="onDialogModelValueChanged">
    <v-card min-width="300px" height="100%" class="reference-picker-card">
      <close-card-button @click="onBtnCancel"></close-card-button>
      <v-card-title class="pa-0">
        <div class="text-h4 pb-5">{{ $t("schemasPicker-dialog-title-label", { $: "Select model schema" }) }}</div>
      </v-card-title>
      <v-card-text class="px-0">
        {{ selectedSchema?.schemaName }}
        <schemas-grid @schema-selected="onSchemaSelected" />
      </v-card-text>
      <v-card-actions class="pa-0">
        <div class="left-col pl-0">
          <v-btn variant="elevated" color="secondary" class="w-100" @click="onBtnCancel">
            {{ $t("schemasPicker-dialog-cancel-label", { $: "Cancel" }) }}
          </v-btn>
        </div>
        <div class="right-col pr-0">
          <v-btn variant="elevated" color="primary" class="w-100" :disabled="!selectedSchema" @click="onBtnAdd">
            {{ $t("schemasPicker-dialog-add-label", { $: "Add" }) }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">

</style>
