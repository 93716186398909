<script lang="ts" setup>
import { getAlertType, ProductTemplateDraft, StepDraft, StepDraftResourceSpec } from "@/components/ProcessDrafts/ProcessDraftModels";
import StepDraftResourceSpecEditor from "@/components/ProcessDrafts/StepDraftResourceSpecEditor.vue";
import { MeasurementUnit, ResourceType, StepResourceAssignmentType, StepResourceUsageType, InventoryChangeType, StepType } from "@masta/generated-model";
import { computed, ref } from "vue";
import { nameRules, positiveQuantityRules, processingTimeRules } from "@/components/ProcessDrafts/validation";
import TreeViewNode from "@/components/ProcessDrafts/TreeView/TreeViewNode.vue";
import { $t } from "@/i18n";
import { getEnumTitleValuePairs } from "@/composables/enumHelpers";
import { translateMeasurementUnit, translateStepType, translateUsageType } from "@/composables/translateEnum";
import ApiService from "@/services/api";
import { filterValidationError, ProcessValidationError } from "@/components/ProcessDrafts/ProcessValidationError";
import { isDefined } from "@/components/Common/Types";
import SpecUsageTypeIcon from "@/components/ProcessDrafts/SpecUsageTypeIcon.vue";
import { useScenariosStore } from "@/store/ScenariosStore";

const modelValue = defineModel<StepDraft>({ required: true });
const props = defineProps<{
  productTemplate: ProductTemplateDraft;
  readonly: boolean;
  validationErrors: ProcessValidationError[];
}>();

const filteredValidationErrors = computed(() => {
  return props.validationErrors.filter((x) => filterValidationError(x, { productTemplate: props.productTemplate, step: modelValue.value }));
});

const stepResourceMeasUnit = computed(() => {
  if (isDefined(props.productTemplate?.resource)) {
    const measUnitValue = props.productTemplate.resource.measurementUnit;

    const measUnitValueTitle = measurementUnits.find((x) => x.value === measUnitValue)?.title ?? "";
    return measUnitValueTitle;
  }

  return null;
});

const isExpanded = ref<boolean>(true);
const isRequireScrollOnTransition = ref<boolean>(false);
const panel = ref<number[]>([0]);
const names = ref<string[]>([]);
const scenarioStore = useScenariosStore();

const lastRemovedSpec = ref<StepDraftResourceSpec | null>(null);

const emit = defineEmits<{
  (e: "remove", step: StepDraft): void;
  (e: "moveup", step: StepDraft): void;
  (e: "movedown", step: StepDraft): void;
}>();

const stepTypes = getEnumTitleValuePairs(StepType, translateStepType);
const measurementUnits = getEnumTitleValuePairs(MeasurementUnit, translateMeasurementUnit);

const groupedSpecs = computed(() => {
  const result: Record<StepResourceUsageType, StepDraftResourceSpec[]> = {
    [StepResourceUsageType.Book]: [],
    [StepResourceUsageType.Consume]: [],
    [StepResourceUsageType.Produce]: [],
    [StepResourceUsageType.Scrap]: [],
    [StepResourceUsageType.ByProduct]: []
  };

  for (const spec of modelValue.value.specs) {
    result[spec.usageType].push(spec);
  }

  return result;
});

// Own translations for ProcessDraft view

// $t("processDraft-view-usageType-Unknown-label", { $: "Unknown" })
// $t("processDraft-view-usageType-Book-label", { $: "Book" })
// $t("processDraft-view-usageType-Consume-label", { $: "Consume" })
// $t("processDraft-view-usageType-Produce-label", { $: "Produce" })
// $t("processDraft-view-usageType-Scrap-label", { $: "Scrap" })
// $t("processDraft-view-usageType-ByProduct-label", { $: "By-Product" })
const usageTypes = getEnumTitleValuePairs(StepResourceUsageType, (t: StepResourceUsageType) => {
  const key = StepResourceUsageType[t] ?? "Unknown";
  return $t(`processDraft-view-usageType-${key}-label`);
});

const groupedSpecTitles: Record<StepResourceUsageType, string> = {
  [StepResourceUsageType.Book]: $t("processDraft-view-groupSpec-book-label", { $: "This you will book" }),
  [StepResourceUsageType.Produce]: $t("processDraft-view-groupSpec-produce-label", { $: "This will be produced" }),
  [StepResourceUsageType.Consume]: $t("processDraft-view-groupSpec-consume-label", { $: "This will be consumed" }),
  [StepResourceUsageType.Scrap]: $t("processDraft-view-groupSpec-scarp-label", { $: "This will be a scrap" }),
  [StepResourceUsageType.ByProduct]: $t("processDraft-view-groupSpec-byProduct-label", { $: "This will be a by-product" })
};

function addResource(usageType: StepResourceUsageType) {
  isRequireScrollOnTransition.value = true;

  modelValue.value.specs.push({
    resources: [],
    resourceType: ResourceType.Material,
    usageType: usageType,
    quantity: 1,
    inventoryChangeThresholdQuantity: 0,
    assignmentType: StepResourceAssignmentType.Alternatives,
    inventoryChangeType: InventoryChangeType.Default
  });
  openPanel();
}

function openPanel() {
  panel.value = [0];
}

function remove() {
  emit("remove", modelValue.value);
}

function moveUp() {
  emit("moveup", modelValue.value);
}

function moveDown() {
  emit("movedown", modelValue.value);
}

function removeSpec(spec: StepDraftResourceSpec) {
  lastRemovedSpec.value = spec;
  modelValue.value.specs = modelValue.value.specs.filter((x) => x !== spec);
}

async function queryName(query: string) {
  if (!query || !scenarioStore.selectedScenario) {
    names.value = [];
    return;
  }
  const response = await ApiService.productTemplates.findStepName(scenarioStore.selectedScenario.id, query);
  names.value = response.data?.names ?? [];
}

async function handleInput(inputValue: any) {
  await queryName(inputValue.data);
}

function onSpecListTransitionAfterEnter(element: HTMLElement) {
  if (isRequireScrollOnTransition.value) {
    element.scrollIntoView({ behavior: "smooth", block: "center" });
    element.classList.add("highlight-spec");

    isRequireScrollOnTransition.value = false;
  }
}

function isProcessingQuantityVisible() {
  return modelValue.value.type === StepType.Production || modelValue.value.type === StepType.Receiving || modelValue.value.type === StepType.QualityCheck;
}
</script>

<template>
  <tree-view-node :readonly="props.readonly" class="step-editor" @delete="remove" @move-up="moveUp" @move-down="moveDown">
    <template #header>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-row no-gutters class="d-flex flex-wrap gap-10">
              <div class="d-flex mt-5 title">{{ $t("processDraft-view-step-label", { $: "Step" }) }}:</div>

              <div class="flex-fill min-width-50 max-width-100">
                <v-text-field
                  v-model="modelValue.position"
                  type="number"
                  readonly
                  variant="outlined"
                  density="compact"
                  hide-details="auto"
                  :label="$t('processDraft-view-stepPosition-label', { $: 'Position' })"
                />
              </div>

              <div class="flex-fill min-width-100 max-width-300">
                <v-select
                  v-model="modelValue.type"
                  :items="stepTypes"
                  variant="outlined"
                  density="compact"
                  hide-details="auto"
                  :label="$t('processDraft-view-stepType-label', { $: 'Type' })"
                  :rules="[(v) => v !== StepType.Undefined || $t('processDraft-validation-stepTypeRequired-message', { $: 'Step type should be defined' })]"
                  :disabled="props.readonly"
                />
              </div>

              <div class="flex-fill" :class="isProcessingQuantityVisible() ? 'min-width-100 max-width-400' : 'min-width-50 max-width-200'">
                <v-row no-gutters>
                  <v-col v-if="isProcessingQuantityVisible()" cols="6" class="step-performance-column">
                    <v-text-field
                      v-model="modelValue.quantityPerTime"
                      type="number"
                      variant="outlined"
                      density="compact"
                      hide-details="auto"
                      :hint="isProcessingQuantityVisible() ? $t('processDraft-view-stepPerformance-label', { $: 'Step performance' }) : undefined"
                      persistent-hint
                      :label="$t('processDraft-view-processingQuantity-label', { $: 'Quantity' })"
                      :rules="positiveQuantityRules"
                      :readonly="props.readonly"
                    >
                      <template #append>
                        <v-label class="align-start">{{ stepResourceMeasUnit }}</v-label>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col :cols="isProcessingQuantityVisible() ? 6 : 12" class="step-performance-column">
                    <v-text-field
                      v-model="modelValue.processingTime"
                      :readonly="props.readonly"
                      variant="outlined"
                      density="compact"
                      hide-details="auto"
                      :class="isProcessingQuantityVisible() ? 'ml-4' : ''"
                      :hint="!isProcessingQuantityVisible() ? $t('processDraft-view-stepPerformance-label', { $: 'Step performance' }) : undefined"
                      persistent-hint
                      :label="$t('processDraft-view-stepProcessingTime-label', { $: 'Time' })"
                      :rules="processingTimeRules"
                    />
                  </v-col>
                </v-row>
              </div>

              <div class="flex-fill d-flex justify-end">
                <v-menu location="bottom" class="locale-switch-btn">
                  <template #activator="{ props }">
                    <v-btn class="mx-2" :disabled="props.readonly" color="primary" prepend-icon="mdi-plus" v-bind="props">
                      {{ $t("processDraft-view-stepAddResource-action", { $: "Add Resource" }) }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="usageType in usageTypes" :key="usageType.value" @click="addResource(usageType.value)">
                      {{ usageType.title }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-row>
          </v-col>
          <!-- <v-col cols="2">
            <v-combobox
              v-model="modelValue.name"
              :readonly="props.readonly"
              variant="outlined"
              density="compact"
              :label="$t('processDraft-view-stepName-label', { $: 'Name' })"
              required
              :rules="nameRules"
              :items="names"
              hide-details="auto"
              @input="handleInput"
            />
          </v-col> -->
        </v-row>
        <v-row>
          <v-col v-if="modelValue.message" cols="12">
            <v-alert closable density="compact" :type="getAlertType(modelValue.message.type)" :text="modelValue.message.text" @click:close="modelValue.message = undefined">
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="filteredValidationErrors?.length > 0" cols="12">
            <v-alert
              v-for="(error, idx) in filteredValidationErrors"
              :key="idx"
              v-model="error.isErrorShown"
              density="compact"
              type="error"
              closable
              :text="error?.message"
              class="mb-1"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-row no-gutters>
      <template v-for="([usageType, specGroup], index) in Object.entries(groupedSpecs)" :key="index">
        <v-fade-transition>
          <v-col v-if="specGroup.length > 0" cols="12" class="resource-spec-group-row">
            <v-row no-gutters>
              <v-col cols="12" class="d-flex align-center text-body-1 font-weight-bold pl-6 pt-4">
                <spec-usage-type-icon :model-value="+usageType as StepResourceUsageType" class="d-inline-block" />
                <span class="ml-4">{{ groupedSpecTitles[+usageType as StepResourceUsageType] }}</span>
              </v-col>
              <v-slide-y-transition group @after-enter="onSpecListTransitionAfterEnter">
                <template v-for="(resourceSpec, index) in specGroup" :key="index">
                  <v-col cols="12 resource-spec-editor-row px-4 py-2">
                    <step-draft-resource-spec-editor
                      v-model="specGroup[index]"
                      :product-template="productTemplate"
                      :step="modelValue"
                      :readonly="props.readonly"
                      :validation-errors="validationErrors"
                      @remove="removeSpec"
                    />
                  </v-col>
                </template>
              </v-slide-y-transition>
            </v-row>
          </v-col>
        </v-fade-transition>
      </template>
    </v-row>
  </tree-view-node>
</template>

<style lang="scss">
.step-editor {
  .title {
    font-size: 14px;
    font-weight: 500;
  }

  .step-performance-column {
    height: 100%;

    /** This is intentional - this text-field 'hint' is to cover two text-fields */
    .v-input__details {
      padding-inline-start: 2px;
      white-space: nowrap;
      overflow: visible;
      margin-bottom: -6px;
    }
  }

  .node-body-expanded {
    .resource-spec-editor-row {
      border-bottom: 1px solid #ccced9;
    }

    .resource-spec-group-row:last-child .resource-spec-editor-row:last-child {
      border-bottom: none;
    }
  }

  @keyframes highlight-fade {
    0% {
      background-color: #d7cffc;
    }
    100% {
      background-color: #f5f5ff;
    }
  }

  .highlight-spec {
    animation: highlight-fade 1s ease-in 750ms;
  }
}
</style>
