import { App, Ref, ref } from "vue";
import { MenuItem, MenuMetaDescription } from "@/components/MegaMenu/MegaMenuModel";
import { _RouteRecordBase, Router, RouteRecord } from "vue-router";
import { $t } from "@/i18n";
import { translateMegaMenuItemRecursively } from "@/composables/translateMegaMenu";
import { useAuthStore } from "@/store/AuthStore";
import { checkValues } from "@/plugins/acl.plugin";

export interface IMegaMenu {
  updateMenuItems(): void;

  getMainMenuItemsRef(): Ref<MenuItem[]>;

  filterMenuItems(menuMetaDescriptionFilterExpression: (desc: MenuMetaDescription) => boolean): MenuItem[];

  translateMainMenu(): void;
}

class MegaMenu implements IMegaMenu {
  private readonly _router: Router;
  private readonly _mainMenuItems: Ref<MenuItem[]>;
  private _defaultMainMenuStructure: MenuItem[] = [];
  private _dynamicMenuMetaDescription: MenuMetaDescription[] = [];
  private _dashboardsMenuMetaDescription: MenuMetaDescription[] = [];

  constructor(private _app: App) {
    this._router = _app.config.globalProperties.$router;
    this._mainMenuItems = ref<MenuItem[]>([]);
    this._defaultMainMenuStructure = [
      {
        id: "resources",
        name: $t("menu-megaMenu-resources-label", { $: "Resources" }),
        items: [
          { id: "contractors", name: $t("menu-megaMenu-contractors-label", { $: "Contractors" }), items: [] },
          { id: "resources", name: $t("menu-megaMenu-resources-label", { $: "Resources" }), items: [] },
          { id: "time", name: $t("menu-megaMenu-time-label", { $: "Time" }), items: [] },
          { id: "capabilities", name: $t("menu-megaMenu-capabilities-label", { $: "Capabilities" }), items: [] },
          { id: "aas-models", name: $t("menu-megaMenu-aasModels-label", { $: "AAS models" }), items: [] }
        ]
      },
      {
        id: "operations",
        name: $t("menu-megaMenu-operations-label", { $: "Operations" }),
        items: [
          { id: "orders", name: $t("menu-megaMenu-orders-label", { $: "Orders" }), items: [] },
          { id: "engineering", name: $t("menu-megaMenu-engineering-label", { $: "Engineering" }), items: [] },
          { id: "planning-scheduling", name: $t("menu-megaMenu-planningScheduling-label", { $: "Planning & scheduling" }), items: [] },
          { id: "tracking", name: $t("menu-megaMenu-tracking-label", { $: "Tracking" }), items: [] }
        ]
      },
      {
        id: "analytics",
        name: $t("menu-megaMenu-analytics-label", { $: "Analytics" }),
        items: [
          { id: "dashboards", name: $t("menu-megaMenu-dashboards-label", { $: "Dashboards" }), items: [] },
          { id: "reports", name: $t("menu-megaMenu-reports-label", { $: "Reports" }), items: [] }
        ]
      },
      {
        id: "administration",
        name: $t("menu-megaMenu-administration-label", { $: "Administration" }),
        items: [
          { id: "administration", name: $t("menu-megaMenu-administration-label", { $: "Administration" }), items: [] },
          { id: "authentication", name: $t("menu-megaMenu-authentication-label", { $: "Authentication" }), items: [] }
        ]
      }
    ] as MenuItem[];
  }

  translateMainMenu() {
    this.translateMenuItems(this.getMainMenuItemsRef().value);
  }

  translateMenuItems(items: MenuItem[]) {
    items.forEach(translateMegaMenuItemRecursively);
  }

  setDefaultMainMenuStructure(structure: MenuItem[]) {
    this._defaultMainMenuStructure = structure;
  }

  updateMenuItems(): void {
    this._mainMenuItems.value = this.generateMenu(this.getFlatMenuMetaDescriptions());
  }

  getMainMenuItemsRef(): Ref<MenuItem[]> {
    return this._mainMenuItems;
  }

  filterMenuItems(menuMetaDescriptionFilterExpression: (desc: MenuMetaDescription) => boolean): MenuItem[] {
    const flatMetaMenu = this.getFlatMenuMetaDescriptions();
    return this.generateMenu(flatMetaMenu.filter(menuMetaDescriptionFilterExpression));
  }

  private checkRole(meta: Exclude<_RouteRecordBase["meta"], void>) {
    if (meta && meta.tenant_roles && (Array.isArray(meta.tenant_roles) || typeof meta.tenant_roles === "string")) {
      const values: any[] = meta.tenant_roles ? (Array.isArray(meta.tenant_roles) ? meta.tenant_roles : [meta.tenant_roles]) : [];
      if (values.length > 0) {
        const user = useAuthStore().getUser;
        if (user === null) {
          return false;
        }
        return checkValues({
          existing: [...new Set(user["tenant_roles"])],
          values: [...new Set(values)],
          anyModifier: true
        });
      }
    }
    return true;
    // const user = useAuthStore().getUser;
    // if (user === null) {
    //   return false;
    // }
    // return checkValues({
    //   existing: [...new Set(user[field])],
    //   values: [...new Set(values)],
    //   anyModifier: false
    // });
  }

  private generateMenu(flatMetaMenuItems: MenuMetaDescription[]): MenuItem[] {
    const mainMenu = JSON.parse(JSON.stringify(this._defaultMainMenuStructure));
    for (const mainMenuRecord of mainMenu) {
      const metaMenus = flatMetaMenuItems.filter((x) => x.path.length > 0 && x.path[0] === mainMenuRecord.id && x.hidden !== true);
      if (mainMenuRecord.items.length > 0) {
        for (const subMenu of mainMenuRecord.items) {
          const subMenuMetas = metaMenus.filter((x) => x.path.length > 1 && x.path[1] === subMenu.id);
          const items = subMenuMetas
            .filter((x) => x.path.length === 2 && this.checkRole(x.route.meta))
            .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
            .map((x) => ({
              id: `${mainMenuRecord.id}-${subMenu.id}-${x.name.toLowerCase()}`,
              name: x.name,
              route: x.route,
              items: [],
              query: x.query
            }));
          subMenu.items = [...subMenu.items, ...items];
        }
      }
      const items = metaMenus
        .filter((x) => x.path.length === 1)
        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        .map((x) => ({
          id: `${mainMenuRecord.id}-${x.name.toLowerCase()}`,
          name: x.name,
          route: x.route,
          items: [],
          query: x.query
        }));
      mainMenuRecord.items = [...mainMenuRecord.items, ...items];
    }
    this.translateMenuItems(mainMenu);
    return mainMenu;
  }

  private getFlatMenuMetaDescriptions(): MenuMetaDescription[] {
    const metaMenuRouteRecords = this._router.getRoutes().filter((x) => !!x.meta && !!x.meta.menu);
    return metaMenuRouteRecords.reduce(
      (acc: MenuMetaDescription[], record: RouteRecord) => {
        const menuMeta = (record.meta.menu as MenuMetaDescription[]).map((x) => ({
          ...x,
          route: record
        })) as MenuMetaDescription[];
        return [...acc, ...menuMeta];
      },
      [...this._dynamicMenuMetaDescription, ...this._dashboardsMenuMetaDescription]
    );
  }
}

export const $megaMenuSymbol = Symbol("$megaMenu");
let installed = false;
export const MegaMenuPlugin = {
  installed: false,
  install(app: App) {
    if (installed) return;
    installed = true;

    const megaMenu = new MegaMenu(app);

    app.config.globalProperties.$megaMenu = megaMenu;
    app.provide($megaMenuSymbol, megaMenu);
  }
};
