﻿<script lang="ts" setup>
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor.vue";
import CalendarsGrid from "@/components/Calendars/CalendarsGrid.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import { useCalendarsStore } from "@/store/CalendarsStore";
import { CalendarDto } from "@masta/generated-model";
import { ref } from "vue";

const calendarsStore = useCalendarsStore();
const selectedCalendar = ref<CalendarDto>();
const editAvailabilityRulesAssignmentDialog = ref<boolean>();
const refreshAction = ref<() => void>();


function manageAvailabilityRules(calendar: CalendarDto) {
  selectedCalendar.value = calendar;
  editAvailabilityRulesAssignmentDialog.value = true;
}
</script>

<template>
  <master-detail-layout scenario-selector>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("calendar-view-calendars-title", { $: "Calendars" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <calendars-grid @manage-availability-rules="manageAvailabilityRules" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-col v-if="editAvailabilityRulesAssignmentDialog">
        <availability-rules-assignment-editor
          :resource="selectedCalendar"
          :opened="editAvailabilityRulesAssignmentDialog"
          @cancel="editAvailabilityRulesAssignmentDialog = false"
          @save="editAvailabilityRulesAssignmentDialog = false"
        />
      </v-col>
    </template>
  </master-detail-layout>
</template>
