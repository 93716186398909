import { ApiClient } from "@/services/api/common.api";
import { DocumentContext, GetAllDocumentContextDto } from "@masta/generated-model";

export default {
  getAllDocumentContexts() {
    return ApiClient.get<GetAllDocumentContextDto>(`documentContexts`);
  },
  getDocumentContext(contextName: string) {
    return ApiClient.get<DocumentContext>(`documentContexts/${contextName}`);
  }
};
