﻿<script lang="ts" setup>
import UserProfileBtn from "@/components/UserProfileBtn";
import MastaLogo from "@/components/MastaLogo.vue";
import MegaMenu from "@/components/MegaMenu/MegaMenu.vue";
import SearchBtn from "@/components/Search/SearchBtn.vue";
import HelpBtn from "@/components/HelpBtn.vue";
import { useTagsStore } from "@/store/TagsStore";
import { NotifyUserNotification, useNotification } from "@/notifications";
import { UserNotificationEvent, UserNotificationType } from "@masta/generated-model";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { watchOnce } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/AuthStore";
import { useRouter } from "vue-router";
import { $t } from "@/i18n";
import UserNoticeButton from "@/components/UserNotices/UserNoticeButton.vue";
import ScenarioInfo from "@/components/Scenarios/ScenarioInfo.vue";

// fetch tags
useTagsStore().fetch(true);

// initialize user notifications
const snackbarStore = useSnackbarsStore();

const { isAuthenticated } = storeToRefs(useAuthStore());
const $router = useRouter();

watchOnce(
  isAuthenticated,
  (_isAuthenticated: boolean) => {
    if (_isAuthenticated) {
      useNotification(NotifyUserNotification, async (e: UserNotificationEvent) => {
        onUserNotification(e);
      });
    }
  },
  { immediate: true }
);

watchOnce(
  isAuthenticated,
  (_isAuthenticated: boolean) => {
    if (_isAuthenticated) {
      snackbarStore.createSnackbar({
        message: $t("login-successful-label", { $: "Login successful" }),
        type: "info",
        closeable: true,
        timeout: 1500
      });
    }
  },
  { immediate: true }
);

function onUserNotification(e: UserNotificationEvent) {
  let type;
  switch (e.notificationType) {
    case UserNotificationType.Information:
      type = "info";
      break;
    case UserNotificationType.Warning:
      type = "warning";
      break;
    case UserNotificationType.Error:
      type = "error";
      break;
    default:
      type = "info";
  }
  snackbarStore.createSnackbar({
    message: getMessage(e),
    closeable: true,
    type: type
  });
}

function getMessage(e: UserNotificationEvent) {
  if (e.message) {
    return e.message;
  }
  switch (e.notificationType) {
    case UserNotificationType.Error:
      return getCommandErrorMessage(e);
    case UserNotificationType.Warning:
      return getCommandWarningMessage(e);
    default:
      return getCommandSuccessMessage(e);
  }
}

function getCommandSuccessMessage(e: UserNotificationEvent) {
  switch (e.commandType) {
    case "CDEMS.Application.Scheduling.ScheduleTasks.ScheduleTasksCommand":
      return $t("tenant-userNotification-scheduleTasksCommandSuccess-message", { $: "Scheduling has finished successfully" });
    case "CDEMS.Application.Scheduling.DescheduleTasks.DescheduleTasksCommand":
      return $t("tenant-userNotification-descheduleTasksCommandSuccess-message", { $: "Descheduling has finished successfully" });
    case "CDEMS.Application.Scheduling.RecalculateTasks.RecalculateTasksCommand":
      return $t("tenant-userNotification-recalculateTasksCommandSuccess-message", { $: "Recalculate has finished successfully" });
    case "CDEMS.Application.Scheduling.ReleaseTasks.ReleaseTasksCommand":
      return $t("tenant-userNotification-releaseTasksCommandSuccess-message", { $: "Releasing tasks has finished successfully" });
    case "CDEMS.Application.Scheduling.UnreleaseTasks.UnreleaseTasksCommand":
      return $t("tenant-userNotification-unreleaseTasksCommandSuccess-message", { $: "Unreleasing tasks has finished successfully" });
    case "CDEMS.Application.Orders.ReleaseOrderToProduction.ReleaseOrdersToProductionCommand":
      return $t("tenant-userNotification-releaseOrderToProductionCommandCommandSuccess-message", { $: "Releasing orders to production has finished successfully" });
    default:
      return $t("tenant-userNotification-defaultCommandSuccess-message", { $: "The action has been processed successfully" });
  }
}

function getCommandErrorMessage(e: UserNotificationEvent) {
  switch (e.commandType) {
    case "CDEMS.Application.Scheduling.ScheduleTasks.ScheduleTasksCommand":
      return $t("tenant-userNotification-scheduleTasksCommandError-message", { $: "Scheduling has failed" });
    case "CDEMS.Application.Scheduling.DescheduleTasks.DescheduleTasksCommand":
      return $t("tenant-userNotification-descheduleTasksCommandError-message", { $: "Descheduling has failed" });
    case "CDEMS.Application.Scheduling.RecalculateTasks.RecalculateTasksCommand":
      return $t("tenant-userNotification-recalculateTasksCommandError-message", { $: "Recalculate has failed" });
    case "CDEMS.Application.Scheduling.ReleaseTasks.ReleaseTasksCommand":
      return $t("tenant-userNotification-releaseTasksCommandError-message", { $: "Releasing tasks has failed" });
    case "CDEMS.Application.Scheduling.UnreleaseTasks.UnreleaseTasksCommand":
      return $t("tenant-userNotification-unreleaseTasksCommandError-message", { $: "Unreleasing tasks has failed" });
    case "CDEMS.Application.Orders.ReleaseOrderToProduction.ReleaseOrdersToProductionCommand":
      return $t("tenant-userNotification-releaseOrderToProductionCommandCommandError-message", { $: "Releasing orders to production has failed" });
    default:
      return $t("tenant-userNotification-defaultCommandError-message", { $: "The action has failed" });
  }
}

function getCommandWarningMessage(e: UserNotificationEvent) {
  switch (e.commandType) {
    case "CDEMS.Application.Orders.ReleaseOrderToProduction.ReleaseOrdersToProductionCommand":
      return $t("tenant-userNotification-releaseOrderToProductionCommandCommandWarning-message", { $: "Releasing orders to production has partially succeeded" });
    default:
      return $t("tenant-userNotification-defaultCommandWarning-message", { $: "The action has finished with some warnings" });
  }
}

async function openActions() {
  await $router.push({
    path: `/system/actions`
  });
}

async function openNotices() {

}
</script>

<template>
  <v-app-bar height="81" elevation="4" v-bind="$attrs">
    <template #prepend>
      <div>
        <masta-logo health-indicator width="165" height="50" />
      </div>
    </template>
    <v-app-bar-title>
      <mega-menu />
    </v-app-bar-title>
    <template #append>
      <search-btn />
      <!-- <v-btn color="primary" fab icon="mdi-list-status" variant="plain" @click="openActions" /> -->
      <user-notice-button @open-notices="openNotices" />
      <help-btn />
      <user-profile-btn />
      <scenario-info />
    </template>
  </v-app-bar>

  <v-main v-bind="$attrs">
    <router-view v-slot="{ Component }" :key="$route.path">
      <v-fade-transition>
        <component :is="Component" />
      </v-fade-transition>
    </router-view>
  </v-main>
</template>

<style lang="scss"></style>
