import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/AuthStore";

export const useContextMaterialGanttGridAction = () => {
  const $router = useRouter();

  return async (materialId: string) => {
    await $router.push({
      path: `/:scenarioId/scheduling/context-material-gantt`,
      query: {
        materialId: materialId
      }
    });
  };
};
