import { ApiClient } from "@/services/api/common.api";
import { AssignModelInstanceToOrderCommand, DeassignModelInstanceFromOrderCommand } from "@masta/generated-model";

export default {
  async assignModelInstance(request: AssignModelInstanceToOrderCommand) {
    return ApiClient.post(`/orders/assignModelInstance`, request);
  },
  async deassignModelInstance(request: DeassignModelInstanceFromOrderCommand) {
    return ApiClient.post(`/orders/deassignModelInstance`, request);
  }
};
