import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import {
  CreateStepResourceSpecTemplateDto,
  GetProductTemplateStepResourceSpecsPaginatedQuery,
  IAgGridPaginationResponse,
  MeasurementUnit,
  StepResourceSpecDto,
  StepTemplateDto
} from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { GridState, GridStateActions, useGridStore } from "@/store/GridRegistryState";
import { asServerSideDurationFormattedString } from "@masta/shared";

type CreateProductTemplateStepResourceSpecType = CreateStepResourceSpecTemplateDto;
type UpdateProductTemplateStepResourceSpecType = CreateStepResourceSpecTemplateDto;

export class ProductTemplateStepResourceSpecsServerSideDataSource extends ServerSideDataSource<
  StepResourceSpecDto,
  StepResourceSpecDto,
  CreateProductTemplateStepResourceSpecType,
  UpdateProductTemplateStepResourceSpecType,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _productTemplatesStore: Store<string, GridState, any, GridStateActions>;
  private _step: StepTemplateDto;

  constructor(id: string, productTemplatesStoreId: string, step: StepTemplateDto) {
    super(id);
    this._scenarioStore = useScenariosStore();
    this._productTemplatesStore = useGridStore(productTemplatesStoreId);
    this._step = step;
  }

  async create(entity: StepResourceSpecDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const { data } = await ApiService.productTemplates.createResourceSpec(this.selectedScenarioId(), this.taskId(), this.stepId(), request);
    return data;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<StepResourceSpecDto>> {
    const { rowGroupCols, groupKeys } = params.request;

    const request = {
      ...this.constructServerRequest(params),
      scenarioId: this.selectedScenarioId(),
      taskId: this.taskId(),
      stepId: this.stepId(),
      rowGroupColIds: rowGroupCols.map((c) => c.id),
      groupKeys
    } as GetProductTemplateStepResourceSpecsPaginatedQuery;
    const response = await ApiService.productTemplates.getStepResourceSpecsPaginated(request);
    return response.data;
  }

  async getResourceSpecsFromDetails(_: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<StepResourceSpecDto>> {
    const { data } = await ApiService.productTemplates.getDetails(this.selectedScenarioId(), this.taskId());
    const steps = data.steps ?? [];
    const specs = steps.find((s) => s.id === this.stepId())?.resources ?? [];
    return { results: specs, count: specs.length } as IAgGridPaginationResponse<StepResourceSpecDto>;
  }

  async getSingle(id: string): Promise<StepResourceSpecDto | undefined> {
    const request = {
      scenarioId: this.selectedScenarioId(),
      taskId: this.taskId(),
      stepId: this.stepId()
    } as GetProductTemplateStepResourceSpecsPaginatedQuery;
    const { data } = await ApiService.productTemplates.getStepResourceSpecsPaginated(request);
    return data.results?.find((s) => s.id === id);
  }

  async update(entity: StepResourceSpecDto): Promise<string> {
    const request = this.transformForUpdate(entity);
    const resourceSpecId = entity.id!;
    const { data } = await ApiService.productTemplates.updateResourceSpec(this.selectedScenarioId(), this.taskId(), this.stepId(), resourceSpecId, request);
    return data;
  }

  async remove(entity: StepResourceSpecDto): Promise<void> {
    const resourceSpecId = entity.id!;
    await ApiService.productTemplates.deleteResourceSpec(this.selectedScenarioId(), this.taskId(), this.stepId(), resourceSpecId);
  }

  protected transformForCreate(listEntity: StepResourceSpecDto): CreateProductTemplateStepResourceSpecType {
    return {
      resourceType: listEntity.resourceType,
      resourceIds: listEntity.resourceIds ?? null,
      usageType: listEntity.usageType,
      inventoryChangeType: listEntity.inventoryChangeType,
      inventoryChangeThresholdQuantity: listEntity.inventoryChangeThresholdQuantity ?? null,
      assignmentType: listEntity.assignmentType,
      quantity: listEntity.quantity,
      quantityUnit: listEntity.quantityUnit ?? MeasurementUnit.Unknown,
      processingTime: listEntity.processingTime ? asServerSideDurationFormattedString(listEntity.processingTime) : null,
      quantityPerTime: listEntity.quantityPerTime ? listEntity.quantityPerTime : null,
      modelInstances: null,
      modelInstanceBusinessIds: listEntity.modelInstances?.map((mi) => mi.businessId) ?? null
    } as CreateStepResourceSpecTemplateDto;
  }

  protected transformForUpdate(listEntity: StepResourceSpecDto): UpdateProductTemplateStepResourceSpecType {
    return {
      ...this.transformForCreate(listEntity)
    } as CreateStepResourceSpecTemplateDto;
  }

  private selectedScenarioId() {
    return this._scenarioStore.selectedScenario!.id;
  }

  private stepId() {
    return this._step.id;
  }

  private taskId() {
    return this._step.taskId;
  }
}
