import { createI18n } from "vue-i18n";
import { I18nController } from "@/services/api-service";
import { useTranslationsStore } from "@/store/TranslationsStore";

export enum Locale {
  English = "en", // English
  Polish = "pl", // Polski
  Ukrainian = "uk", // український
  German = "de", // Deutsch
  Spanish = "es", // Español
  French = "fr", // Français
  Portuguese = "pt", // Português
  Czech = "cs", // Česky
  Slovak = "sk", // Slovenská
  Turkish = "tr", // Türkçe
  ChineseSimplified = "zh", // 简体中文
}

export const publicLocalStorageKey = "client.i18n.locale";
export const authorizedLocalStorageKey = "client.i18n.locale.";
export const locales = Object.values(Locale);

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  fallbackLocale: Locale.English,
  missingWarn: import.meta.env.VITE_I18N_WARN === "true",
  fallbackWarn: import.meta.env.VITE_I18N_WARN === "true"
});

export async function setLocaleFromLocalStoragePublic(reload = false) {
  let locale = localStorage.getItem(publicLocalStorageKey) as Locale;
  if (!locale) {
    locale = i18n.global.fallbackLocale.value as Locale;
  }
  await setLocalePublic(locale, reload);
  localStorage.setItem(publicLocalStorageKey, locale);
}

export async function setLocaleFromLocalStorageAuthorized(tenantId: string, reload = false) {
  let locale = localStorage.getItem(`${authorizedLocalStorageKey}${tenantId}`) as Locale;
  if (!locale) {
    locale = i18n.global.fallbackLocale.value as Locale;
  }
  await setLocaleAuthorized(locale, reload);
  localStorage.setItem(`${authorizedLocalStorageKey}${tenantId}`, locale);
}

// Set new locale.
export async function setLocaleAuthorized(locale: Locale, reload = false) {
  // Load locale if not available yet.
  if (!i18n.global.availableLocales.includes(locale) || reload) {
    const messages = await loadLocaleAuthorized(locale);

    // fetch() error occurred.
    if (messages === undefined) {
      return;
    }

    // Add locale.
    i18n.global.mergeLocaleMessage(locale, messages);
  }

  // Set locale.
  i18n.global.locale.value = locale;
  useTranslationsStore().notifyLocaleChange(locale);
}

// Set new locale.
export async function setLocalePublic(locale: Locale, reload = false) {
  // Load locale if not available yet.
  if (!i18n.global.availableLocales.includes(locale) || reload) {
    const messages = await loadLocalePublic(locale);

    // fetch() error occurred.
    if (messages === undefined) {
      return;
    }

    // Add locale.
    i18n.global.mergeLocaleMessage(locale, messages);
  }

  // Set locale.
  i18n.global.locale.value = locale;
  useTranslationsStore().notifyLocaleChange(locale);
}

export async function loadLocaleAuthorized(locale: Locale): Promise<Record<string, any>> {
  return await loadLocale(locale, "getClientLabels");
}

export async function loadLocalePublic(locale: Locale): Promise<Record<string, any>> {
  return await loadLocale(locale, "getPublicClientLabels");
}

async function loadLocale(locale: Locale, method: keyof I18nController): Promise<Record<string, any>> {
  try {
    const response = await new I18nController()[method](locale);
    if (response.status == 200) {
      return response.data;
    }
  } catch (error: any) {
    console.error(error);
    throw new Error("Could not load i18n for locale: " + locale);
  }
  throw new Error("Could not load i18n for locale: " + locale);
}

type TranslationFnType = typeof i18n.global.t;
const wrapWithMissingTranslationLogger =
  (t: TranslationFnType) =>
    (...[key, ...args]: any): ReturnType<TranslationFnType> => {
      const result = t.apply(i18n.global, [key, ...args] as Parameters<TranslationFnType>);
      if (result === key) {
        useTranslationsStore().reportMissingTranslation(i18n.global.locale.value, key, args[0]);
      }
      return result;
    };
i18n.global.t = wrapWithMissingTranslationLogger(i18n.global.t);

// @ts-ignore
window["i18nT8l"] = {
  createMissingKeysReport: () => useTranslationsStore().createMissingTranslationKeysReport(),
  createMissingKeysJsonTemplate: () => useTranslationsStore().createMissingTranslationKeysJsonTemplate()
};

export const $t: TranslationFnType = (...[key, ...args]: any) => {
  return i18n.global.t.apply(i18n.global, [key, ...args] as Parameters<TranslationFnType>);
};

export default {
  $t
};
