<template>
  <master-detail-layout scenario-selector>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("agreement-view-agreements-title", { $: "Agreements" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height" no-gutters>
          <agreements-grid @resource2resource="resource2resourceAction" />
        </v-card-text>
      </v-card>
      <resource2-resource-dialog
        v-if="resourceType >= 0 && agreement"
        :opened="resource2resourceDialog"
        :assignment-type="resourceAssignmentType"
        :resource-type="resourceType"
        :resource="agreement"
        @close="resource2resourceDialogClose"
      />
    </template>
  </master-detail-layout>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import AgreementsGrid from "@/components/Agreements/AgreementsGrid";
import { mapActions } from "pinia";
import { useAgreementsStore } from "@/store/AgreementsStore";
import Resource2ResourceDialog from "@/components/Resources/ResourceAssignment/Resource2ResourceDialog.vue";
import { ResourceAssignmentType } from "@masta/generated-model";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";

export default {
  name: "Agreements",
  components: {
    Breadcrumbs,
    Resource2ResourceDialog,
    AgreementsGrid,
    MasterDetailLayout,
  },
  title: "Agreements",
  data() {
    return {
      resource2resourceDialog: false,
      agreement: null,
      refreshGridAction: null,
      resourceType: null,
      resourceAssignmentType: ResourceAssignmentType.AgreementAssignment
    };
  },
  methods: {
    resource2resourceAction(resourceType, agreement) {
      this.resourceType = resourceType;
      this.agreement = agreement;
      this.resource2resourceDialog = true;
    },
    resource2resourceDialogClose() {
      this.resource2resourceDialog = false;
      this.resourceType = null;
      this.agreement = null;
    }
  }
};
</script>

<style lang="scss"></style>
