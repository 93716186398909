import ApiService from "@/services/api";
import { AvailableSettingKeyDto, SettingDto } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useErrorsStore } from "@/store/ErrorsStore";

export const FACTORY_TIMEZONE = "factory.calendar.timeZone";

interface State {
  settings: SettingDto[];
  availableKeys: AvailableSettingKeyDto[];
}

export const useSettingsStore = defineStore("settings", {
  state: (): State => ({
    settings: [],
    availableKeys: []
  }),
  actions: {
    async fetch() {
      try {
        const response = await ApiService.settings.getSettings();
        this.settings = response.data.settings.map((s: SettingDto) => ({
          ...s,
          value: s.value,
          metadata: s.metadata ? { ...s.metadata, value: s.metadata.values } : null
        }));
        const keysResponse = await ApiService.settings.getSettingsKeys();
        keysResponse.data.keys.sort((a: AvailableSettingKeyDto, b: AvailableSettingKeyDto) => a.key.localeCompare(b.key));
        this.availableKeys = keysResponse.data.keys;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchIfEmpty() {
      if (this.settings.length === 0) {
        await this.fetch();
      }
    },
    async getSettingByKey(key: string): SettingDto | undefined {
      await this.fetchIfEmpty();
      const setting = this.settings.find(s => s.key === key);
      return setting;
    },
    async create(setting: SettingDto) {
      try {
        await ApiService.settings.createSetting({
          ...setting,
          value: setting.value
        });
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async update(setting: SettingDto) {
      try {
        await ApiService.settings.updateSetting({
          ...setting,
          value: setting.value
        });
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async delete(id: string) {
      try {
        await ApiService.settings.deleteSetting(id);
        await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});

// export const mutations = {
//   SET_AVAILABLE_KEYS(_state, keys) {
//     _state.availableKeys = [...keys].map((k) => ({ ...k, children: [] }));
//   }
// };

