<script lang="ts" setup>
import CostCataloguePickerDialog from "@/components/CostCatalogue/CostCataloguePickerDialog.vue";
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { nameOrBusinessIdOrIdOrNull, nonEmptyGuidOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import { translateCurrencyCode, translateMeasurementUnit, translateResourceType } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { useCostCataloguesStore } from "@/store/CostCataloguesStore";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useTagsStore } from "@/store/TagsStore";
import { CostCatalogueItemDto, CurrencyCodes, MeasurementUnit, ModelInstanceAssignmentsDto, OrganizationDto, ResourceInfoDto, ResourceType } from "@masta/generated-model";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { KeyCreatorParams, SelectionChangedEvent, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { inject, ref, watch } from "vue";

const modelInstanceId = defineModel<string>({
  required: true
});
const assignments = ref<ModelInstanceAssignmentsDto | null>(null);
const modelInstanceStore = useModelInstancesStore();
const snackbarsStore = useSnackbarsStore();
const tagsStore = useTagsStore();
const costCataloguesStore = useCostCataloguesStore();
const selectedRow = ref<CostCatalogueItemDto | null>(null);

const gridWrapperRef = ref<GridWrapperComponent>();

const modelInstanceCostCataloguesAssignDialog = ref(false);

const selectedCostCataloguesToAssign = ref<CostCatalogueItemDto[]>();

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

watch(modelInstanceId, async (id) => {
  if (id) {
    await fetchModelInstanceAssignments();
  }
}, { immediate: true });

watch(selectedCostCataloguesToAssign, async (costCatalogueItems) => {
  if (costCatalogueItems) {

    for (const costCatalogueItem of costCatalogueItems) {
      await costCataloguesStore.assignModelInstance({
        costCatalogueItemId: costCatalogueItem.id,
        modelInstanceId: modelInstanceId.value,
        scenarioId: costCatalogueItem.scenarioId
      } as any);

      snackbarsStore.createSnackbar({
        message: $t("modelInstanceAssignments-snackbar-costCatalogueItem-assigned", { $: "Cost catalogue item {item} assigned", item: costCatalogueItem.businessId }),
        closeable: true,
        timeout: 2500
      });    
    }

    await fetchModelInstanceAssignments();
  }
});

async function fetchModelInstanceAssignments() {
  selectedRow.value = null;
  assignments.value = await modelInstanceStore.fetchModelInstanceAssignments(modelInstanceId.value);
}

function assignCostCatalogueItems() {
  modelInstanceCostCataloguesAssignDialog.value = true;
}

async function unassignCostCatalogueItems() {
  if (gridWrapperRef.value == null) return;

  const selectedRows = gridWrapperRef.value.gridApi.getSelectedRows();
  if (selectedRows.length === 0) return;

  for (const row of selectedRows) {
    const costCatalogueItem = row as CostCatalogueItemDto;
    await costCataloguesStore.deassignModelInstance({
      costCatalogueItemId: costCatalogueItem.id,
      modelInstanceId: modelInstanceId.value,
      scenarioId: costCatalogueItem.scenarioId
    } as any);

    snackbarsStore.createSnackbar({
      message: $t("modelInstanceAssignments-snackbar-costCatalogueItem-unassigned", { $: "Cost catalogue item {item} unassigned", item: costCatalogueItem.businessId }),
      closeable: true,
      timeout: 2500
    });
  }

  await fetchModelInstanceAssignments();
}

function onSelectionChanged(e: SelectionChangedEvent) {
  if (e.source === "rowDataChanged") return;
  const selectedRows = e.api.getSelectedRows();
  selectedRow.value = selectedRows.length > 0 ? selectedRows[0] : null;
}

function mapToOrganizationDto(organizationBusinessId: string | undefined): OrganizationDto | null {
  if (organizationBusinessId) {
    const organizationDto: OrganizationDto = {
      id: "",
      scenarioId: "",
      name: organizationBusinessId,
      businessId: organizationBusinessId,
      types: [],
      tags: [],
    };
    return organizationDto;
  } else {
    return null;
  }
}

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "id",
      headerValueGetter: (_: any) => $t("costCatalogue-list-id-label", { $: "Id" }),
      editable: false,
      sortable: false,
      filter: false,
      hide: true
    },
    {
      field: "resourceBusinessId",
      type: ["resourcePickerTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-resourceBusinessId-label", { $: "Resource Business Id" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        const resourceInfo: ResourceInfoDto = params.value;
        if (resourceInfo) {
          const result = nameOrBusinessIdOrIdOrNull(resourceInfo);
          return result ?? "";
        }
        const data = params.data;
        return data?.resourceBusinessId ?? nonEmptyGuidOrNull(data?.resourceId) ?? null;
      },
      valueGetter: (params: ValueGetterParams) => {
        const resourceInfo = mapToOrganizationDto(params.data.resourceBusinessId);
        return resourceInfo;
      }
    },
    {
      // This column is hidden, because it's used for filtering only (filterGridActionRef)
      field: "resourceType",
      hide: true,
      headerValueGetter: (_: any) => $t("costCatalogue-list-resourceType-label", { $: "Resource Type" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(ResourceType), translateResourceType),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => translateResourceType(params.value)
    },
    {
      field: "manufacturer",
      type: ["organizationPickerTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-manufacturer-label", { $: "Manufacturer" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams<CostCatalogueItemDto, CostCatalogueItemDto>) => {
        return params.data?.manufacturer;
      },
      valueGetter: (params: ValueGetterParams<CostCatalogueItemDto, OrganizationDto>) => {
        const { data } = params;
        if (!data || !data.manufacturer) return null;

        return {
          name: data?.manufacturer,
          businessId: data?.manufacturer
        } as OrganizationDto;
      }
    },
    {
      field: "name",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("costCatalogue-list-name-label", { $: "Name" }),
      floatingFilterComponentParams: {
        placeholder: $t("costCatalogue-list-name-label", { $: "Name" })
      }
    },
    {
      field: "pricePerQuantity",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-pricePerQuantity-label", { $: "Price-Per-Quantity" })
    },
    {
      field: "currencyCode",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-currencyCode-label", { $: "Currency" }),
      valueFormatter: (params: ValueFormatterParams) => translateCurrencyCode(params.data.currencyCode),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(CurrencyCodes), translateCurrencyCode),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "validFrom",
      type: ["datepickerTypeColumn", "dateTimeTypeColumn"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-validFrom-label", { $: "Valid From" }),
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.validFrom);
      }
    },
    {
      field: "validTo",
      type: ["datepickerTypeColumn", "dateTimeTypeColumn"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-validTo-label", { $: "Valid To" }),
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.validTo);
      }
    },
    {
      field: "businessId",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("costCatalogue-list-businessId-label", { $: "Business ID" }),
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      floatingFilterComponentParams: {
        placeholder: $t("costCatalogue-list-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "providerBusinessId",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      type: ["organizationPickerTypeColumn", "textFloatingFilterColumnType"],
      valueGetter: (params: ValueGetterParams<CostCatalogueItemDto, OrganizationDto>) => {
        const { data } = params;
        if (!data || !data.providerBusinessId) return null;

        return {
          name: data?.providerBusinessId,
          businessId: data?.providerBusinessId
        } as OrganizationDto;
      },
      valueFormatter: (params: ValueFormatterParams<CostCatalogueItemDto, CostCatalogueItemDto>) => {
        return params.data?.providerBusinessId;
      },
      headerValueGetter: (_: any) => $t("costCatalogue-list-providerName-label", { $: "Provider" })
    },
    {
      field: "quantityPerPrice",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-quantityPerPrice-label", { $: "Quantity Per Price" })
    },
    {
      field: "quantityPerPriceUnit",
      editable: false,
      sortable: true,
      resizable: true,
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      valueFormatter: (params: any) => (params.data ? translateMeasurementUnit(params.data.quantityPerPriceUnit) : null),
      headerValueGetter: (_: any) => $t("costCatalogue-list-quantityPerPriceUnit-label", { $: "Quantity-Per-Price Unit" }),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "measurementUnit",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-measurementUnit-label", { $: "Measurement Unit" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => translateMeasurementUnit(params.data.measurementUnit),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "orderableUnit",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-orderableUnit-label", { $: "Orderable Unit" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => translateMeasurementUnit(params.data.orderableUnit),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "packSize",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("costCatalogue-list-packSize-label", { $: "Pack size" })
      },
      headerValueGetter: (_: any) => $t("costCatalogue-list-packSize-label", { $: "Pack size" })
    },
    {
      field: "packaging",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("costCatalogue-list-packaging-label", { $: "Packaging" }),
      floatingFilterComponentParams: {
        placeholder: $t("costCatalogue-list-packaging-label", { $: "Packaging" })
      }
    },
    {
      field: "minimumNumberOfPackagesToOrder",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-minimumNumberOfPackagesToOrder-label", { $: "Min. Number Of Packages To Order" })
    },
    {
      field: "minimumOrderableQuantity",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("costCatalogue-list-minimumOrderableQuantity-label", { $: "Min. Orderable Qty" })
    }
  ];
}
</script>

<template>
  <grid-wrapper 
    ref="gridWrapperRef" 
    identifier="modelInstanceCostCatalogueItems" 
    row-selection="multiple"
    hide-custom-actions-separator 
    :grid-data="assignments?.costCatalogueItems"
    :default-col-def="defaultColumnDef"
    @selection-changed="onSelectionChanged"
    @prepare-columns="onPrepareColumns">
    <template #custom-buttons>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" @click="assignCostCatalogueItems">
              <v-icon icon="mdi-playlist-plus" class="pr-4"></v-icon>
              {{ $t("modelInstanceCostCatalogueItems-list-assign-action", { $: "Assign" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstanceCostCatalogueItems-list-assign-tooltip", { $: "Assign" }) }}</span>
      </v-tooltip>

      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" :disabled="!selectedRow" @click="unassignCostCatalogueItems">
              <v-icon icon="mdi-playlist-minus" class="pr-4"></v-icon>
              {{ $t("modelInstanceCostCatalogueItems-list-unassign-action", { $: "Unassign" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstanceCostCatalogueItems-list-unassign-tooltip", { $: "Unassign" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>

  <cost-catalogue-picker-dialog
    v-model:selected-cost-catalogues="selectedCostCataloguesToAssign"
    v-model:dialog="modelInstanceCostCataloguesAssignDialog"
    :preselected-cost-catalogue-ids="assignments?.costCatalogueItems.map((costCatItem) => (costCatItem.id))">
  </cost-catalogue-picker-dialog>
</template>

<style scoped lang="scss"></style>
