<script lang="ts" setup>
import { TagDto } from "@masta/generated-model";
import { computed, nextTick, ref, watch } from "vue";
import ApiService from "@/services/api";
import TagsGrid from "@/components/Tags/TagsGrid.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import { $t } from "@/i18n";
import { VAutocomplete } from "vuetify/lib/components/index.mjs";
import { useTagsStore } from "@/store/TagsStore";

const modelValue = defineModel<string[]>({ required: true });
const props = defineProps<{
  label?: string;
  color?: string | undefined;
  placeholder?: string;
  readonly?: boolean;
  rules?: any[] | undefined;
}>();

const tagsStore = useTagsStore();
const loading = ref(false);
const search = ref<string | undefined>();
const tags = ref<TagDto[]>([]);
const dialog = ref(false);
const selectedTags = ref<TagDto[]>();

const autocompleteRef = ref<VAutocomplete>();

const noDataText = computed(() => {
  if (!search.value) {
    return $t("autocomplete-control-startTyping-label", { $: "Start typing ..." });
  }
  return $t("autocomplete-control-noData-label", { $: "No data" });
});

watch(search, (newValue) => {
  if (!newValue) {
    tags.value = [];
    return;
  }
  querySelections(newValue);
});

async function querySelections(query: string) {
  loading.value = true;
  const response = await ApiService.tags.findTags(query);
  tags.value = response.data.tags ?? [];
  loading.value = false;
}

function customFilter(itemTitle: string, queryText: string, item: any) {
  const name = item.raw?.name.toLowerCase();
  const parentName = item.raw?.parentName?.toLowerCase() ?? "";
  const emoji = item.raw?.emoji?.toLowerCase() ?? "";
  const searchText = queryText.toLowerCase();

  return name.indexOf(searchText) > -1 || parentName.indexOf(searchText) > -1 || emoji.indexOf(searchText) > -1;
}

function updatedModel() {
  search.value = "";
}

function selectTags() {
  dialog.value = true;
}

function onBtnConfirm() {
  if (selectedTags.value) {
    const tagNames = selectedTags.value ? selectedTags.value.map((v) => v.name!) : [];
    modelValue.value = tagNames;
  }
  dialog.value = false;
}

function onBtnCancel() {
  dialog.value = false;
}

function onRowSelected(rows: TagDto[] | null | undefined) {
  if (rows) {
    selectedTags.value = rows;
  } else {
    selectedTags.value = [];
  }
}

function onRowDoubleClicked(row: TagDto[] | null | undefined) {
  if (row) {
    selectedTags.value = Array.isArray(row) ? [...row] : [row];
  } else {
    selectedTags.value = [];
  }

  onBtnConfirm();
}

function focus() {
  nextTick(() => autocompleteRef.value?.focus());
}

function hasFocus(): boolean | undefined {
  return autocompleteRef.value?.focused;
}

function setMenu(menu: boolean) {
  if (autocompleteRef.value) {
    autocompleteRef.value.menu = menu;
  }
}

function getMenu(): boolean | undefined {
  return autocompleteRef.value?.menu;
}

function onSelectTags() {
  selectTags();
}

function getTagChip(_tag: any) {
  if (_tag && _tag.title) {
    const tag = tagsStore.tags.find((t) => t.name === _tag.title);
    if (!tag) return _tag.title;
    return tag.emoji ? `${tag.emoji} ${tag.name}` : tag.name;
  }
  if (_tag.id && _tag.name) {
    return _tag.emoji ? `${_tag.emoji} ${_tag.name}` : _tag.name;
  }
  return _tag;
}

defineExpose({ focus, hasFocus, onSelectTags, setMenu, getMenu });
</script>

<template>
  <div class="d-flex align-center tags-picker">
    <v-autocomplete
      ref="autocompleteRef"
      v-model="modelValue"
      v-model:search="search"
      class="flex-1-1-100"
      auto-select-first
      :color="color"
      :label="label"
      :placeholder="placeholder"
      :items="tags"
      :loading="loading"
      :disabled="props.readonly"
      :rules="props.rules"
      :custom-filter="customFilter"
      multiple
      chips
      small-chips="false"
      closable-chips
      item-title="name"
      variant="outlined"
      density="compact"
      hide-details="auto"
      hide-no-data
      :no-data-text="noDataText"
      @update:model-value="updatedModel"
    >
      <template #item="{ props, item }">
        <v-list-item v-bind="props" :title="item?.raw?.emoji + ' ' + item?.raw?.name ?? ''"></v-list-item>
      </template>
      <template #chip="{ item, props }">
        <v-chip v-bind="props" :text="getTagChip(item)" :color="color"></v-chip>
      </template>
      <template #append-inner>
        <v-btn v-if="!props.readonly" variant="plain" icon="mdi-select-search" density="compact" :ripple="false" @click.stop="onSelectTags"></v-btn>
      </template>
    </v-autocomplete>
    <v-dialog v-model="dialog" width="100%" height="80vh" @click:outside="onBtnCancel">
      <v-card min-width="300px" height="100%" class="reference-picker-card">
        <close-card-button @click="onBtnCancel"></close-card-button>
        <v-card-title class="pa-0">
          <div class="text-h4 pb-5">{{ $t("tagsPicker-list-chooseTags-label", { $: "Choose Tags" }) }}</div>
        </v-card-title>
        <v-card-text class="px-0">
          <tags-grid :reference-value="modelValue" @row-selected="onRowSelected" @row-double-clicked="onRowDoubleClicked"></tags-grid>
        </v-card-text>
        <v-card-actions class="pa-0">
          <div class="left-col pl-0">
            <v-btn variant="elevated" color="secondary" class="w-100" @click="onBtnCancel">Cancel</v-btn>
          </div>
          <div class="middle-col"></div>
          <div class="right-col pr-0">
            <v-btn variant="elevated" color="primary" class="w-100" @click="onBtnConfirm">Confirm</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">
.tags-picker {
  :deep(.v-autocomplete__menu-icon) {
    display: none;
  }
}
</style>
