import { ApiClient } from "@/services/api/common.api";
import {
  GetMaterialsDemandQuery,
  GetPlannedCapacityUtilizationQuery,
  GetPlanningViewQuery,
  MaterialsDemandResult,
  PlannedCapacityUtilizationResult,
  PlanningViewDto,
  UpdateCapacityByAgreementCommand,
  UpdateCapacityByRangeCommand,
  UpdatePlanningViewCommand
} from "@masta/generated-model";

export default {
  async getPlanningView(request: GetPlanningViewQuery) {
    return ApiClient.get<PlanningViewDto>("/planning", {
      params: request
    });
  },
  async savePlanningView(request: UpdatePlanningViewCommand) {
    return ApiClient.put("/planning", request);
  },
  async updateCapacityByAgreement(request: UpdateCapacityByAgreementCommand) {
    return ApiClient.put("/planning/capacity/agreement", request);
  },
  async updateCapacityByRange(request: UpdateCapacityByRangeCommand) {
    return ApiClient.put("/planning/capacity/range", request);
  },
  async getPlannedCapacityUtilization(request: GetPlannedCapacityUtilizationQuery) {
    return ApiClient.post<PlannedCapacityUtilizationResult>("/planning/capacity-utilization", request);
  },
  async getMaterialsDemand(request: GetMaterialsDemandQuery) {
    return ApiClient.post<MaterialsDemandResult>("/planning/materials-demand", request);
  }
};
