<script lang="ts" setup>
import { getOrderReleaseStatusCellStyle } from "@/components/CustomerOrders/CustomerOrderUtils";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { PurchaseOrdersServerSideDataSource } from "@/components/PurchaseOrders/PurchaseOrdersServerSideDataSource";
import { requiredRule, validDateRequiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { translateOrderReleaseStatus } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { OrderStatusChangedNotification, useNotification } from "@/notifications";
import { OrderDto, OrderReleaseStatus, OrderStatusChangeNotificationEvent, OrderType, OrganizationDto } from "@masta/generated-model";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { useDebounceFn } from "@vueuse/core";
import { SelectionChangedEvent, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { defineExpose, inject, reactive, ref, watch } from "vue";

const $emits = defineEmits(["rowSelected", "deleteAction", "rowDoubleClicked", "selectionChanged"]);
const props = defineProps<{
  filterByProductId?: string | null | undefined;
  readonly?: boolean;
  referenceValue?: string;
}>();

const serverSideDataSource = reactive(new PurchaseOrdersServerSideDataSource("purchase-orders"));
serverSideDataSource.useFilteringByProductId(props.filterByProductId);

const DEFAULT_CREATE_VALUE = {
  issueDate: () => resolveIssueDate(),
  orderType: OrderType.Purchase,
  businessId: null,
  releaseStatus: OrderReleaseStatus.New
};

useNotification(OrderStatusChangedNotification, async (e: OrderStatusChangeNotificationEvent) => {
  onOrderStatusChanged(e);
});

const onOrderStatusChanged: (e: OrderStatusChangeNotificationEvent) => void = useDebounceFn(async (e: OrderStatusChangeNotificationEvent) => {
  const row = gridWrapperRef.value?.gridApi?.getRowNode(e.orderId);
  if (row) {
    gridWrapperRef.value?.gridApi?.refreshServerSide();
  }
}, 800);

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

const gridWrapperRef = ref<GridWrapperComponent>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const isRowSelected = ref(false);
const isEditBtnDisabled = ref(false);
const isDeleteBtnDisabled = ref(false);

function resolveIssueDate(): Date {
  return new Date();
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "description",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("purchaseOrder-list-description-label", { $: "Description" })
      },
      headerValueGetter: (_: any) => $t("purchaseOrder-list-description-label", { $: "Description" }),
      floatingFilterComponentParams: {
        laceholder: $t("purchaseOrder-list-description-label", { $: "Description" })
      }
    },
    {
      field: "businessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("purchaseOrder-list-businessId-label", { $: "Business ID" })
      },
      headerValueGetter: (_: any) => $t("purchaseOrder-list-businessId-label", { $: "Business ID" }),
      floatingFilterComponentParams: {
        placeholder: $t("purchaseOrder-list-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "issueDate",
      editable: true,
      sortable: true,
      resizable: true,
      filter: "agDateColumnFilter",
      type: "datepickerTypeColumn",
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.issueDate);
      },
      cellEditorParams: {
        rules: [validDateRequiredRule],
        placeholder: $t("purchaseOrder-list-issueDate-label", { $: "Issue Date" })
      },
      headerValueGetter: (_: any) => $t("purchaseOrder-list-issueDate-label", { $: "Issue Date" })
    },
    {
      field: "customerName",
      editable: (params: any) => !params.data.id,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      type: ["organizationPickerTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("purchaseOrder-list-customerName-label", { $: "Customer" })
      },
      valueGetter: (params: ValueGetterParams<OrderDto, OrganizationDto>) => {
        const { data } = params;
        if (!data || !data.customerName) return null;

        return {
          businessId: data.customerName,
          name: data.customerName
        } as OrganizationDto;
      },
      valueSetter: (params: ValueSetterParams<OrderDto, OrganizationDto>) => {
        params.data.customerName = params.newValue?.businessId ?? "";
        return true;
      },
      valueFormatter: (params: ValueFormatterParams) => params.data?.customerName,
      headerValueGetter: (_: any) => $t("purchaseOrder-list-customerName-label", { $: "Customer" })
    },
    {
      field: "releaseStatus",
      resizable: true,
      cellStyle: (params: any) => getOrderReleaseStatusCellStyle(params.data.releaseStatus),
      valueGetter: (params: any) => translateOrderReleaseStatus(params.data.releaseStatus),
      headerValueGetter: (_: any) => $t("purchaseOrder-list-releaseStatus-label", { $: "Release Status" })
    },
    {
      field: "organizationId",
      hide: true,
      filter: "agTextColumnFilter",
      suppressColumnsToolPanel: true,
      headerValueGetter: (_: any) => $t("purchaseOrder-list-organizationId-label", { $: "Organization ID" })
    }
  ];
}

function onSelectionChanged(event: SelectionChangedEvent) {
  const { api } = event;
  const selectedRows = getSelectedRows(api);
  isRowSelected.value = selectedRows.length > 0;
  if (selectedRows.length === 1) {
    $emits("rowSelected", selectedRows[0]);
  }

  $emits("selectionChanged", event);
}

watch(props, (newProps) => {
  serverSideDataSource.useFilteringByProductId(newProps.filterByProductId);
  gridWrapperRef.value.gridApi.refreshServerSide({ purge: true });
});

defineExpose({ gridWrapperRef });
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="orders"
    :default-col-def="defaultColumnDef"
    row-selection="multiple"
    :create-default-value="DEFAULT_CREATE_VALUE"
    server-side
    :server-side-datasource="serverSideDataSource"
    refresh-btn
    :edit-btn="!readonly"
    :edit-btn-disabled="isEditBtnDisabled"
    :delete-btn-disabled="isDeleteBtnDisabled"
    :create-btn="!readonly"
    :duplicate-btn="!readonly"
    :delete-btn="!readonly"
    :hide-custom-actions-separator="readonly"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  >
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
