import ApiService from "@/services/api";
import { useErrorsStore } from "@/store/ErrorsStore";
import { AssignModelInstanceToCostCatalogueItemCommand, DeassignModelInstanceFromCostCatalogueItemCommand } from "@masta/generated-model";
import { defineStore } from "pinia";

interface State {}

export const useCostCataloguesStore = defineStore("costCatalogues", {
  state: (): State => ({}),
  actions: {
    async assignModelInstance(request: AssignModelInstanceToCostCatalogueItemCommand) {
      try {
        await ApiService.costCatalogue.assignModelInstance(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async deassignModelInstance(request: DeassignModelInstanceFromCostCatalogueItemCommand) {
      try {
        await ApiService.costCatalogue.deassignModelInstance(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});

