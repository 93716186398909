<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref, watch } from "vue";
import { AgreementDto, AgreementSubType, ResourceInfoDto, ResourceSubType, ResourceType } from "@masta/generated-model";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import OpenResourceGanttGridAction from "@/components/Gantt/OpenResourceGanttGridAction.vue";
import { $dateSymbol, $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { useFiltersStore } from "@/store/FiltersStore";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { $t } from "@/i18n";
import {
  enumToEditorEntries,
  enumToEditorEntriesExcluding,
  enumToEditorEntriesOnlyIncluding,
  enumValueEntryWithLocaleComparator,
  translateEditorEntries
} from "@/components/Grid/ColumnTypes";
import { AgreementsServerSideDataSource } from "@/components/Agreements/AgreementsServerSideDataSource";
import { IEnumValueSelectCellEditorParams } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";
import {
  CellClassParams,
  EditableCallbackParams,
  GridOptions,
  KeyCreatorParams,
  ProcessCellForExportParams,
  RefreshServerSideParams,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams
} from "ag-grid-community";
import { ITextInputCellEditorParams } from "@/components/Grid/CellEditors/ITextInputCellEditorParams";
import { IControlledCellEditorParams } from "@/components/Grid/UseValueChangeControl";
import { translateResourceSubType, translateResourceType } from "@/composables/translateEnum";
import { joinArrayOfStrings, nameOrBusinessIdOrIdOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import { booleanTypeColumnFilterParams } from "@/components/Grid/Filters/BooleanTypeColumnFilters";
import { groupValueCellStyle } from "@/components/Grid/CellRenderers/GroupValueCellStyle";
import { isDefined } from "@/components/Common/Types";
import { getSelectedNodes } from "@/components/Grid/UseGridSelection";
import { groupTypeCellStyle } from "@/components/Grid/CellRenderers/GroupTypeCellStyle";
import ActionsButton from "@/components/Layout/ActionsButton.vue";

const serverSideDataSource = reactive(new AgreementsServerSideDataSource("agreements"));

const emit = defineEmits(["resource2resource"]);
const miStore = useModelInstancesStore();
const filtersStore = useFiltersStore();
const scenariosStore = useScenariosStore();
const { selectedScenario } = storeToRefs(scenariosStore);

const ganttTypes = reactive([ResourceType.Agreement, ResourceType.AgreementGroup]);
const gridWrapperRef = ref<GridWrapperComponent>();

const periodStartEditorRef = ref<string | null>(null);
const periodEndEditorRef = ref<string | null>(null);

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const $date = inject<typeof dayjs>($dateSymbol)!;

const DEFAULT_CREATE_VALUE = {
  type: ResourceType.Agreement,
  isActive: true,
  plannable: true,
  subTypes: [AgreementSubType.FTE],
  tags: []
};

const sideBar = ref({
  position: "left",
  toolPanels: [
    {
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
      width: 500
    }
    // TODO fix CQL filter panel
    // {
    //   id: "cqlFilterExpressionToolPanel",
    //   labelDefault: "CQL Filter",
    //   labelKey: "CQL Filter",
    //   iconKey: "filter",
    //   toolPanelFramework: "CqlFilterExpressionToolPanel",
    //   width: 800,
    // },
  ]
});

watch(selectedScenario, async () => {
  await onFetchData();
});

async function onFetchData() {
  await miStore.fetchSchemas();
  await filtersStore.fetchResourceFilters(true);
}

const actionGroupContextRowNode = ref<RowNode | null>(null);

function gridApi() {
  return gridWrapperRef.value!.gridApi;
}

function firstSelectedRowNode(): RowNode {
  return getSelectedNodes(gridApi()).filter((x) => x.displayed)[0] as RowNode;
}

async function onEdit() {
  isEditingNewlyCreatedRow.value = false;
  actionGroupContextRowNode.value = firstSelectedRowNode();
  const agreementSubType = actionGroupContextRowNode.value?.data.subTypes[0];
  onAgreementTypeChange(agreementSubType, null);
}

async function onDelete() {
  const contextRowNode = firstSelectedRowNode();
  const parentGroupKeys = contextRowNode?.getGroupKeys().slice(0, -1);
  gridApi().refreshServerSide({ route: parentGroupKeys } as RefreshServerSideParams);
}

async function onSave() {
  const contextRowNode = actionGroupContextRowNode.value;
  gridApi().refreshServerSide({ route: contextRowNode?.getGroupKeys() } as RefreshServerSideParams);
}

async function onRefreshAction() {
  await onFetchData();
}

const isEditingNewlyCreatedRow = ref<boolean>(false);
const isEditingAgreementType = ref<boolean>(true);
const isEditingFteSubType = ref<boolean>(false);
const isEditingBudgetAutomatedProgressSubType = ref<boolean>(false);
const isEditingBudgetManualProgressSubType = ref<boolean>(false);

function onTypeChange(newValue: any, _: any) {
  isEditingAgreementType.value = newValue === ResourceType.Agreement;
}

function onAgreementTypeChange(newValue: any, _: any) {
  isEditingFteSubType.value = false;
  isEditingBudgetAutomatedProgressSubType.value = false;
  isEditingBudgetManualProgressSubType.value = false;

  switch (newValue) {
    case AgreementSubType.FTE:
      isEditingFteSubType.value = true;
      break;
    case AgreementSubType.BudgetAutomatedProgress:
      isEditingBudgetAutomatedProgressSubType.value = true;
      break;
    case AgreementSubType.BudgetManualProgress:
      isEditingBudgetManualProgressSubType.value = true;
      break;
    default:
      break;
  }
}

function notEditableWhenGroupType(params: EditableCallbackParams): boolean {
  return params.data.type !== ResourceType.AgreementGroup;
}

function requiredRule(v: any): boolean | string {
  return (v !== undefined && v !== null && v !== "") || $t("agreement-edit-valueRequired-tooltip", { $: "Required" });
}

function periodStartAndEndBothRequiredRule(_: any): boolean | string {
  const isStartPresent = !!periodStartEditorRef.value;
  const isEndPresent = !!periodEndEditorRef.value;
  return (isStartPresent && isEndPresent) || $t("agreement-edit-periodStartAndEndRequired-tooltip", { $: "Both start and end of period must be set or empty" });
}

function periodStartBeforeEndRequiredRule(_: any): boolean | string {
  const start = periodStartEditorRef.value;
  const end = periodEndEditorRef.value;
  return (!start && !end) || dayjs(end).diff(dayjs(start)) >= 0 || $t("agreement-edit-periodStartBeforeEndRequired-tooltip", { $: "Start must be before end of period" });
}

function requiredWhenNotGroupRule(v: any): boolean | string {
  return !isEditingAgreementType.value || requiredRule(v);
}

// $t("agreement-subType-Unknown-label", {$: "Unknown"})
// $t("agreement-subType-FTE-label", {$: "FTE"})
// $t("agreement-subType-BudgetManualProgress-label", {$: "Budget Manual Progress"})
// $t("agreement-subType-BudgetAutomatedProgress-label", {$: "Budget Automated Progress"})
function translateAgreementSubType(t: AgreementSubType): string {
  const key = AgreementSubType[t];
  return $t(`agreement-subType-${key}-label`);
}

function asAgreementSubType(params: any) {
  return params.data.subTypes ? params.data.subTypes[0] : ResourceSubType.Unknown;
}

const defaultColumnDef = ref({
  filter: false,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});

const autoGroupColumnDef = ref({
  field: "businessId",
  type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
  editable: true,
  sortable: true,
  minWidth: 330,
  filter: "agTextColumnFilter",
  headerValueGetter: (_: any) => $t("agreement-list-businessId-label", { $: "Business ID" }),
  cellEditorParams: {
    placeholder: $t("agreement-list-businessId-label", { $: "Business ID" }),
    rules: [(v: any) => (v !== undefined && v !== null && v !== "") || "Required"]
  },
  cellStyle: (params: CellClassParams) => groupValueCellStyle(params, isServerSideGroup),
  floatingFilterComponentParams: {
    placeholder: $t("agreement-list-businessId-label", { $: "Business ID" })
  }
});

const gridOptions: GridOptions = {
  processCellForClipboard: (params: ProcessCellForExportParams) => {
    const { value, column } = params;

    if (column.getColDef().type === "parentId") {
      return params.formatValue(value);
    }

    return value;
  }
};

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "id",
      headerValueGetter: (_: any) => $t("agreement-list-groupId-label", { $: "Group ID" }),
      editable: false,
      sortable: false,
      filter: false,
      hide: true,
      valueGetter: (params: ValueGetterParams) => params.data.name ?? params.data.businessId ?? params.data.id
    },
    {
      field: "parentId",
      type: "resourcePickerTypeColumn",
      headerValueGetter: (_: any) => $t("agreement-list-parent-label", { $: "Parent" }),
      editable: true,
      hide: true,
      cellEditorParams: {
        resourceTypes: [ResourceType.AgreementGroup],
        placeholder: $t("agreement-list-parent-label", { $: "Parent" })
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return nameOrBusinessIdOrIdOrNull(params.value);
      },
      valueGetter: (params: ValueGetterParams<AgreementDto>) => {
        const { data } = params;
        if (!data || !isDefined(data.parentId)) return null;

        const parentNode = params.api.getRowNode(data.parentId);

        return mapToResourceInfoDto(data.parentId, parentNode && parentNode.data ? parentNode.data.businessId : null, parentNode && parentNode.data ? parentNode.data.name : null);
      },
      valueSetter: (params: ValueSetterParams<AgreementDto, AgreementDto>) => {
        params.data.parentId = params.newValue?.id ?? null;
        return true;
      }
    },
    {
      field: "type",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("agreement-list-type-label", { $: "Type" }),
      cellStyle: (params: CellClassParams) => groupTypeCellStyle(params, isServerSideGroup),
      editable: true,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(ResourceType, [ResourceType.Agreement, ResourceType.AgreementGroup]), translateResourceType),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => translateResourceType(params.data.type),
      cellEditorParams: {
        values: enumToEditorEntriesOnlyIncluding(ResourceType, [ResourceType.Agreement, ResourceType.AgreementGroup]),
        rules: [requiredRule],
        placeholder: $t("agreement-edit-type-label", { $: "Type" }),
        onValueChange: onTypeChange,
        isEditEnabled: () => isEditingNewlyCreatedRow,
        clearOnEditDisabled: false,
        defaultValue: ResourceType.Agreement
      } as IEnumValueSelectCellEditorParams,
      floatingFilterComponentParams: {
        placeholder: $t("agreement-edit-type-label", { $: "Type" }),
        values: enumToEditorEntriesOnlyIncluding(ResourceType, [ResourceType.Agreement, ResourceType.AgreementGroup])
      }
    },
    {
      field: "businessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("agreement-list-businessId-label", { $: "Business ID" }),
      editable: true,
      sortable: true,
      hide: true,
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("agreement-edit-businessId-label", { $: "Business ID" }),
        rules: [(v: any) => (v !== undefined && v !== null && v !== "") || "Required"]
      },
      floatingFilterComponentParams: {
        placeholder: $t("agreement-edit-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "name",
      headerName: "Name",
      headerValueGetter: (_: any) => $t("agreement-list-name-label", { $: "Name" }),
      editable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("agreement-edit-name-label", { $: "Name" })
      } as ITextInputCellEditorParams,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        placeholder: $t("agreement-edit-name-label", { $: "Name" })
      }
    },
    {
      field: "description",
      headerValueGetter: (_: any) => $t("agreement-list-description-label", { $: "Description" }),
      editable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("agreement-edit-description-label", { $: "Description" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("agreement-edit-description-label", { $: "Description" })
      }
    },
    {
      field: "isActive",
      type: ["setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("agreement-list-isActive-label", { $: "Active" }),
      filter: "agSetColumnFilter",
      filterParams: booleanTypeColumnFilterParams,
      editable: true,
      resizable: true,
      sortable: true,
      cellDataType: "boolean",
      cellRendererParams: {
        disabled: () => !gridWrapperRef.value?.isEditing()
      }
    },
    {
      field: "agreementType",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("agreement-list-agreementType-label", { $: "Agreement type" }),
      editable: notEditableWhenGroupType,
      resizable: true,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(AgreementSubType), translateResourceSubType),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueGetter: asAgreementSubType,
      valueSetter: (params: any) => {
        params.data.subTypes = [params.newValue];
        return true;
      },
      valueFormatter: (params: any) => {
        return translateAgreementSubType(asAgreementSubType(params));
      },
      cellEditorParams: {
        rules: [requiredWhenNotGroupRule],
        values: enumToEditorEntriesExcluding(AgreementSubType, [AgreementSubType.Unknown]).map((entry) => {
          entry.key = translateAgreementSubType(entry.value);
          return entry;
        }),
        isEditEnabled: () => isEditingAgreementType,
        clearOnEditDisabled: true,
        onValueChange: onAgreementTypeChange
      } as IEnumValueSelectCellEditorParams,
      floatingFilterComponentParams: {
        placeholder: $t("agreement-list-agreementType-label", { $: "Agreement type" }),
        values: enumToEditorEntriesExcluding(AgreementSubType, [AgreementSubType.Unknown]).map((entry) => {
          entry.key = translateAgreementSubType(entry.value);
          return entry;
        })
      }
    },
    {
      field: "periodStart",
      type: "datepickerTypeColumn",
      headerValueGetter: (_: any) => $t("agreement-list-periodStart-label", { $: "Start" }),
      editable: notEditableWhenGroupType,
      resizable: true,
      sortable: true,
      filter: "agDateColumnFilter",
      suppressFloatingFilterButton: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.periodStart);
      },
      cellEditorParams: {
        isEditEnabled: () => isEditingAgreementType,
        valueRef: () => periodStartEditorRef,
        rules: [periodStartAndEndBothRequiredRule, periodStartBeforeEndRequiredRule],
        clearOnEditDisabled: true,
        placeholder: $t("agreement-list-periodStart-label", { $: "Start" })
      } as IControlledCellEditorParams
    },
    {
      field: "periodEnd",
      type: "datepickerTypeColumn",
      headerValueGetter: (_: any) => $t("agreement-list-periodEnd-label", { $: "End" }),
      editable: notEditableWhenGroupType,
      resizable: true,
      sortable: true,
      filter: "agDateColumnFilter",
      suppressFloatingFilterButton: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.periodEnd);
      },
      cellEditorParams: {
        isEditEnabled: () => isEditingAgreementType,
        valueRef: () => periodEndEditorRef,
        rules: [periodStartAndEndBothRequiredRule, periodStartBeforeEndRequiredRule],
        clearOnEditDisabled: true,
        placeholder: $t("agreement-list-periodEnd-label", { $: "End" })
      } as IControlledCellEditorParams
    },
    {
      field: "agreedFTE",
      type: "numberInputTypeColumn",
      headerValueGetter: (_: any) => $t("agreement-list-agreedFTE-label", { $: "Agreed FTE" }),
      editable: (params: any) => notEditableWhenGroupType(params),
      resizable: true,
      sortable: true,
      cellEditorParams: {
        isEditEnabled: () => isEditingFteSubType,
        clearOnEditDisabled: () => !isEditingAgreementType.value || !isEditingFteSubType.value,
        clearValue: 0
      } as IControlledCellEditorParams
    },
    {
      field: "plannedEffort",
      type: "numberInputTypeColumn",
      editable: (params: any) => notEditableWhenGroupType(params),
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("agreement-list-plannedEffort-label", { $: "Planned effort" }),
      cellEditorParams: {
        isEditEnabled: () => isEditingBudgetManualProgressSubType,
        clearOnEditDisabled: () => !isEditingAgreementType.value || !isEditingBudgetManualProgressSubType.value,
        clearValue: 0
      } as IControlledCellEditorParams
    },
    {
      field: "remainingEffort",
      type: "numberInputTypeColumn",
      editable: (params: any) => notEditableWhenGroupType(params),
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("agreement-list-remainingEffort-label", { $: "Remaining effort" }),
      cellEditorParams: {
        isEditEnabled: () => isEditingBudgetManualProgressSubType,
        clearOnEditDisabled: () => !isEditingAgreementType.value || !isEditingBudgetManualProgressSubType.value,
        clearValue: 0
      } as IControlledCellEditorParams
    },
    {
      field: "tags",
      headerValueGetter: (_: any) => $t("agreement-list-tags-label", { $: "Tags" }),
      type: "tagsPickerTypeColumn",
      editable: true,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => joinArrayOfStrings(params.data.tags),
      cellEditorParams: {
        placeholder: $t("agreement-edit-tags-label", { $: "Tags" })
      }
    },
    {
      field: "versionNumber",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agNumberColumnFilter",
      headerValueGetter: (_: any) => $t("agreement-list-versionNumber-label", { $: "Version" })
    },
    {
      field: "versionDateTime",
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return $date(params.data.versionDateTime).format("DD/MM/YYYY HH:mm");
      },
      headerValueGetter: (_: any) => $t("agreement-list-versionDateTime-label", { $: "Version Date" })
    },
    {
      field: "plannable",
      type: ["setFloatingFilterColumnType"],
      filter: "agSetColumnFilter",
      filterParams: booleanTypeColumnFilterParams,
      headerValueGetter: (_: any) => $t("agreement-list-plannable-label", { $: "Plannable" }),
      editable: true,
      resizable: true,
      sortable: true,
      cellDataType: "boolean",
      cellRendererParams: {
        disabled: () => !gridWrapperRef.value?.isEditing()
      }
    },
    {
      field: "createdBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("agreement-list-createdBy-label", { $: "Created By" }),
      floatingFilterComponentParams: {
        placeholder: $t("agreement-list-createdBy-label", { $: "Created By" })
      }
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("agreement-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("agreement-list-modifiedBy-label", { $: "Modified By" }),
      floatingFilterComponentParams: {
        placeholder: $t("agreement-list-modifiedBy-label", { $: "Modified By" })
      }
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("agreement-list-modifiedAt-label", { $: "Modified At" })
    }
  ];
}

function getContextMenuItems(param: any) {
  return [
    {
      name: $t("agreement-resource2resource-person-action", { $: "Person assignment" }),
      action: () => {
        emit("resource2resource", ResourceType.Person, param.node.data);
      },
      disabled: !param.node || param.node.data.type !== ResourceType.Agreement
    }
  ];
}

function isServerSideGroup(dataItem: any) {
  // new row in editing mode is not a group (otherwise the ag grid might tries to load data for it and :boom:)
  if (dataItem?.id == null) return false;

  return dataItem?.type === ResourceType.AgreementGroup;
}

function getServerSideGroupKey(dataItem: any) {
  return dataItem?.id;
}

function mapToResourceInfoDto(id: string | null, businessId: string | null, name: string | null): ResourceInfoDto | null {
  if (id || businessId || name) {
    const resourceInfo: ResourceInfoDto = {
      id: id as string,
      scenarioId: "",
      name: name,
      businessId: businessId,
      type: ResourceType.PersonGroup,
      plannable: false
    };

    return resourceInfo;
  } else {
    return null;
  }
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    create-btn
    create-child-btn
    edit-btn
    delete-btn
    refresh-btn
    duplicate-btn
    identifier="agreements"
    :default-col-def="defaultColumnDef"
    :auto-group-column-def="autoGroupColumnDef"
    row-selection="multiple"
    :context-menu-items="getContextMenuItems"
    :side-bar="sideBar"
    :server-side="true"
    :server-side-datasource="serverSideDataSource"
    :tree-data="true"
    :is-server-side-group="isServerSideGroup"
    :get-server-side-group-key="getServerSideGroupKey"
    :enable-group-edit="true"
    :create-default-value="DEFAULT_CREATE_VALUE"
    :grid-options="gridOptions"
    @fetch-data="onFetchData"
    @edit-action="onEdit"
    @save-action="onSave"
    @delete-action="onDelete"
    @refresh-action="onRefreshAction"
    @prepare-columns="onPrepareColumns"
  >
    <template #custom-buttons>
      <actions-button :model-value="[]" :label="$t('actions-button-gantt-label', { $: 'Gantt' })">
        <template #prepend>
          <open-resource-gantt-grid-action :types="ganttTypes" :grid-api="gridWrapperRef?.gridApi" />
        </template>
      </actions-button>
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
