<script setup lang="ts">
import { $t } from "@/i18n";
import { DocumentContextNameConsts, EquipmentDto } from "@masta/generated-model";
import Tasks from "@/views/Tasks.vue";
import ResourceModelInstances from "@/components/ModelInstances/ResourceModelInstances.vue";
import { ref } from "vue";
import { ModelInstanceTab } from "@/components/ModelInstances/model-instance-tab";
import ResourceCapacityDetails from "@/components/Resources/ResourceCapacityDetails.vue";
import ResourceMediaGallery from "@/components/Media/ResourceMediaGallery.vue";

const props = defineProps<{
  equipment: EquipmentDto;
  schemaTabs: ModelInstanceTab[];
}>();

const emit = defineEmits<{
  (e: "updated", id: string): void;
}>();

const defaultTab = "overview";
const currentTab = ref(defaultTab);

async function modelInstanceUpdated(resourceId: string) {
  emit("updated", resourceId);
}
</script>

<template>
  <v-card elevation="7" class="d-flex flex-column fill-height equipments-card">
    <v-card-title class="tabs">
      <span class="text-h6">{{ equipment?.name || equipment?.businessId }}</span>
      <v-tabs
        v-model="currentTab"
        class="task-details text-disabled"
        selected-class="v-slide-group-item--active text-primary bg-white"
        density="compact"
        color="primary"
        show-arrows
      >
        <v-tab value="overview">
          <v-icon class="pr-2">mdi-folder-information</v-icon>
          {{ $t("equipmentDetails-view-overview-label", { $: "Gallery" }) }}
        </v-tab>
        <v-tab v-for="tab in schemaTabs" :key="tab.title" :value="tab.title">
          <v-icon class="pr-2">mdi-tag-multiple</v-icon>
          {{ tab.title }}
        </v-tab>
        <v-tab value="productionTasks">
          <v-icon class="pr-2">mdi-list-box-outline</v-icon>
          {{ $t("equipmentDetails-view-productionTasks-label", { $: "Production Tasks" }) }}
        </v-tab>
        <v-tab value="resourceCapacities">
          <v-icon class="pr-2">mdi-treasure-chest</v-icon>
          {{ $t("equipmentDetails-view-resourceCapacities-label", { $: "Resource Capacities" }) }}
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="d-flex flex-column pa-0">
      <div class="flex-1-1 d-flex overflow-hidden">
        <v-card class="flex-1-1 d-flex flex-column rounded-0" elevation="6">
          <v-card-text class="flex-1-1 d-flex flex-column pa-0">
            <v-window v-model="currentTab" class="bg-white flex-fill d-block" style="position: absolute; width: 100%; height: 100%">
              <v-window-item class="fill-height pa-2 overflow-y-auto" value="overview">
                <resource-media-gallery :resource="equipment" :context-name="DocumentContextNameConsts.equipmentImage" />
              </v-window-item>
              <v-window-item v-for="tab in schemaTabs" :key="tab.title" :value="tab.title" class="fill-height pa-2 overflow-y-auto">
                <ResourceModelInstances :context-name="tab.contextName" :resource="equipment" :schema="tab.schema" @updated="modelInstanceUpdated" />
              </v-window-item>
              <v-window-item class="fill-height pa-2 overflow-y-auto" value="productionTasks">
                <tasks :filter-by-product-id="equipment?.id" embedded />
              </v-window-item>
              <v-window-item class="fill-height pa-2 overflow-y-auto" value="resourceCapacities">
                <resource-capacity-details :resource="equipment" />
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.equipments-card {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 0;
}
</style>
