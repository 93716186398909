<script lang="ts" setup>
import CloseCardButton from "@/components/CloseCardButton.vue";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import TasksGrid from "@/components/Tasks/TasksGrid.vue";
import { $t } from "@/i18n";
import { ProductionTaskDto, TaskDto } from "@masta/generated-model";
import { IRowNode, RowDoubleClickedEvent, SelectionChangedEvent } from "ag-grid-community";
import { ref } from "vue";
import { VBtn } from "vuetify/lib/components/index.mjs";

const modelValue = defineModel<TaskDto[] | null | undefined>("selectedTasks", { required: true, default: [] });
const dialog = defineModel<boolean>("dialog", { required: true, default: false });
const props = defineProps<{

  /**
   * The task IDs that should be preselected in the picker.
   */
  preselectedTaskIds?: string[];
}>();

const selectedTasks = ref<ProductionTaskDto[] | null | undefined>([]);

function onBtnAdd() {
  if (selectedTasks.value) {
    modelValue.value = selectedTasks.value;
  }

  dialog.value = false;
}

function onBtnCancel() {
  dialog.value = false;
}

function onSelectionChanged({ api }: SelectionChangedEvent<ProductionTaskDto>) {
  const selectedRows = getSelectedRows(api);
  selectedTasks.value = selectedRows;
}

function rowDoubleClicked(event: RowDoubleClickedEvent<ProductionTaskDto>) {
  if (event.data) {

    if (isRowSelectable(event.node)) {
      selectedTasks.value = [event.data];
      onBtnAdd();
    }
  }
}

function isRowSelectable(row: IRowNode) {
  return !!row.data && !props.preselectedTaskIds?.includes(row.data.id);
}
</script>

<template>
  <v-dialog v-model="dialog" width="100%" height="90vh" @click:outside="onBtnCancel">
    <v-card min-width="300px" height="100%" class="reference-picker-card">
      <close-card-button @click="onBtnCancel"></close-card-button>
      <v-card-title class="pa-0">
        <div class="text-h4 pb-5">{{ $t("tasksPicker-list-addTasks-label", { $: "Add tasks" }) }}</div>
      </v-card-title>
      <v-card-text class="px-0">
        <tasks-grid 
          :is-row-selectable="isRowSelectable" 
          @selection-changed="onSelectionChanged"
          @row-double-clicked="rowDoubleClicked" />
      </v-card-text>
      <v-card-actions class="pa-0">
        <div class="left-col pl-0">
          <v-btn variant="elevated" color="secondary" class="w-100" @click="onBtnCancel">
            {{ $t("tasksPicker-list-cancel-label", { $: "Cancel" }) }}
          </v-btn>
        </div>
        <div class="middle-col"></div>
        <div class="right-col pr-0">
          <v-btn variant="elevated" color="primary" class="w-100" @click="onBtnAdd">
            {{ $t("tasksPicker-list-add-label", { $: "Add" }) }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>