<template>
  <split-layout identifier="assets" :gutter="createSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("asset-view-assets-title", { $: "Assets" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <assets-grid
                @manage-model-instances="manageModelInstances"
                @manage-availability-rules="manageAvailabilityRules"
                @resource2resource="resource2resourceAction"
                @show-details="onShowDetails"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-col v-if="editAvailabilityRulesAssignmentDialog">
        <availability-rules-assignment-editor
          :resource="asset"
          :opened="editAvailabilityRulesAssignmentDialog"
          @cancel="editAvailabilityRulesAssignmentDialog = false"
          @save="editAvailabilityRulesAssignmentDialog = false"
        />
      </v-col>
      <resource2-resource-dialog
        v-if="resourceType >= 0 && asset"
        :opened="resource2resourceDialog"
        :assignment-type="resourceAssignmentType"
        :resource-type="resourceType"
        :resource="asset"
        reverse
        @close="resource2resourceDialogClose"
      />
    </template>
    <template #detail>
      <asset-details v-if="asset" :asset="asset" :schema-tabs="schemaTabs" @updated="assetUpdated" />
      <v-card v-if="!asset" elevation="7" class="d-flex flex-column fill-height no-tasks-card">
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("asset-view-noAssetSelected-title", { $: "No asset selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<script lang="ts" setup>
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import { $t } from "@/i18n";

/**
 * Called to create gutter element for split panel.
 */
function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-assets");
  return gutter;
}
</script>

<script lang="ts">
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import AssetsGrid from "@/components/Assets/AssetsGrid.vue";
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor";
import { mapActions, mapState } from "pinia";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import AssetDetails from "@/components/Assets/AssetDetails.vue";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useSystemStore } from "@/store/SystemStore";
import Resource2ResourceDialog from "@/components/Resources/ResourceAssignment/Resource2ResourceDialog.vue";
import { ResourceAssignmentType } from "@masta/generated-model";

export default {
  name: "Assets",
  components: {
    Resource2ResourceDialog,
    AssetDetails,
    Breadcrumbs,
    AvailabilityRulesAssignmentEditor,
    AssetsGrid,
    MasterDetailLayout
  },
  title: "Assets",
  data() {
    return {
      editAvailabilityRulesAssignmentDialog: false,
      showDetails: false,
      asset: null,
      refreshGridAction: null,
      schemaTabs: [],
      resource2resourceDialog: false,
      resourceType: null,
      resourceAssignmentType: ResourceAssignmentType.Identity
    };
  },
  computed: {
    ...mapState(useModelInstancesStore, ["schemas"]),
    ...mapState(useSystemStore, ["modelInstanceTabs"])
  },
  mounted() {
    this.loadTabSchemas();
  },
  methods: {
    ...mapActions(useModelInstancesStore, ["fetchSchemas"]),
    manageAvailabilityRules(asset) {
      this.asset = asset;
      this.editAvailabilityRulesAssignmentDialog = true;
    },
    resource2resourceAction(resourceType, asset) {
      this.resourceType = resourceType;
      this.asset = asset;
      this.resource2resourceDialog = true;
    },
    resource2resourceDialogClose() {
      this.resource2resourceDialog = false;
      this.resourceType = null;
      this.asset = null;
    },
    onShowDetails(asset) {
      this.asset = asset;
      this.showDetails = true;
    },
    async loadTabSchemas() {
      await this.fetchSchemas();
      for (const tab of this.modelInstanceTabs?.assets ?? []) {
        const schema = this.schemas.find((x) => x.schemaName === tab.schemaName && x.schemaKey === tab.schemaKey && x.version === tab.schemaVersion);
        if (schema) {
          this.schemaTabs.push({ title: tab.title ?? "", schema, contextName: tab.contextName ?? "", resourceSubTypes: tab.resourceSubTypes });
        }
      }
    },
    async assetUpdated(id) {
      this.$refs.grid.refreshSingle(id);
    }
  }
};
</script>

<style lang="scss" scoped></style>
